import React, { useState } from 'react'
import styles from './AccordtionPrakerja.module.css'
import profileImage from './profilPengajarimg.png'
import { Collapse } from 'antd'
import ModalProgramDurasi from '../ModalProgramDurasi'
const { Panel } = Collapse
const AccorditionPrakerja = () => {
  const [durasiModal, setdurasiModal] = useState(false)
  return (
    <>
      <Collapse accordion expandIconPosition="right" ghost>
        <Panel header="Deskripsi Pelatihan" key="Deskripsi Pelatihan">
          <div>
            <p>
              Sistem Manajemen Keamanan Pangan untuk Pengawas Kualitas (Webinar)
              merupakan pelatihan yang mempelajari penerapan sistem standar
              dalam keamanan pangan seperti GMP, SSOP, HACCP, dan ISO
              22000:2018.
            </p>
            <br />
            <p>
              Mengikuti pelatihan ini akan sangat cocok bagi para peserta yang
              ingin masuk ke industri pangan, khususnya pada bagian Quality
              Assurance, Quality Control, Research & Development, dan bagian
              produksi makanan/minuman.
            </p>
          </div>
        </Panel>
        <Panel header="Tujuan" key="Tujuan Pelatihan">
          <div>
            <p className={styles.tujuanTitle}>Tujuan Umum:</p>
            <p>
              Peserta mengerti dan memahami persyaratan, kriteria dan regulasi
              dari GMP, SSOP, dan HACCP sehingga dapat menerapkan Sistem
              Manajemen Keamanan Pangan berdasarkan ISO 22000:2018
            </p>
            <br />
            <p>Tujuan Khusus:</p>
            <ol>
              <li>
                Peserta mengerti dan memahami tahapan proses penerapan Sistem
                Manajemen Keamanan Pangan di Industri;
              </li>
              <li>
                Peserta mampu mendesain proses dari Sistem Manajemen Keamanan
                Pangan di Industri;
              </li>
              <li>
                Peserta mampu melaksanakan, monitoring, evaluasi, dan melakukan
                peningkatan (improvement) dalam penerapan Sistem Manajemen
                Keamanan Pangan di Industri
              </li>
            </ol>
          </div>
        </Panel>
        <Panel header="Aspek Kompetensi" key="Aspek Kompetensi">
          <div>
            <ol>
              <li>
                Memahami dan menerapkan Sistem Manajemen Keamanan Pangan
                berdasarkan ISO 22000:2018
              </li>
              <li>
                Memahami dan menerapkan persyaratan serta prosedur ISO
                22000:2018
              </li>
              <li>Melakukan validasi dan verifikasi ISO 22000:2018</li>
              <li>
                Mampu mendokumentasikan penerapan ISO 22000:2018 dalam bentuk
                manual
              </li>
              <li>Memahami dan menerapkan Sistem Keamanan Pangan HACCP</li>
              <li>Mendesain langkah persiapan HACCP</li>
              <li>
                Mendesain dan mendokumentasikan 7 prinsip HACCP dalam bentuk
                HACCP Plan
              </li>
              <li>
                Melakukan monitoring, verifikasi, evaluasi, dan tindakan
                perbaikan terhadap penerapan HACCP
              </li>
              <li>
                Mampu mendesain atau menetapkan (GMP) sebagai syarat dasar
                sistem keamanan pangan
              </li>
              <li>Menerapkan persyaratan dan prosedur GMP</li>
              <li>Mampu membuat dokumen persyaratan GMP</li>
              <li>
                Mampu melakukan monitoring serta melakukan evaluasi dan tindakan
                perbaikan untuk persyaratan GMP
              </li>
            </ol>
          </div>
        </Panel>
        <Panel header="Kelompok Sasaran" key="Sasaran Peserta">
          <div>
            <ol>
              <li>
                Mahasiswa (Teknologi Pangan, Biologi, Kimia, Fisika, Perikanan,
                Kelautan, Peternakan, Pertanian, Ilmu Gizi, Teknik kimia, Teknik
                Industri dan jurusan lain yang berkaitan)
              </li>
              <li>Freshgraduate</li>
              <li>Profesional (karyawan)</li>
              <li>Pelaku usaha (F&B, Horeka dan usaha lain yang berkaitan)</li>
              <li>Pegawai negeri ataupun swasta</li>
              <li>Memiliki laptop/handphone/computer</li>
              <li>Memiliki akun Zoom sebagai platform pembelajaran</li>
              <li>
                Memiliki akun Whatsapp sebagai platform diskusi dan penyampaian
                informasi
              </li>
              <li>
                Memiliki akun email untuk pengiriman hasil rekaman pelatihan dan
                sertifikat
              </li>
              <li>
                Mampu mendesain atau menetapkan prosedur SSOP sebagai prosedur
                kerja untuk menjaga kualitas makanan dan minuman di industri
                pangan
              </li>
              <li>Menerapkan persyaratan dan prosedur SSOP</li>
              <li>
                Mampu melakukan monitoring prosedur SSOP berdasarkan form
                checklist
              </li>
              <li>
                Mampu melakukan evaluasi dan tindakan perbaikan prosedur SSOP
              </li>
            </ol>
          </div>
        </Panel>
        <Panel header="Durasi" key="Durasi">
          <div>
            <p
              className={styles.btnLihatDurasi}
              onClick={() => setdurasiModal(true)}>
              lihat durasi
            </p>
          </div>
        </Panel>
        <Panel header="Level Pelatihan" key="Level Pelatihan">
          <div>
            <p>Basic/Tingkat Dasar</p>
          </div>
        </Panel>
        <Panel header="Mode Penyampaian" key="Mode Penyampaian">
          <div>
            <p>Webinar</p>
          </div>
        </Panel>
        <Panel header="Metode Evaluasi" key="Metode Evaluasi">
          <div>
            <p>Kuis Post Test</p>
          </div>
        </Panel>
        <Panel header="Jenis Sertifikat" key="Jenis Sertifikat">
          <div>
            <ul>
              <li>Sertifikat Penyelesaian</li>
              <li>Sertifikat Kompetensi Lulus</li>
            </ul>
          </div>
        </Panel>
        <Panel header="Jadwal Konsultasi" key="Jadwal Konsultasi">
          <div>
            <p>Jumat (10.00-11.00 WIB)</p>
          </div>
        </Panel>
        <Panel header="Profil Pengajar" key="Profil Pengajar">
          <div>
            <div className={styles.profilPengajarImgGroup}>
              <img src={profileImage} alt="profile" />
              <p className={styles.namePengajar}>
                Dr. Matheus Nugroho. SPi., MP
              </p>
            </div>
            <p>
              Sebagai lulusan S1 Perikanan, S2 Teknologi Pertanian, dan S3 Ilmu
              Lingkungan di Universitas Brawiijaya sudah memiliki ilmu dasar
              dalam menyampaikan materi terkait Sistem Manajemen Keamanan
              Pangan. Dengan pengalaman kerja sebagai Management Representative
              di Cargill, sudah terbiasa dalam menerapkan Sistem Manajemen
              Keamanan Pangan serta sistem lain yang terintegrasi didalamnya.
              Beliau juga sebagai akademisi di suatu universitas dan juga sudah
              mengisi training terkait sistem manajemen keamanan pangan serta
              sistem terintegrasi lainnya kurang lebih 4 tahun. Beliau juga
              sudah mengikuti beberapa sertifikasi kompetensi seperti IRCA dan
              yang lainnya.
            </p>
          </div>
        </Panel>
      </Collapse>
      <ModalProgramDurasi
        durasiModal={durasiModal}
        setdurasiModal={setdurasiModal}
      />
    </>
  )
}

export default AccorditionPrakerja
