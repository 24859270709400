import { Button, Card, Col, message, Modal, Radio, Row } from 'antd'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import FetchLoading from '../../components/FetchLoading'
import Layout from '../../components/Layout'
import { selectCurrentUser } from '../../redux/user/user.selectors'
import { BASE_URL_IMAGE_PROGRAM } from '../../utils'
import styles from './DetailCheckout.module.css'

import BCA from '../../assets/images/method_payment/BCA.svg'
import BJB from '../../assets/images/method_payment/BJB.svg'
import BNI from '../../assets/images/method_payment/BNI.svg'
import BRI from '../../assets/images/method_payment/BRI.svg'
import BSI from '../../assets/images/method_payment/BSI.svg'
import CIMB from '../../assets/images/method_payment/CIMB.svg'
import MANDIRI from '../../assets/images/method_payment/MANDIRI.svg'
import PERMATA from '../../assets/images/method_payment/PERMATA.svg'
import SAHABAT_SAMPOERNA from '../../assets/images/method_payment/SAHABAT_SAMPOERNA.svg'
import ALFAMART from '../../assets/images/method_payment/ALFAMART.svg'
import INDOMARET from '../../assets/images/method_payment/INDOMARET.svg'
import OVO from '../../assets/images/method_payment/ovo.svg'
import DANA from '../../assets/images/method_payment/dana.svg'
import SHOPEEPAY from '../../assets/images/method_payment/shopeepay.svg'
// import LINKAJA from '../../assets/images/method_payment/linkaja.svg'

import './DetailCheckout.scss'
// import ButtonBack from '../../components/ButtonBack'
import Kupon from '../Keranjang/components/Kupon'
import { InfoCircleTwoTone } from '@ant-design/icons'
import { ButtonLink } from '../../components'

const qs = require('qs')

const DetailCheckout = () => {
  const [loading, setLoading] = useState(false)
  const [produk, setProduk] = useState(null)
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [countTotal, setCountTotal] = useState(null)
  const [method, setMethod] = useState(null)
  const [kupon, setKupon] = useState(null)
  const [visibleModalOVO, setVisibleModalOVO] = useState(null)
  const [visibleModalEwallet, setVisibleModalEwallet] = useState(null)
  const currentUser = useSelector(selectCurrentUser)
  const navigate = useNavigate()
  const { slug } = useParams()

  useEffect(() => {
    slug
      ? axios({
          method: 'get',
          url: `/direct-buy/${atob(slug)}`,
          headers: {
            Authorization: `Bearer ${currentUser.token}`,
          },
        })
          .then(({ data }) => {
            setKupon(data.coupon)
            setProduk(data.product)
            setPaymentMethod(data.payment_method)
            setCountTotal(data.count_total)
          })
          .catch((error) => {
            if (error.response.status === 500) {
              message.error('We are Under Maintenance')
            }
          })
      : axios({
          method: 'get',
          url: '/cart',
          headers: {
            Authorization: `Bearer ${currentUser.token}`,
          },
        })
          .then(({ data }) => {
            setKupon(data.coupon)
            setProduk(data.product)
            setPaymentMethod(data.payment_method)
            setCountTotal(data.count_total)
          })
          .catch((error) => {
            if (error.response.status === 500) {
              message.error('We are Under Maintenance')
            }
          })
  }, [currentUser.token, slug])

  const selectMethod = (e) => {
    setMethod(e.target.value)
  }

  const onCreateVA = () => {
    setLoading(true)
    let value = qs.stringify(
      slug
        ? {
            payment_method:
              method === 'INDOMARET' || method === 'ALFAMART'
                ? 'RETAIL_OUTLETS'
                : method === 'ID_OVO' ||
                  method === 'ID_DANA' ||
                  method === 'ID_SHOPEEPAY' ||
                  method === 'ID_LINKAJA'
                ? 'EWALLET'
                : 'VIRTUAL_ACCOUNT',
            payment_via: method,
            buy_mode: 'direct',
            token_product: produk[0].token_product,
          }
        : {
            payment_method:
              method === 'INDOMARET' || method === 'ALFAMART'
                ? 'RETAIL_OUTLETS'
                : method === 'ID_OVO' ||
                  method === 'ID_DANA' ||
                  method === 'ID_SHOPEEPAY' ||
                  method === 'ID_LINKAJA'
                ? 'EWALLET'
                : 'VIRTUAL_ACCOUNT',
            payment_via: method,
            buy_mode: 'cart',
          }
    )

    axios({
      method: 'post',
      url: '/invoice',
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: value,
    })
      .then(({ data }) => {
        setLoading(false)
        if (value.search('EWALLET') > 1) {
          if (method === 'ID_OVO') {
            setVisibleModalOVO(true)
          } else {
            window.open(
              data.actions.desktop_web_checkout_url
                ? data.actions.desktop_web_checkout_url
                : data.actions.mobile_web_checkout_url
                ? data.actions.mobile_web_checkout_url
                : data.actions.mobile_deeplink_checkout_url,
              '_blank' // <- This is what makes it open in a new window.
            )
            setVisibleModalEwallet(true)
          }
        } else {
          navigate(`/bayar-pesanan/${btoa(data.id)}`)
        }
      })
      .catch((error) => {
        console.log(error.response.data)
        if (
          error.response.data.message ===
          'There was an error with the format submitted to the server.'
        ) {
          message.warning('Silahkan pilih metode pembayaran')
        } else {
          message.error(
            'Metode Pembayaran yang dipilih tidak mendukung, Silahkan Pilih Metode lain'
          )
        }
        setLoading(false)
      })
  }

  return (
    <Layout idPage="detailCheckoutPage">
      <div className={styles.content}>
        <div className="container">
          <div className={styles.titleGroup}>
            <div className={styles.title}>
              {/* <ButtonBack urlRoute="/keranjang" /> */}
              <h2>Pilih Metode Pembayaran</h2>
            </div>
            <p>Pilih salah satu untuk metode pembayaran</p>
          </div>
          <FetchLoading data={produk}>
            <Row justify="space-between">
              <Col sm={{ span: 24 }} xl={{ span: 8 }}>
                <Card className={styles.card} id="listPelatihan">
                  <h3 className={styles.title}>List pelatihan yang dibeli</h3>
                  {produk?.map((item, index) => (
                    <div key={index}>
                      <div className={styles.pelatihan}>
                        <img
                          className={styles.cover}
                          src={`${BASE_URL_IMAGE_PROGRAM}/${item.cover}`}
                          alt="poster"
                        />
                        <div>
                          <p className={styles.category}>
                            {item.category_name}
                          </p>
                          <p className={styles.name}>{item.product_name}</p>
                        </div>
                      </div>
                      <div className={styles.priceDetail}>
                        <p className={styles.price}>
                          Rp{' '}
                          {item.discount === '0'
                            ? item.price
                            : item.promo_price}
                        </p>
                        <p className={styles.priceBeforDiscount}>
                          {item.discount === '0' ? null : `Rp ${item.price}`}
                        </p>
                      </div>
                    </div>
                  ))}
                </Card>
                <Kupon kupon={kupon} currentUser={currentUser} />
              </Col>
              <Col sm={{ span: 24 }} xl={{ span: 12 }}>
                <div className={styles.paymentMethod}>
                  <Card style={{ width: '100%' }}>
                    <div className={styles.method}>
                      <p className={styles.titleMethod}>Virtual Account</p>
                      <Radio.Group
                        onChange={selectMethod}
                        buttonStyle="solid"
                        optionType="button"
                        size="large"
                        style={{ width: '100%' }}>
                        <Row gutter={[16, 16]}>
                          {paymentMethod?.VIRTUAL_ACCOUNT.data.map(
                            (method, index) => (
                              <>
                                {method.is_activated === true ? (
                                  <Col
                                    key={index}
                                    xs={{ span: 24 }}
                                    xl={{ span: 12 }}>
                                    <Card>
                                      <Radio value={method.code}>
                                        <div className={styles.radioMethod}>
                                          <img
                                            src={
                                              method.name ===
                                              'Bank Central Asia'
                                                ? BCA
                                                : method.name ===
                                                  'Bank Jabar Banten'
                                                ? BJB
                                                : method.name ===
                                                  'Bank Negara Indonesia'
                                                ? BNI
                                                : method.name ===
                                                  'Bank Rakyat Indonesia'
                                                ? BRI
                                                : method.name ===
                                                  'Bank Syariah Indonesia'
                                                ? BSI
                                                : method.name === 'Bank Mandiri'
                                                ? MANDIRI
                                                : method.name === 'Bank Permata'
                                                ? PERMATA
                                                : method.name ===
                                                  'Bank CIMB Niaga'
                                                ? CIMB
                                                : method.name ===
                                                  'Bank Sahabat Sampoerna'
                                                ? SAHABAT_SAMPOERNA
                                                : method.name === 'ALFAMART'
                                                ? ALFAMART
                                                : method.name === 'INDOMARET'
                                                ? INDOMARET
                                                : null
                                            }
                                            alt="logo"
                                          />
                                          <p>{method.name}</p>
                                        </div>
                                      </Radio>
                                    </Card>
                                  </Col>
                                ) : null}
                              </>
                            )
                          )}
                        </Row>
                        {/* <p
                          className={styles.titleMethod}
                          style={{ marginTop: 20 }}>
                          Retail Outlet
                        </p>
                        <Row gutter={[16, 16]}>
                          {paymentMethod?.RETAIL_OUTLET.data.map(
                            (method, index) => (
                              <Col
                                key={index}
                                xs={{ span: 24 }}
                                xl={{ span: 12 }}>
                                <Card>
                                  <Radio value={method.code}>
                                    <div className={styles.radioMethod}>
                                      <img
                                        src={
                                          method.name ===
                                          'Alfamart retail outlet'
                                            ? ALFAMART
                                            : method.name ===
                                              'Indomaret retail outlet'
                                            ? INDOMARET
                                            : null
                                        }
                                        alt="logo"
                                      />
                                      <p>{method.name}</p>
                                    </div>
                                  </Radio>
                                </Card>
                              </Col>
                            )
                          )}
                        </Row> */}
                        <p
                          className={styles.titleMethod}
                          style={{ marginTop: 20 }}>
                          E-Wallet
                        </p>
                        <Row gutter={[16, 16]}>
                          {/* {paymentMethod?.EWALLET.data.map((method, index) => (
                            <Col
                              key={index}
                              xs={{ span: 24 }}
                              xl={{ span: 12 }}>
                              <Card>
                                <Radio value={method.code}>
                                  <div className={styles.radioMethod}>
                                    <img
                                      src={
                                        method.name === 'ovo'
                                          ? OVO
                                          : method.name === 'dana'
                                          ? DANA
                                          : method.name === 'shopee pay'
                                          ? SHOPEEPAY
                                          : method.name === 'link aja'
                                          ? LINKAJA
                                          : null
                                      }
                                      alt="logo"
                                    />
                                    <p>{method.name.toUpperCase()}</p>
                                  </div>
                                </Radio>
                              </Card>
                            </Col>
                          ))} */}
                          <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                            <Card>
                              <Radio
                                value={paymentMethod?.EWALLET.data[0].code}>
                                <div className={styles.radioMethod}>
                                  <img src={OVO} alt="logo" />
                                  <p>
                                    {paymentMethod?.EWALLET.data[0].name.toUpperCase()}
                                  </p>
                                </div>
                              </Radio>
                            </Card>
                          </Col>
                          <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                            <Card>
                              <Radio
                                value={paymentMethod?.EWALLET.data[1].code}>
                                <div className={styles.radioMethod}>
                                  <img src={DANA} alt="logo" />
                                  <p>
                                    {paymentMethod?.EWALLET.data[1].name.toUpperCase()}
                                  </p>
                                </div>
                              </Radio>
                            </Card>
                          </Col>
                          <Col
                            xs={{ span: 24 }}
                            xl={{ span: 12 }}
                            className={styles.mobileMethod}>
                            <Card>
                              <Radio
                                value={paymentMethod?.EWALLET.data[2].code}>
                                <div className={styles.radioMethod}>
                                  <img src={SHOPEEPAY} alt="logo" />
                                  <p>
                                    {paymentMethod?.EWALLET.data[2].name.toUpperCase()}
                                  </p>
                                </div>
                              </Radio>
                            </Card>
                          </Col>
                          {/* <Col xs={{ span: 24 }} xl={{ span: 12 }}>
                            <Card>
                              <Radio
                                value={paymentMethod?.EWALLET.data[3].code}>
                                <div className={styles.radioMethod}>
                                  <img src={LINKAJA} alt="logo" />
                                  <p>
                                    {paymentMethod?.EWALLET.data[3].name.toUpperCase()}
                                  </p>
                                </div>
                              </Radio>
                            </Card>
                          </Col> */}
                        </Row>
                      </Radio.Group>
                    </div>
                  </Card>
                </div>
              </Col>
            </Row>
            <p className={styles.pricingTittle}>Rincian Pembayaran</p>
            <div className={styles.pricingInfo}>
              <Card style={{ width: '100%', paddingLeft: 20 }}>
                <Row style={{ marginBottom: 10 }}>
                  <Col xs={{ span: 24 }} sm={{ span: 18 }}>
                    <p>
                      <b>Subtotal Produk</b>
                    </p>
                  </Col>
                  <Col xs={{ span: 8 }} sm={{ span: 4 }}>
                    <p>
                      Rp{' '}
                      {countTotal?.subtotal
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                    </p>
                  </Col>
                </Row>
                <Row style={{ marginBottom: 10 }}>
                  <Col xs={{ span: 24 }} sm={{ span: 18 }}>
                    <p>
                      <b>Total Diskon</b>
                    </p>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 4 }}>
                    <p>
                      Rp{' '}
                      {(countTotal?.subtotal - countTotal?.total)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                      {` (-)`}
                    </p>
                  </Col>
                </Row>
                {/* <Row style={{ marginBottom: 10 }}>
                  <Col span={18}>
                    <p>
                      <b>Biaya Penanganan {`(Xendit)`}</b>
                    </p>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 4 }}>
                    <p>
                      Rp{' '}
                      {method === 'INDOMARET' || method === 'ALFAMART'
                        ? paymentMethod?.RETAIL_OUTLET.pricing
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                        : method === null
                        ? 0
                        : paymentMethod?.VIRTUAL_ACCOUNT.pricing
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                    </p>
                  </Col>
                </Row> */}
                <Row className={styles.totalHarga} style={{ marginTop: 10 }}>
                  <Col xs={{ span: 24 }} sm={{ span: 18 }}>
                    <p>Total Pembayaran</p>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 4 }}>
                    <p>
                      Rp{' '}
                      {countTotal?.total
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                      {/* {method === 'INDOMARET' || method === 'ALFAMART'
                        ? (
                            countTotal?.total +
                            paymentMethod?.RETAIL_OUTLET.pricing
                          )
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                        : method === null
                        ? countTotal?.total
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                        : (
                            countTotal?.total +
                            paymentMethod?.VIRTUAL_ACCOUNT.pricing
                          )
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')} */}
                    </p>
                  </Col>
                </Row>
              </Card>
            </div>
            <div className={styles.bayarButtonCenter}>
              <Button
                className={styles.bayarButton}
                onClick={onCreateVA}
                loading={loading}>
                Buat Pesanan
              </Button>
            </div>
            <Modal
              centered
              visible={visibleModalOVO}
              footer={null}
              onCancel={() => window.location.reload()}>
              <div className={styles.modalContainer}>
                <InfoCircleTwoTone style={{ fontSize: 50 }} />
                <p className={styles.modalTitle}>
                  Silahkan Cek Notifikasi pembayaran OVO diperangkat mobile anda
                  pada nomor {currentUser.user.phone}, jika sudah berhasil
                  silahkan cek dashboard transaksi
                </p>
                <ButtonLink
                  title="Lihat Transaksi"
                  urlRoute="/dashboard/transaksi"
                />
              </div>
            </Modal>
            <Modal
              centered
              visible={visibleModalEwallet}
              footer={null}
              onCancel={() => window.location.reload()}>
              <div className={styles.modalContainer}>
                <InfoCircleTwoTone style={{ fontSize: 50 }} />
                <p className={styles.modalTitle}>
                  Silahkan lakukan pembayaran melalui {method?.substring(3)},
                  jika sudah berhasil silahkan cek dashboard transaksi
                </p>
                <ButtonLink
                  title="Lihat Transaksi"
                  urlRoute="/dashboard/transaksi"
                />
              </div>
            </Modal>
          </FetchLoading>
        </div>
      </div>
    </Layout>
  )
}

export default DetailCheckout
