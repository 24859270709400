//! this file for select data from reducer
//! and called in mapTopProps
import { createSelector } from 'reselect'
const selectProduct = (state) => state.program

// START HANDLING SEARCH PROGRAM
export const selectSearchField = createSelector(
  [selectProduct],
  (program) => program.searchField
)
// END HANDLING SEARCH PROGRAM