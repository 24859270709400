import React from 'react'
import { Link } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'
const ButtonBack = ({ urlRoute = '/' }) => {
  return (
    <Link to={urlRoute}>
      <ArrowLeftOutlined
        style={{
          fontSize: 30,
          color: '#223A5E',
          marginRight: 20,
          marginTop: 15,
        }}
      />
    </Link>
  )
}

export default ButtonBack
