import React, { useState, useCallback, useEffect } from 'react'
import styles from './FloatBtnGroup.module.css'
import { ReactComponent as IcArrowUp } from '../../assets/icon/ic-arrow-up-ios.svg'
import IcWhatasappFloat from './assets/whatsappFloatIcon.png'
import { animateScroll as scroll } from 'react-scroll'

const FloatBtnGroup = () => {
  const [windowsY, setY] = useState(window.scrollY)
  const [windowScrollUp, setWindowScrollUp] = useState(true)

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget
      if (windowsY > window.scrollY) setWindowScrollUp(true)
      else if (windowsY < window.scrollY) setWindowScrollUp(false)

      setY(window.scrollY)
    },
    [windowsY]
  )

  useEffect(() => {
    setY(window.scrollY)
    window.addEventListener('scroll', handleNavigation)
    return () => {
      window.removeEventListener('scroll', handleNavigation)
    }
  }, [handleNavigation])

  return (
    <div
      className={`${styles.floatBtnGroup} ${
        windowScrollUp ? '' : styles.floatBtnGroupHidden
      }`}>
      <div className={styles.floatContainer}>
        <div
          className={`${styles.circle} ${styles.scrollUp}`}
          onClick={() => scroll.scrollToTop()}>
          <IcArrowUp />
        </div>
        <a
          href="https://api.whatsapp.com/send/?phone=6282217777616&text=%23askmika%20Halo%2C%20Mika!%20Saya%20ingin%20bertanya%20mengenai.."
          target="_blank"
          rel="noreferrer"
          className={`${styles.circle} ${styles.whatasapp}`}>
          <img src={IcWhatasappFloat} alt="whatsapp" />
        </a>
      </div>
    </div>
  )
}

export default FloatBtnGroup
