import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar'
import Layout from '../../../../components/Layout'
import styles from './ProfileLayout.module.css'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { removeCurrentUser } from '../../../../redux/user/user.action'
import { message } from 'antd'
import { selectCurrentUser } from '../../../../redux/user/user.selectors'

const ProfileLayout = ({ id = '', title = '', text = '', children }) => {
  const currentUser = useSelector(selectCurrentUser)
  const [dataUser, setDataUser] = useState(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    axios({
      method: 'get',
      url: '/profile',
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    })
      .then(({ data }) => {
        setDataUser(data)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate('/masuk')
          dispatch(removeCurrentUser())
        }
        if (error.response.status === 500) {
          message.error('We are Under Maintenance')
        }
      })
  }, [currentUser.token, dispatch, navigate])

  return (
    <Layout idPage="profilPage">
      <div id={`${id}`} className={styles.container}>
        <Sidebar name={dataUser?.user.name} />
        <main className={styles.content}>
          {title.length ? (
            <>
              <h1 className={styles.title}>{title}</h1>
              <p className={styles.text}>{text}</p>
            </>
          ) : null}
          {children}
        </main>
      </div>
    </Layout>
  )
}

export default ProfileLayout
