import React, { useState, useEffect } from 'react'
import Layout from '../../components/Layout'
import axios from 'axios'
import { useParams, useNavigate } from 'react-router-dom'
import styles from './DetailKelas.module.css'
import { InfoProgram, SilabusMateri, FasilitasPelatihan } from './components'
import BelajarMandiri from '../../components/BelajarMandiri'

import { message, Spin } from 'antd'
import { Link } from 'react-router-dom'
import { ReactComponent as IcArrowRight } from '../../assets/icon/ic-arrow-right.svg'

import './DetailKelas.scss'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../redux/user/user.selectors'
import PersiapanKelas from './components/PersiapanKelas'

const DetailKelas = () => {
  const currentUser = useSelector(selectCurrentUser)
  const [dataUser, setDataUser] = useState(null)
  const [program, setProgram] = useState(null)
  let { slug } = useParams()
  let navigate = useNavigate()

  useEffect(() => {
    axios({
      method: 'get',
      url: `/user-classroom/${slug}`,
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    })
      .then(({ data }) => {
        setProgram(data)
      })
      .catch((error) => {
        if (error.response.status === 500) {
          message.error('We are Under Maintenance')
        }
      })
    if (currentUser) {
      axios({
        method: 'get',
        url: '/profile',
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      }).then(({ data }) => {
        setDataUser(data)
      })
    }
  }, [slug, navigate, currentUser])

  return (
    <Layout idPage="program-detail-page">
      {program === null ? (
        <div className="loadingContainer">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className="container">
            <InfoProgram program={program} dataUser={dataUser} />
            <div className={`${styles.silabusFasilitasGroup}`}>
              <div className={styles.detailProgramCard}>
                <div className={styles.detailProgramCardInner}>
                  <SilabusMateri program={program} />
                </div>
              </div>
              <div className={styles.detailProgramCard}>
                <div className={styles.detailProgramCardInner}>
                  <PersiapanKelas program={program} />
                </div>
                <div
                  className={styles.detailProgramCardInner}
                  style={{ marginTop: 30 }}>
                  <FasilitasPelatihan program={program} />
                </div>
              </div>
            </div>
            <div className={styles.btnLinkToProgram}>
              <Link to="/program">
                <button type="button" className={`btn btn-primary btn-w-icon`}>
                  Lihat Semua Pelatihan
                  <IcArrowRight />
                </button>
              </Link>
            </div>
          </div>
          <BelajarMandiri />
        </>
      )}
    </Layout>
  )
}

export default DetailKelas
