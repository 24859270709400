import React from 'react'
import { Input, AutoComplete } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

import { useDispatch } from 'react-redux'
import { setSearchProductField } from '../../../../redux/program/program.action'

import './SearchProgram.scss'

const SearchProgram = ({ optionSearchField, initSearchField = '' }) => {
  const dispatch = useDispatch()
  const handleSearch = (value) => {
    dispatch(setSearchProductField(value))
  }

  return (
    <div id="program-page__search-program">
      <AutoComplete
        options={optionSearchField}
        filterOption={(inputValue, option) =>
          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        onChange={handleSearch}
        defaultValue={initSearchField}
        notFoundContent="Pencarian Tidak Ditemukan">
        <Input
          allowClear
          bordered={false}
          size="large"
          placeholder="Cari pelatihan disini"
          prefix={<SearchOutlined />}
          required
        />
      </AutoComplete>
    </div>
  )
}

export default SearchProgram
