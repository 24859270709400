import React from 'react'
import { Modal } from 'antd'
import styles from './ModalProgramDurasi.module.css'

const ModalProgramDurasi = ({ durasiModal, setdurasiModal }) => {
  return (
    <Modal
      id="program-durasi-modal"
      className={styles.durasiModal}
      title="Durasi"
      visible={durasiModal}
      onCancel={() => setdurasiModal(false)}
      footer={null}>
      <div className={styles.durasiTable}>
        <div className={`${styles.durasiTableRow} ${styles.durasiTableHeader}`}>
          <div className={styles.durasiTableCol}>No</div>
          <div className={styles.durasiTableCol}>Topik/Chapter/Bab bahasan</div>
          <div className={styles.durasiTableCol}>Durasi (menit)</div>
        </div>
        <div className={`${styles.durasiTableRow} ${styles.durasiTableColParent}`}>
          <div className={styles.durasiTableCol}>1</div>
          <div className={styles.durasiTableCol}>
            Bab 1 Sistem Manajemen Keamanan Pangan (ISO 22000:2018)
          </div>
          <div className={styles.durasiTableCol}>90 menit</div>
        </div>
        <div className={`${styles.durasiTableRow}`}>
          <div className={styles.durasiTableCol}>-</div>
          <div className={styles.durasiTableCol}>
            1.1 Memahami dan menerapkan Sistem Manajemen Keamanan Pangan
            berdasarkan ISO 22000:2018 [WEBINAR]
          </div>
          <div className={styles.durasiTableCol}>50 menit</div>
        </div>
        <div className={`${styles.durasiTableRow}`}>
          <div className={styles.durasiTableCol}>-</div>
          <div className={styles.durasiTableCol}>
            1.2 Memahami dan menerapkan persyaratan serta prosedur ISO
            22000:2018 [WEBINAR]
          </div>
          <div className={styles.durasiTableCol}>15 menit</div>
        </div>
        <div className={`${styles.durasiTableRow}`}>
          <div className={styles.durasiTableCol}>-</div>
          <div className={styles.durasiTableCol}>
            1.3 Melakukan validasi dan verifikasi ISO 22000:2018 [WEBINAR]
          </div>
          <div className={styles.durasiTableCol}>15 menit</div>
        </div>
        <div className={`${styles.durasiTableRow}`}>
          <div className={styles.durasiTableCol}>-</div>
          <div className={styles.durasiTableCol}>
            1.4 Mampu mendokumentasikan penerapan ISO 22000:2018 dalam bentuk
            manual [WEBINAR]
          </div>
          <div className={styles.durasiTableCol}>10 menit</div>
        </div>
        <div className={`${styles.durasiTableRow}`}>
          <div className={styles.durasiTableCol}>2</div>
          <div className={styles.durasiTableCol}>
            Cara Produksi Pangan yang Baik (GMP) dan Prosedur Sanitasi dan
            Hygiene (SSOP)
          </div>
          <div className={styles.durasiTableCol}>90 menit</div>
        </div>
        <div className={`${styles.durasiTableRow}`}>
          <div className={styles.durasiTableCol}>-</div>
          <div className={styles.durasiTableCol}>
            2.1 Mampu mendesain atau menetapkan (GMP) sebagai syarat dasar
            sistem keamanan pangan [WEBINAR]
          </div>
          <div className={styles.durasiTableCol}>15 menit</div>
        </div>
        <div className={`${styles.durasiTableRow}`}>
          <div className={styles.durasiTableCol}>-</div>
          <div className={styles.durasiTableCol}>
            2.2 Menerapkan persyaratan dan prosedur GMP [WEBINAR]
          </div>
          <div className={styles.durasiTableCol}>15 menit</div>
        </div>
        <div className={`${styles.durasiTableRow}`}>
          <div className={styles.durasiTableCol}>-</div>
          <div className={styles.durasiTableCol}>
            2.3 Mampu membuat dokumen persyaratan GMP [WEBINAR]
          </div>
          <div className={styles.durasiTableCol}>10 menit</div>
        </div>
        <div className={`${styles.durasiTableRow}`}>
          <div className={styles.durasiTableCol}>-</div>
          <div className={styles.durasiTableCol}>
            2.4 Mampu melakukan monitoring serta melakukan evaluasi dan tindakan
            perbaikan untuk persyaratan GMP [WEBINAR]
          </div>
          <div className={styles.durasiTableCol}>10 menit</div>
        </div>
        <div className={`${styles.durasiTableRow}`}>
          <div className={styles.durasiTableCol}>-</div>
          <div className={styles.durasiTableCol}>
            2.5 Mampu mendesain atau menetapkan prosedur SSOP sebagai prosedur
            kerja untuk menjaga kualitas makanan dan minuman di industri pangan
            [WEBINAR]
          </div>
          <div className={styles.durasiTableCol}>10 menit</div>
        </div>
        <div className={`${styles.durasiTableRow}`}>
          <div className={styles.durasiTableCol}>-</div>
          <div className={styles.durasiTableCol}>
            2.6 Menerapkan persyaratan dan prosedur SSOP [WEBINAR]
          </div>
          <div className={styles.durasiTableCol}>10 menit</div>
        </div>
        <div className={`${styles.durasiTableRow}`}>
          <div className={styles.durasiTableCol}>-</div>
          <div className={styles.durasiTableCol}>
            2.7 Mampu melakukan monitoring prosedur SSOP berdasarkan form
            checklist [WEBINAR]
          </div>
          <div className={styles.durasiTableCol}>10 menit</div>
        </div>
        <div className={`${styles.durasiTableRow}`}>
          <div className={styles.durasiTableCol}>-</div>
          <div className={styles.durasiTableCol}>
            2.8 Mampu melakukan evaluasi dan tindakan perbaikan prosedur SSOP
            [WEBINAR]
          </div>
          <div className={styles.durasiTableCol}>10 menit</div>
        </div>
        <div className={`${styles.durasiTableRow}`}>
          <div className={styles.durasiTableCol}>3</div>
          <div className={styles.durasiTableCol}>
            Sistem Keamanan Pangan (HACCP)
          </div>
          <div className={styles.durasiTableCol}>180 menit</div>
        </div>
        <div className={`${styles.durasiTableRow}`}>
          <div className={styles.durasiTableCol}>-</div>
          <div className={styles.durasiTableCol}>
            3.1 Memahami dan menerapkan Sistem Keamanan Pangan HACCP [WEBINAR]
          </div>
          <div className={styles.durasiTableCol}>30 menit</div>
        </div>
        <div className={`${styles.durasiTableRow}`}>
          <div className={styles.durasiTableCol}>-</div>
          <div className={styles.durasiTableCol}>
            3.2 Mendesain langkah persiapan HACCP [WEBINAR]
          </div>
          <div className={styles.durasiTableCol}>30 menit</div>
        </div>
        <div className={`${styles.durasiTableRow}`}>
          <div className={styles.durasiTableCol}>-</div>
          <div className={styles.durasiTableCol}>
            3.3 Mendesain dan mendokumentasikan 7 prinsip HACCP dalam bentuk
            HACCP Plan [WEBINAR]
          </div>
          <div className={styles.durasiTableCol}>90 menit</div>
        </div>
        <div className={`${styles.durasiTableRow}`}>
          <div className={styles.durasiTableCol}>-</div>
          <div className={styles.durasiTableCol}>
            3.4 Melakukan monitoring, verifikasi, evaluasi, dan tindakan
            perbaikan terhadap penerapan HACCP [WEBINAR]
          </div>
          <div className={styles.durasiTableCol}>90 menit</div>
        </div>
        <div className={`${styles.durasiTableRow} ${styles.durasiTableFooter}`}>
          <div className={styles.durasiTableCol}>
            Total
          </div>
          <div className={styles.durasiTableCol}>360 menit</div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalProgramDurasi
