import React, { useState } from 'react'
import styles from './ResetPassword.module.css'

// COMPONENTS
import { ReactComponent as LogoMindoWhite } from '../../assets/images/logo/logo-mindo--white.svg'
import { Form, Input, Button, message } from 'antd'

import './ResetPassword.scss'
import axios from 'axios'
import Modal from 'antd/lib/modal/Modal'
import { CheckCircleTwoTone } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { ButtonLink } from '../../components'

const ResetPassword = () => {
  const url_string = window.location.href.toString()
  const url = new URL(url_string)
  const token = url.searchParams.get('token')
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const navigate = useNavigate()

  const onFinish = (values) => {
    setLoading(true)
    const { email, password, password_confirmation } = values
    const data = JSON.stringify({
      email,
      password,
      password_confirmation,
      token,
    })
    axios({
      method: 'post',
      url: '/reset-password',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then(() => {
        setModalVisible(true)
        setLoading(false)
      })
      .catch((error) => {
        if (error.response.status === 500) {
          message.error('We are Under Maintenance')
        }
        setLoading(false)
      })
  }

  return (
    <div id="resetPass-page">
      <div className={styles.container}>
        <div className={styles.card}>
          <div className={styles.header}>
            <LogoMindoWhite />
          </div>
          <div className={styles.content}>
            <p>
              Form <span>Reset Password</span>
            </p>
            <Form form={form} name="loginForm" onFinish={onFinish}>
              <Form.Item
                name="email"
                hasFeedback
                rules={[
                  {
                    type: 'email',
                    message: 'Email Tidak Valid',
                  },
                  {
                    required: true,
                    message: '',
                  },
                ]}>
                <Input placeholder="Alamat e-mail " size="large" />
              </Form.Item>
              <Form.Item
                name="password"
                hasFeedback
                rules={[
                  {
                    required: true,
                    min: 8,
                    message: 'Minimal 8 Karakter',
                  },
                ]}>
                <Input.Password placeholder="Password" size="large" />
              </Form.Item>
              <Form.Item
                name="password_confirmation"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }

                      return Promise.reject(
                        new Error('Password yang anda masukkan tidak sesuai')
                      )
                    },
                  }),
                ]}>
                <Input.Password
                  placeholder="Konfirmasi Password"
                  size="large"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  block
                  className={styles.btnSubmit}>
                  Reset Password
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
        <Modal
          centered
          visible={modalVisible}
          footer={null}
          onCancel={() => navigate('/masuk')}>
          <div className={styles.modalContainer}>
            <CheckCircleTwoTone
              twoToneColor="#1BBC34"
              style={{ fontSize: 50 }}
            />
            <p className={styles.modalTitle}>
              Password berhasil direset, Silahkan Login kembali
            </p>
            <ButtonLink urlRoute="/masuk" title="Oke" />
          </div>
        </Modal>
      </div>
    </div>
  )
}

export default ResetPassword
