import React from 'react'
import './SilabusMateri.scss'
import { Collapse } from 'antd'
import Moment from 'react-moment'

const { Panel } = Collapse

const SilabusMateri = ({ program }) => {
  return (
    <div id="program-detail-page__silabus-materi">
      <Collapse accordion expandIconPosition="right" ghost>
        <Panel header="Kurikulum Program" key="Kurikulum Program">
          <div
            dangerouslySetInnerHTML={{
              __html: `${program.syllabus}`,
            }}></div>
        </Panel>
        <Panel header="Info Program" key="Info Program">
          <div
            dangerouslySetInnerHTML={{
              __html: `${program.description}`,
            }}></div>
        </Panel>
        <Panel header="Manfaat Program" key="Manfaat Program">
          <div>
            <ol>
              <li>
                Peserta mendapatkan pelatihan yang sesuai dengan bidang karir
                yang diinginkan
              </li>
              <li>
                Peserta mendapatkan gambaran permasalahan dan cara penyelesaian
                melalui studi kasus
              </li>
              <li>
                Peserta mendapatkan gambaran koordinasi dan kerjasama antar
                departemen (Quality & R&D) dalam membuat dan menyelesaikan
                produk melalui FGD
              </li>
              <li>
                Peserta mendapatkan gambaran tugas dan tanggung jawab dari karir
                yang dipilihnya melalui workshop
              </li>
              <li>
                Peserta mengetahui bagaimana bentuk CV dan Motivation Letter
                yang baik dari sudut pandang HR
              </li>
              <li>
                Peserta mengetahui bagaimana langkah-langkah interview dan
                bagaimana menjawab interview dari sudut pandang HR
              </li>
            </ol>
          </div>
        </Panel>
        <Panel header="Tanggal Pelaksanaan" key="Tanggal Pelaksanaan">
          <div>
            <p>
              Jadwal :{' '}
              <Moment locale="id" date={program.time_start} format="DD MMMM" />{' '}
              -{' '}
              <Moment
                locale="id"
                date={program.time_end}
                format="DD MMMM YYYY"
              />
            </p>
            <p>Durasi : 2 Minggu</p>
            <p>Kelas : Setiap Rabu, Sabtu dan Minggu</p>
          </div>
        </Panel>
        {program.slug === 'mindo-intensive-bootcamp-quality-department' ? (
          <Panel header="Timeline Pelatihan" key="Timeline Pelatihan">
            <a
              href="https://drive.google.com/file/d/1UstkP7VDWdPLzAu3-0SGVKOQlEJihA0B/view"
              className="termCondition"
              target="_blank"
              rel="noreferrer">
              Berikut Timeline Pelatihan
            </a>
          </Panel>
        ) : program.slug === 'mindo-intensive-bootcamp-rd-department' ? (
          <Panel header="Timeline Pelatihan" key="Timeline Pelatihan">
            <a
              href="https://drive.google.com/file/d/1QaDlsruP68rFJekUG-hA3js3579nu9EA/view"
              className="termCondition"
              target="_blank"
              rel="noreferrer">
              Berikut Timeline Pelatihan
            </a>
          </Panel>
        ) : null}
        <Panel header="Fasilitas Pelatihan" key="Fasilitas Pelatihan">
          <div>
            <ul>
              <li>
                Modul Bootcamp (termasuk juknis, timeline kegiatan, materi
                pelatihan, rincian tugas, dok. pendukung dan studi kasus)
              </li>
              <li>Sertifikat Pelatihan</li>
              <li>Sertifikat Bootcamp</li>
              <li>Voucher Belajar</li>
              <li>Rekaman Pelatihan</li>
              <li>Video Learning</li>
              <li>Akses Konsultasi</li>
              <li>Competency Test</li>
              <li>Materi Pelatihan PP</li>
              <li>Sesi kelas bedah CV dan Interview</li>
              <li>Sesi kelas pembuatan Motivation Letter</li>
              <li>Bimbingan karir</li>
              <li>Grup Belajar</li>
            </ul>
          </div>
        </Panel>
        <Panel header="Syarat dan Ketentuan" key="Syarat dan Ketentuan">
          <a
            href={program.term_condition}
            className="termCondition"
            target="_blank"
            rel="noreferrer">
            Berikut Syarat dan Ketentuan Program Bootcamp
          </a>
        </Panel>
      </Collapse>
    </div>
  )
}

export default SilabusMateri
