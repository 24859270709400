import { Col, message, Row, Spin } from 'antd'
import axios from 'axios'
import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ProgramKeranjang } from '../../components'
import Layout from '../../components/Layout'
import emptyCart from './assets/empty-cart.svg'
import { selectCurrentUser } from '../../redux/user/user.selectors'
import { Kupon } from './components'

import styles from './Keranjang.module.css'
import { useNavigate } from 'react-router-dom'
import { removeCurrentUser } from '../../redux/user/user.action'

const Keranjang = () => {
  const [produk, setProduk] = useState(null)
  const [kupon, setKupon] = useState(null)
  const currentUser = useSelector(selectCurrentUser)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(() => {
    axios({
      method: 'get',
      url: '/cart',
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    })
      .then(({ data }) => {
        setKupon(data.coupon)
        setProduk(data.product)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate('/masuk')
          dispatch(removeCurrentUser())
        }
        if (error.response.status === 500) {
          message.error('We are Under Maintenance')
        }
      })
  }, [currentUser.token, dispatch, navigate])

  return (
    <Layout idPage="keranjangPage">
      <div className={styles.productPage}>
        {produk?.length === 0 ? (
          <div className="container">
            <div className={styles.titleGroup}>
              <h2>Keranjang Belanja</h2>
              <p>{produk?.length} Pelatihan di keranjang</p>
            </div>
            <Row gutter={[16, 16]} justify="space-between">
              <Col xl={{ span: 8 }}>
                <div className={styles.emptyCart}>
                  <img src={emptyCart} alt="emptyCart" />
                </div>
              </Col>
              <Col sm={{ span: 24 }} xl={{ span: 8 }}>
                <Kupon
                  product={produk}
                  kupon={kupon}
                  currentUser={currentUser}
                />
              </Col>
            </Row>
          </div>
        ) : produk?.length > 0 ? (
          <div className="container">
            <div className={styles.titleGroup}>
              <h2>Keranjang Belanja</h2>
              <p>{produk?.length} Pelatihan di keranjang</p>
            </div>
            <Row gutter={[16, 16]} justify="space-between">
              <ProgramKeranjang product={produk} currentUser={currentUser} />
              <Col sm={{ span: 24 }} xl={{ span: 8 }}>
                <Kupon
                  product={produk}
                  kupon={kupon}
                  currentUser={currentUser}
                />
              </Col>
            </Row>
          </div>
        ) : (
          <div className={styles.container}>
            <Spin size="large" />
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Keranjang
