import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { BASE_URL_IMAGE_PROGRAM } from '../../utils'
import { ReactComponent as IconAddShoppingChart } from './assets/icons/iconAddShoppingChart.svg'
import { ReactComponent as IconStar } from './assets/icons/iconStar.svg'
import { ReactComponent as IconJam } from './assets/icons/iconJam.svg'
import styles from './CardProgram.module.css'
import axios from 'axios'

import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser } from '../../redux/user/user.selectors'
import { message, Modal } from 'antd'
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  ExclamationCircleTwoTone,
} from '@ant-design/icons'
import ButtonLink from '../ButtonLink'
import { removeCurrentUser } from '../../redux/user/user.action'

const CardProgram = ({ program = null, dataUser = null }) => {
  const [modalVisibleSuccess, setModalVisibleSuccess] = useState(false)
  const [modalVisibleFail, setModalVisibleFail] = useState(false)
  const [modalVisibleAuth, setModalVisibleAuth] = useState(false)
  const [modalVisibleStatus, setModalVisibleStatus] = useState(false)

  const [messageError, setMessageError] = useState('')
  const currentUser = useSelector(selectCurrentUser)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    id,
    link_campsite,
    cover,
    rating,
    // type,
    category,
    name,
    discount,
    price,
    promo_price,
    hours,
    time_start,
  } = program

  const addCart = (id) => {
    let data = JSON.stringify({
      product_id: id,
    })

    axios({
      method: 'post',
      url: '/cart',
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then(() => {
        setModalVisibleSuccess(true)
      })
      .catch((error) => {
        setMessageError(error.response.data.message)
        if (error.response.status === 401) {
          navigate('/masuk')
          dispatch(removeCurrentUser())
        }
        if (error.response.status === 500) {
          message.error('We are Under Maintenance')
        }
        if (
          error.response.data.message === 'Your email address is not verified.'
        ) {
          navigate('/verify')
        } else {
          setModalVisibleFail(true)
        }
        console.log(error.response)
      })
  }

  return program ? (
    <div className={styles.cardProgram}>
      <Link
        to={
          program?.category?.name === 'Bootcamp'
            ? `/bootcamp/${program.slug}`
            : `/program/${program.slug}`
        }>
        <div className={styles.thumbnail}>
          <img src={`${BASE_URL_IMAGE_PROGRAM}/${cover}`} alt="poster" />
        </div>
        <div className={styles.programDescription}>
          <div className={styles.programInfo}>
            <div className={styles.programInfoRatingGroup}>
              <IconStar />
              <p>{rating}</p>
            </div>
            <div className={styles.verticalLine}></div>
            <div className={styles.programInfoDuration}>
              <IconJam />
              {program?.category?.name === 'Bootcamp' ? (
                <p>2 Minggu</p>
              ) : (
                <p>{hours} Jam</p>
              )}
            </div>
            <div className={styles.verticalLine}></div>
            {/* <div className={styles.programInfotrainingTypeGroup}>
              <p>{type}</p>
            </div> */}
            <div className={styles.programInfoTimeStart}>
              <p>{time_start.slice(0, 10).split('-').reverse().join('-')}</p>
            </div>
          </div>
          <div className={styles.category}>
            <p>{category.name}</p>
          </div>
          <div className={styles.titleContainer}>
            <h3 className={styles.title}>{name}</h3>
          </div>
          <div className={styles.footer}>
            <div className={styles.priceGroup}>
              <p className={styles.price}>
                Rp {discount === '0' ? price : promo_price}
              </p>
              <div
                className={`${styles.priceDiscountGroup} ${
                  discount === '0' && styles.priceDiscountGroupHidden
                }`}>
                <p className={styles.priceBeforDiscount}>Rp {price}</p>
                <p className={styles.pricePercentDiscount}>-{discount}%</p>
              </div>
            </div>
          </div>
        </div>
      </Link>
      <div id="cardProductAddToChart" className={styles.addToChartContainer}>
        {link_campsite === '/form-prakerja' ? (
          <Link to="/form-pendaftaran">
            <IconAddShoppingChart style={{ fontSize: 23 }} />
          </Link>
        ) : currentUser === null ? (
          <IconAddShoppingChart onClick={() => setModalVisibleAuth(true)} />
        ) : dataUser?.user.status === null ? (
          <IconAddShoppingChart onClick={() => setModalVisibleStatus(true)} />
        ) : (
          <IconAddShoppingChart onClick={() => addCart(id)} />
        )}
      </div>
      <Modal
        centered
        visible={modalVisibleSuccess}
        footer={null}
        onCancel={() => setModalVisibleSuccess(false)}>
        <div className={styles.modalContainer}>
          <CheckCircleTwoTone twoToneColor="#1BBC34" style={{ fontSize: 50 }} />
          <p className={styles.modalTitle}>
            Produk Berhasil ditambahkan ke keranjang
          </p>
          <ButtonLink title="Lihat Keranjang" urlRoute="/keranjang" />
        </div>
      </Modal>
      <Modal
        centered
        visible={modalVisibleFail}
        footer={null}
        onCancel={() => setModalVisibleFail(false)}>
        <div className={styles.modalContainer}>
          <CloseCircleTwoTone twoToneColor="#FB0100" style={{ fontSize: 50 }} />
          <p className={styles.modalTitle}>{messageError}</p>
        </div>
      </Modal>
      <Modal
        centered
        visible={modalVisibleAuth}
        footer={null}
        onCancel={() => setModalVisibleAuth(false)}>
        <div className={styles.modalContainer}>
          <ExclamationCircleTwoTone
            twoToneColor="#ffa940"
            style={{ fontSize: 50 }}
          />
          <p className={styles.modalTitle}>
            Silahkan masuk terlebih dahulu, untuk menambahkan ke keranjang
          </p>
          <ButtonLink title="Masuk" urlRoute="/masuk" />
        </div>
      </Modal>
      <Modal
        centered
        visible={modalVisibleStatus}
        footer={null}
        onCancel={() => setModalVisibleStatus(false)}>
        <div className={styles.modalContainer}>
          <ExclamationCircleTwoTone
            twoToneColor="#ffa940"
            style={{ fontSize: 50 }}
          />
          <p className={styles.modalTitle}>
            Silahkan lengkapi profil terlebih dahulu
          </p>
          <ButtonLink title="Profil" urlRoute="/dashboard/profile" />
        </div>
      </Modal>
    </div>
  ) : (
    <div></div>
  )
}

export default CardProgram
