// FILLTERING PROGRAM BY SEARCH PROGRAM OR CATEGORY
const handleFilterCategory = (datas, categoryField) => {
  return datas.filter((data) => data.category.code_category === categoryField)
}

const handleFilterSearch = (datas, searchField) => {
  return datas.filter((data) =>
    data.name.toLowerCase().includes(searchField.toLowerCase())
  )
}

export const handleFilteringProgram = (programDatas, searchField, categoryField) => {
  let programFilter = programDatas
  if (searchField.length !== 0 && categoryField.length !== 0) {
    programFilter = handleFilterCategory(programFilter, categoryField)
    programFilter = handleFilterSearch(programFilter, searchField)
  } else if (searchField.length) {
    programFilter = handleFilterSearch(programFilter, searchField)
  } else if (categoryField.length) {
    programFilter = handleFilterCategory(programFilter, categoryField)
  }
  return programFilter
}

// FILTERING SEARCH OPTION SUGGESTION BY CATEGORY
export const handleFilterSearchOption = (programDatas, categoryField) => {
    let searchOption = programDatas
    if (categoryField.length !== 0) {
      searchOption = searchOption.filter(
        (program) => program.category.code_category === categoryField
      )
    }
    searchOption = searchOption.map((program) => {
      return { value: program.name, label: program.name }
    })
    return searchOption
  }