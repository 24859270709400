import './Profile.scss'
import ProfileLayout from '../components/ProfileLayout'
import { Button, Col, Form, Input, message, Row, Select, Tabs } from 'antd'
import { Option } from 'antd/lib/mentions'

import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser } from '../../../redux/user/user.selectors'
import { useEffect } from 'react'
import axios from 'axios'
import { useState } from 'react'
import FetchLoading from '../../../components/FetchLoading'
import { useNavigate } from 'react-router-dom'
import { removeCurrentUser } from '../../../redux/user/user.action'

const { TabPane } = Tabs

const Profile = () => {
  const currentUser = useSelector(selectCurrentUser)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [dataUser, setDataUser] = useState(null)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [tabKey, setTabKey] = useState('1')

  const [form] = Form.useForm()

  useEffect(() => {
    axios({
      method: 'get',
      url: '/profile',
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    })
      .then(({ data }) => {
        setDataUser(data)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate('/masuk')
          dispatch(removeCurrentUser())
        }
        if (error.response.status === 500) {
          message.error('We are Under Maintenance')
        }
      })
  }, [currentUser.token, dispatch, navigate])

  const onFinish = (values) => {
    setSubmitLoading(true)
    const {
      username,
      name,
      // firstname,
      // lastname,
      email,
      city,
      address,
      zip_code,
      phone,
      status,
      university,
      class_year,
      major,
      company,
      position,
      old_password,
      password,
      password_confirmation,
    } = values

    switch (tabKey) {
      case '1':
        const dataPesertaBekerja = JSON.stringify({
          // name:
          //   (firstname ? firstname : null) + ' ' + (lastname ? lastname : null),
          // firstname,
          // lastname,
          name,
          username,
          email,
          city,
          address,
          phone,
          status,
          zip_code,
          company,
          position,
        })
        const dataPesertaMahasiswa = JSON.stringify({
          // name:
          //   (firstname ? firstname : null) + ' ' + (lastname ? lastname : null),
          // firstname,
          // lastname,
          name,
          username,
          email,
          city,
          address,
          phone,
          status,
          zip_code,
          university,
          class_year,
          major,
        })
        const dataProfile =
          status === 'bekerja' ? dataPesertaBekerja : dataPesertaMahasiswa
        axios({
          method: 'patch',
          url: '/profile',
          headers: {
            Authorization: `Bearer ${currentUser.token}`,
            'Content-Type': 'application/json',
          },
          data: dataProfile,
        })
          .then(() => {
            setSubmitLoading(false)
            message.success('Perubahan Berhasil')
            setTimeout(() => {
              window.location.reload()
            }, 500)
          })
          .catch((error) => {
            if (error.response.status === 500) {
              message.error('We are Under Maintenance')
            }
            if (error.response.status === 403) {
              message.error('Pilihan Status tidak ada')
            }
            setSubmitLoading(false)
          })
        break

      case '2':
        const dataPassword = JSON.stringify({
          old_password,
          password,
          password_confirmation,
        })

        axios({
          method: 'patch',
          url: '/change-password',
          headers: {
            Authorization: `Bearer ${currentUser.token}`,
            'Content-Type': 'application/json',
          },
          data: dataPassword,
        })
          .then(() => {
            setSubmitLoading(false)
            form.resetFields()
            message.success('Password Berhasil Diubah')
            setTimeout(() => {
              window.location.reload()
            }, 500)
          })
          .catch((error) => {
            if (error.response.status === 500) {
              message.error('We are Under Maintenance')
            }
            if (error.response.status === 401) {
              message.error('Password harus berbeda dengan password lama')
            }
            setSubmitLoading(false)
          })
        break

      default:
        break
    }
  }

  return (
    <ProfileLayout
      id="profil"
      title="Profil"
      text="Lengkapi informasi dan mulai belajar">
      <div className="container">
        <FetchLoading data={dataUser}>
          <div className="card">
            <Tabs defaultActiveKey="1" onTabClick={(key) => setTabKey(key)}>
              <TabPane tab="Detail Profil" key="1">
                {tabKey === '1' ? (
                  <Form
                    form={form}
                    layout="vertical"
                    name="formProfile"
                    onFinish={onFinish}
                    initialValues={{
                      username: dataUser?.user.username,
                      name: dataUser?.user.name,
                      // firstname: dataUser?.user.firstname,
                      // lastname: dataUser?.user.lastname,
                      phone: dataUser?.user.phone,
                      email: dataUser?.user.email,
                      status: dataUser?.user.status,
                      city: dataUser?.user.city,
                      address: dataUser?.user.address,
                      zip_code: dataUser?.user.zip_code,
                      company: dataUser?.status?.company,
                      position: dataUser?.status?.position,
                      university: dataUser?.status?.university,
                      class_year: dataUser?.status?.class_year,
                      major: dataUser?.status?.major,
                    }}>
                    <Row gutter={[48, 16]}>
                      <Col sm={{ span: 24 }} xl={{ span: 8 }}>
                        <Form.Item
                          label="Username"
                          name="username"
                          rules={[
                            {
                              required: true,
                              message: 'Username harus diisi',
                            },
                          ]}>
                          <Input placeholder="Username" />
                        </Form.Item>
                      </Col>
                      <Col sm={{ span: 24 }} xl={{ span: 8 }}>
                        <Form.Item
                          label="Nama"
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: 'Nama harus diisi',
                            },
                          ]}>
                          <Input placeholder="Nama" />
                        </Form.Item>
                      </Col>
                      <Col sm={{ span: 24 }} xl={{ span: 8 }}>
                        <Form.Item
                          label="Status"
                          name="status"
                          rules={[
                            {
                              required: true,
                              message: 'Status harus diisi',
                            },
                          ]}>
                          <Select placeholder="Status" allowClear>
                            <Option value="mahasiswa">Mahasiswa</Option>
                            <Option value="bekerja">Bekerja</Option>
                            <Option value="freshgraduate">
                              Fresh graduate (max 2 tahun)
                            </Option>
                            <Option value="umum">Umum</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    {/* <Row gutter={[48, 16]}>
                      <Col sm={{ span: 24 }} xl={{ span: 8 }}>
                        <Form.Item
                          label="Nama Depan"
                          name="firstname"
                          rules={[
                            {
                              required: true,
                              message: 'Nama Depan harus diisi',
                            },
                          ]}>
                          <Input placeholder="Nama Depan" />
                        </Form.Item>
                      </Col>
                      <Col sm={{ span: 24 }} xl={{ span: 8 }}>
                        <Form.Item
                          label="Nama Belakang"
                          name="lastname"
                          rules={[
                            {
                              required: true,
                              message: 'Nama Belakang harus diisi',
                            },
                          ]}>
                          <Input placeholder="Nama Belakang" />
                        </Form.Item>
                      </Col>
                      <Col sm={{ span: 24 }} xl={{ span: 8 }}>
                        <Form.Item
                          label='Gelar'
                          name='gelar'
                          rules={
                            [
                              // { required: true, message: 'Gelar harus diisi' },
                            ]
                          }>
                          <Input placeholder='Gelar' />
                        </Form.Item>
                      </Col>
                    </Row> */}
                    <Row gutter={[48, 16]}>
                      <Col sm={{ span: 24 }} xl={{ span: 8 }}>
                        <Form.Item
                          label="No Whatsapp/Telfon"
                          name="phone"
                          rules={[
                            {
                              required: true,
                              message: 'No Whatsapp/Telfon harus diisi',
                            },
                          ]}>
                          <Input
                            style={{ width: '100%' }}
                            controls={false}
                            placeholder="0812345678"
                          />
                        </Form.Item>
                      </Col>
                      <Col sm={{ span: 24 }} xl={{ span: 8 }}>
                        <Form.Item
                          label="Alamat Email yang aktif"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: 'Alamat email tidak valid!',
                              type: 'email',
                            },
                          ]}>
                          <Input placeholder="Email" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[48, 16]}>
                      <Col sm={{ span: 24 }} xl={{ span: 8 }}>
                        <Form.Item
                          label="Alamat Lengkap"
                          name="address"
                          rules={[
                            {
                              required: true,
                              message: 'Alamat Lengkap harus diisi',
                            },
                          ]}>
                          <Input placeholder="Alamat Lengkap" />
                        </Form.Item>
                      </Col>
                      <Col sm={{ span: 24 }} xl={{ span: 8 }}>
                        <Form.Item
                          label="Asal Kota"
                          name="city"
                          rules={[
                            {
                              required: true,
                              message: 'Asal Kota Lengkap harus diisi',
                            },
                          ]}>
                          <Input placeholder="Asal Kota" />
                        </Form.Item>
                      </Col>
                      <Col sm={{ span: 24 }} xl={{ span: 8 }}>
                        <Form.Item
                          label="Kode Pos"
                          name="zip_code"
                          rules={[
                            {
                              required: true,
                              message: 'Kode Pos harus diisi',
                            },
                          ]}>
                          <Input placeholder="Kode Pos" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.status !== currentValues.status
                      }>
                      {({ getFieldValue }) =>
                        getFieldValue('status') === 'mahasiswa' ||
                        getFieldValue('status') === 'freshgraduate' ? (
                          <>
                            <h2>Status</h2>
                            <Row gutter={[48, 16]}>
                              <Col sm={{ span: 24 }} xl={{ span: 8 }}>
                                <Form.Item
                                  name="university"
                                  label="Asal Perguruan Tinggi"
                                  rules={[
                                    {
                                      message:
                                        'Asal Perguruan Tinggi harus diisi',
                                      required: true,
                                    },
                                  ]}>
                                  <Input placeholder="Asal Perguruan Tinggi" />
                                </Form.Item>
                              </Col>
                              <Col sm={{ span: 24 }} xl={{ span: 8 }}>
                                <Form.Item
                                  name="major"
                                  label="Jurusan"
                                  rules={[
                                    {
                                      message: 'Jurusan harus diisi',
                                      required: true,
                                    },
                                  ]}>
                                  <Input placeholder="Jurusan" />
                                </Form.Item>
                              </Col>
                              <Col sm={{ span: 24 }} xl={{ span: 8 }}>
                                <Form.Item
                                  name="class_year"
                                  label="Tahun Angkatan"
                                  rules={[
                                    {
                                      message: 'Tahun Angkatan harus diisi',
                                      required: true,
                                    },
                                  ]}>
                                  <Input placeholder="2022" />
                                </Form.Item>
                              </Col>
                            </Row>
                          </>
                        ) : getFieldValue('status') === 'bekerja' ? (
                          <>
                            <h2>Status</h2>
                            <Row gutter={[48, 16]}>
                              <Col sm={{ span: 24 }} xl={{ span: 8 }}>
                                <Form.Item
                                  name="company"
                                  label="Asal Instansi/Perusahaan"
                                  rules={[
                                    {
                                      message:
                                        'Asal Instansi/Perusahaan harus diisi',
                                      required: true,
                                    },
                                  ]}>
                                  <Input placeholder="Nama Instansi/Perusahaan" />
                                </Form.Item>
                              </Col>
                              <Col sm={{ span: 24 }} xl={{ span: 8 }}>
                                <Form.Item
                                  name="position"
                                  label="Jabatan"
                                  rules={[
                                    {
                                      message: 'Jabatan harus diisi',
                                      required: true,
                                    },
                                  ]}>
                                  <Input placeholder="Jabatan di perusahaan" />
                                </Form.Item>
                              </Col>
                            </Row>
                          </>
                        ) : null
                      }
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={submitLoading}>
                        Simpan Perubahan
                      </Button>
                    </Form.Item>
                  </Form>
                ) : null}
              </TabPane>
              <TabPane tab="Ganti Password" key="2">
                {tabKey === '2' ? (
                  <Form
                    form={form}
                    layout={'vertical'}
                    onFinish={onFinish}
                    wrapperCol={{ span: 10 }}>
                    <h2>Password Lama</h2>
                    <Form.Item
                      name="old_password"
                      rules={[
                        {
                          // required: true,
                          min: 8,
                          message: 'Minimal 8 Karakter',
                        },
                      ]}>
                      <Input.Password
                        className="inputForTablet"
                        placeholder="Password Lama"
                        size="large"
                      />
                    </Form.Item>
                    <h2>Password Baru</h2>
                    <Form.Item
                      name="password"
                      hasFeedback
                      rules={[
                        {
                          // required: true,
                          min: 8,
                          message: 'Minimal 8 Karakter',
                        },
                      ]}>
                      <Input.Password
                        className="inputForTablet"
                        placeholder="Password Baru"
                        size="large"
                      />
                    </Form.Item>
                    <Form.Item
                      name="password_confirmation"
                      dependencies={['password']}
                      hasFeedback
                      rules={[
                        {
                          // required: true,
                          min: 8,
                          message: 'Minimal 8 Karakter',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve()
                            }

                            return Promise.reject(
                              new Error(
                                'Password yang anda masukkan tidak sesuai'
                              )
                            )
                          },
                        }),
                      ]}>
                      <Input.Password
                        className="inputForTablet"
                        placeholder="Konfirmasi Password Baru"
                        size="large"
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={submitLoading}>
                        Ubah Password
                      </Button>
                    </Form.Item>
                  </Form>
                ) : null}
              </TabPane>
            </Tabs>
          </div>
        </FetchLoading>
      </div>
    </ProfileLayout>
  )
}

export default Profile
