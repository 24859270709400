import { Card, Col, Row } from 'antd'
import styles from './ListPelatihan.module.css'
import { useLocation } from 'react-router-dom'
import { BASE_URL_IMAGE_PROGRAM } from '../../utils'

const ListPelatihan = ({ transaction }) => {
  const location = useLocation()

  return (
    <Col sm={{ span: 24 }} xl={{ span: 8 }}>
      <Card className={styles.card} id="listPelatihan">
        <h3 className={styles.title}>List pelatihan yang dibeli</h3>
        {transaction?.detail_transaction.map((item, index) => (
          <div key={index}>
            <div className={styles.pelatihan}>
              <img
                className={styles.cover}
                src={`${BASE_URL_IMAGE_PROGRAM}/${item.product.cover}`}
                alt="poster"
              />
              <div>
                <p className={styles.category}>{item.product.category.name}</p>
                <p className={styles.name}>{item.product.name}</p>
              </div>
            </div>
            <div className={styles.hargaPelatian}>
              <p>RP {item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</p>
            </div>
          </div>
        ))}
      </Card>
      {location.pathname === '/checkout/detail-checkout' ? null : (
        <Card className={styles.rincianPembayaran} id="rincianPembayaran">
          <h3 className={styles.title}>Rincian Pembayaran</h3>
          <Row style={{ marginBottom: 10 }}>
            <Col span={16}>
              <p>Subtotal Produk</p>
            </Col>
            <Col span={8}>
              <p>
                RP{' '}
                {transaction.subtotal
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
              </p>
            </Col>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <Col span={16}>
              <p>Total Diskon</p>
            </Col>
            <Col span={8}>
              <p>
                RP {transaction.discount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                {` (-)`}
              </p>
            </Col>
          </Row>
          {/* <Row style={{ marginBottom: 10 }}>
            <Col span={16}>
              <p>Biaya Penanganan {`(Xendit)`}</p>
            </Col>
            <Col span={8}>
              <p>
                RP{' '}
                {transaction.xendit_payment
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
              </p>
            </Col>
          </Row> */}
          <hr />
          <Row className={styles.totalHarga} style={{ marginTop: 10 }}>
            <Col span={16}>
              <p>Total Pembayaran</p>
            </Col>
            <Col span={8}>
              <p>
                RP {transaction.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
              </p>
            </Col>
          </Row>
        </Card>
      )}
    </Col>
  )
}

export default ListPelatihan
