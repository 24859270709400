import React from 'react'
import { Link as LinkScroll } from 'react-scroll'
import landingPageMenuItem from './menu-item'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import styles from './ListMenu.module.css'
import ButtonActionGroup from '../ButtonActionGroup'

const ListMenu = ({ offset, onSetActive = null }) => {
  const mainUrl = window.location.origin
  const currentPage = useLocation()
  return (
    <div className={styles.navbarMenus}>
      {currentPage.pathname === '/' ? (
        landingPageMenuItem.map((menu, idx) => (
          <LinkScroll
            to={`${menu.id}`}
            key={idx}
            activeClass="active"
            spy={true}
            smooth={true}
            offset={offset}
            duration={500}
            onSetActive={onSetActive === null ? null : onSetActive}>
            {menu.title}
          </LinkScroll>
        ))
      ) : currentPage.pathname === '/form-pendaftaran' ? (
        <Link to={-1}>Kembali</Link>
      ) : (
        <Link to="/">Beranda</Link>
      )}
      <a href={`${mainUrl}/tentangmindo`} rel="noreferrer">
        Tentang Kami
      </a>
      <a href={`${mainUrl}/bootcamp`} rel="noreferrer">
        Bootcamp
      </a>
      {/* <a
        href="https://prakerja.mindoeducation.co.id/"
        target="_blank"
        rel="noreferrer">
        Kartu Prakerja
      </a> */}
      {currentPage.pathname === '/masuk' ||
      currentPage.pathname === '/daftar' ||
      currentPage.pathname.search('verified') === 1 ? null : (
        <ButtonActionGroup />
      )}
    </div>
  )
}

export default ListMenu
