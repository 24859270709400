import React, { useState } from 'react'
import styles from './Login.module.css'

// ROUTER
import { Link, useNavigate } from 'react-router-dom'

// REDUX
import { useDispatch } from 'react-redux'
import { setCurrentUser } from '../../redux/user/user.action'

// COMPONENTS
import { ReactComponent as LogoMindoWhite } from '../../assets/images/logo/logo-mindo--white.svg'
import { Form, Input, Button, message } from 'antd'

import './Login.scss'
import axios from 'axios'
import Layout from '../../components/Layout'
import Modal from 'antd/lib/modal/Modal'
import { ExclamationCircleTwoTone } from '@ant-design/icons'

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)

  const onFinish = (values) => {
    setLoading(true)
    const { username, password } = values
    const data = JSON.stringify({
      username,
      password,
    })
    axios({
      method: 'post',
      url: '/login',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then(({ data }) => {
        if (data.message === 'akun anda diblokir') {
          message.error('Akun anda diblokir')
        } else if (data.user.email_verified_at === null) {
          setEmail(data.user.email)
          setModalVisible(true)
          axios({
            method: 'post',
            url: '/email/verification-notification',
            headers: {
              Authorization: `Bearer ${data.token}`,
            },
          })
        } else {
          dispatch(setCurrentUser(data))
          form.resetFields()
          navigate('/')
        }
        setLoading(false)
      })
      .catch((error) => {
        if (error.response.status === 500) {
          message.error('We are Under Maintenance')
        }
        if (error.response === undefined) {
          message.error('Akun anda diblokir')
        } else {
          message.error('Username atau Password salah')
        }
        setLoading(false)
      })
  }

  return (
    <Layout idPage="loginPage">
      <div id="login-page">
        <div className={styles.container}>
          <div className={styles.card}>
            <div className={styles.header}>
              <LogoMindoWhite />
            </div>
            <div className={styles.content}>
              <p>
                Masuk sebagai <span>Peserta</span>
              </p>
              <Form form={form} name="loginForm" onFinish={onFinish}>
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: 'username belum di isi!',
                    },
                  ]}>
                  <Input placeholder="username" size="large" />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'password belum di isi!!',
                    },
                  ]}>
                  <Input.Password placeholder="password" size="large" />
                </Form.Item>
                <Link className={styles.forgotPassword} to="/forgot-password">
                  Lupa Password
                </Link>
                <Form.Item>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    block
                    className={styles.btnSubmit}>
                    Masuk
                  </Button>
                </Form.Item>

                <p>
                  Belum punya akun ? <Link to="/daftar">Daftar sekarang</Link>
                </p>
              </Form>
            </div>
          </div>
          <Modal
            centered
            visible={modalVisible}
            footer={null}
            onCancel={() => window.location.reload()}>
            <div className={styles.modalContainer}>
              <ExclamationCircleTwoTone
                twoToneColor="#ffa940"
                style={{ fontSize: 50 }}
              />
              <p className={styles.modalTitle}>
                Kamu belum memverifikasi email, Silahkan cek email{' '}
                <b>{email}</b> kamu ya untuk link verifikasinya
              </p>
            </div>
          </Modal>
        </div>
      </div>
    </Layout>
  )
}

export default Login
