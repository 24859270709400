// import depedencies
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // get local storage

// import reducer
import programReducer from './program/program.reducer'
import userReducer from './user/user.reducer'
// create persist config
// its persist with local storage so when refresh the data won't disappeared
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
}

const rootReducer = combineReducers({
  program: programReducer,
  user: userReducer,
})

export default persistReducer(persistConfig, rootReducer)
