import React from 'react'
import GambarBuku from './images/gambar-buku.png'
import { ReactComponent as IcArrowRight } from '../../assets/icon/ic-arrow-right.svg'
import styles from './BelajarMandiri.module.css'
import { useMediaQuery } from 'react-responsive'

const BelajarMandiri = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
  return (
    <article
      id="belajarMandiri"
      className={
        isTabletOrMobile ? styles.belajarMandiriContainer : 'container'
      }>
      <div
        className={`${styles.titleGroup} ${
          isTabletOrMobile ? 'container' : ''
        }`}
        data-aos="fade-up">
        <h2>Tambah ngerti dengan punya bukunya</h2>
      </div>
      <div className={styles.content} data-aos="fade-up">
        <div className={styles.contentInner}>
          <div className={styles.imageContainer}>
            <img src={GambarBuku} alt="buku mindo" />
          </div>
          <div className={styles.btnContainer}>
            <a
              href="https://www.tokopedia.com/mindoeducation"
              target="_blank"
              className="btn btn-tertiary btn-w-icon"
              rel="noreferrer">
              Pesan Bukunya Sekarang
              <IcArrowRight />
            </a>
          </div>
        </div>
      </div>
    </article>
  )
}

export default BelajarMandiri
