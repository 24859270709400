import { Card, Col, message, Modal } from 'antd'
import iconDelete from './assets/icon_delete.svg'
import iconWarning from './assets/icon_warning.svg'

import styles from './ProgramKeranjang.module.css'
import { useState } from 'react'
import { BASE_URL_IMAGE_PROGRAM } from '../../utils'
import axios from 'axios'

const ProgramKeranjang = ({ product, currentUser }) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [cartId, setCartId] = useState(null)
  const [loading, setLoading] = useState(false)

  const deleteProduct = () => {
    setLoading(true)
    axios({
      method: 'delete',
      url: `/cart/${cartId}`,
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    })
      .then(() => {
        window.location.reload()
      })
      .catch((error) => {
        if (error.response.status === 500) {
          message.error('We are Under Maintenance')
        }
        setLoading(false)
        setModalVisible(false)
        message.error({
          content: 'Gagal menghapus produk dari keranjang',
          onClose: {},
          style: {
            marginTop: '20vh',
          },
        })
      })
  }

  return (
    <Col xl={{ span: 14 }}>
      {product.map((program, index) => (
        <div key={index} className={styles.program}>
          <Card className={styles.card} style={{ width: '100%' }}>
            <div className={styles.cardProgram}>
              <div>
                <img
                  className={styles.cover}
                  src={`${BASE_URL_IMAGE_PROGRAM}/${program.cover}`}
                  alt="poster"
                />
              </div>
              <div className={styles.programDescription}>
                <h3>{program.product_name}</h3>
                <div className={styles.programDetail}>
                  <p className={styles.price}>
                    Rp{' '}
                    {program.discount === '0'
                      ? program.price
                      : program.promo_price}
                  </p>
                  <p className={styles.priceBeforDiscount}>
                    {program.discount === '0' ? null : `Rp ${program.price}`}
                  </p>
                  <div>
                    <p className={styles.category}>{program.category_name}</p>
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <img
            className={styles.btnDelete}
            src={iconDelete}
            alt="delete icon"
            onClick={() => {
              setModalVisible(true)
              setCartId(program.cart_id)
            }}
          />
        </div>
      ))}
      <Modal
        centered
        visible={modalVisible}
        okText="Hapus"
        closable={false}
        cancelText="Batalkan"
        onOk={() => deleteProduct()}
        confirmLoading={loading}
        onCancel={() => setModalVisible(false)}>
        <div className={styles.modalContainer}>
          <img
            className={styles.modalIcon}
            src={iconWarning}
            alt="delete warning"
          />
          <div>
            <p className={styles.modalTitle}>Hapus Produk</p>
            <p className={styles.modalText}>
              Apakah anda yakin ingin menghapus produk?
            </p>
          </div>
        </div>
      </Modal>
    </Col>
  )
}

export default ProgramKeranjang
