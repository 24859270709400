import { Button, Card, Form, Input, message } from 'antd'
import { Link, useLocation } from 'react-router-dom'

import styles from './Kupon.module.css'
import { CheckCircleTwoTone } from '@ant-design/icons'
import axios from 'axios'
import { useState } from 'react'

let qs = require('qs')

const Kupon = ({ product, kupon, currentUser }) => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const location = useLocation()

  const addCoupon = (values) => {
    setLoading(true)
    const { code_coupon } = values
    const data = qs.stringify({
      code_coupon,
    })

    axios({
      method: 'post',
      url: '/take-coupon',
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: data,
    })
      .then(({ data }) => {
        if (
          data.message === 'kupon tidak ditemukan' ||
          data.message === 'kupon sudah digunakan'
        ) {
          message.error({
            content: data.message,
            style: {
              marginTop: '20vh',
            },
          })
          setLoading(false)
        } else {
          window.location.reload()
        }
      })
      .catch((error) => {
        if (error.response.status === 500) {
          message.error('We are Under Maintenance')
        }
      })
  }

  return (
    <Card className={styles.cardKupon}>
      <h3 className={styles.kuponTitle}>Kupon</h3>
      <Form form={form} onFinish={addCoupon}>
        <Form.Item name="code_coupon">
          <Input
            className={styles.kuponInput}
            placeholder="Masukkan di sini (Jika ada)"
            size="large"
          />
        </Form.Item>
        <Form.Item>
          <div className={styles.kuponButtonRight}>
            <Button
              htmlType="submit"
              className={styles.kuponButton}
              loading={loading}>
              Gunakan
            </Button>
          </div>
        </Form.Item>
      </Form>

      <div className={styles.listKupon}>
        {kupon.map((kupon, index) => (
          <div key={index} className={styles.kuponItem}>
            <CheckCircleTwoTone
              twoToneColor="#52c41a"
              className={styles.checkOutlined}
            />
            <div className={styles.kuponInfo}>
              <div>
                <p className={styles.kuponCode}>{kupon.code}</p>
                <p className={styles.kuponDesc}>{kupon.product.name}</p>
              </div>
              <p className={styles.kuponDesc}>{(kupon.value / 100) * 100}%</p>
            </div>
          </div>
        ))}
      </div>
      {location.pathname === '/keranjang' ? (
        <div className={styles.bayarButtonCenter}>
          <Link
            to={product?.length === 0 ? '/keranjang' : '/checkout'}
            className={styles.bayarButton}>
            Bayar Sekarang
          </Link>
        </div>
      ) : null}
    </Card>
  )
}

export default Kupon
