import React from 'react'
import './SilabusMateri.scss'
import AccorditionPrakerja from './components/AccorditionPrakerja'
import { Collapse } from 'antd'
const { Panel } = Collapse

const SilabusMateri = ({ program }) => {
  const { link_campsite } = program
  return (
    <div id="program-detail-page__silabus-materi">
      {link_campsite !== '/form-prakerja' ? (
        <Collapse accordion expandIconPosition="right" ghost>
          <Panel header="Silabus Materi" key="1">
            <div
              dangerouslySetInnerHTML={{
                __html: `${program.syllabus}`,
              }}></div>
          </Panel>
          <Panel header="Info Kelas" key="2">
            <div
              dangerouslySetInnerHTML={{
                __html: `${program.description}`,
              }}></div>
          </Panel>
          <Panel header="Syarat dan Ketentuan" key="3">
            <a
              href={program.term_condition}
              className="termCondition"
              target="_blank"
              rel="noreferrer">
              Berikut Syarat dan Ketentuan Pelatihan
            </a>
          </Panel>
        </Collapse>
      ) : (
        <AccorditionPrakerja />
      )}
    </div>
  )
}

export default SilabusMateri
