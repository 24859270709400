import React from 'react'
import { Link } from 'react-router-dom'
import { CardProgram, DynamicContent } from '../../../../components'
import { ReactComponent as IcArrowRight } from '../../../../assets/icon/ic-arrow-right.svg'

import './ProgramTerdekat.scss'
import styles from './ProgramTerdekat.module.css'

const ProgramTerdekat = ({ dataUser, programTerdekatData }) => {
  return (
    <article id="home-page__programTerdekat">
      <div className="container">
        <div data-aos="fade-up">
          <div className={styles.text}>
            <h2>Program Terdekat</h2>
            <p>
              Program terdekat dari Mindo Education untuk menunjang skill kamu
              ketika bekerja nanti di industri
            </p>
          </div>
        </div>
        <DynamicContent data={programTerdekatData}>
          <>
            <div className={styles.programItems} data-aos="fade-up">
              {programTerdekatData?.map((program, index) => (
                <CardProgram
                  key={index}
                  program={program}
                  dataUser={dataUser}
                />
              ))}
            </div>
            <div className={styles.btnContainer}>
              <Link to="/program">
                <button type="button" className={`btn btn-primary btn-w-icon`}>
                  Lihat Semua Pelatihan
                  <IcArrowRight />
                </button>
              </Link>
            </div>
          </>
        </DynamicContent>
      </div>
    </article>
  )
}

export default ProgramTerdekat
