import React, { useState } from 'react'
import styles from './Daftar.module.css'

// ROUTER
import { Link } from 'react-router-dom'

// COMPONENTS
import { ReactComponent as LogoMindoWhite } from '../../assets/images/logo/logo-mindo--white.svg'
import { Form, Input, Button, message, Checkbox } from 'antd'

import './Daftar.scss'
import axios from 'axios'
import Layout from '../../components/Layout'
import Verify from './components/Verify'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentUser } from '../../redux/user/user.action'
import { selectCurrentUser } from '../../redux/user/user.selectors'

const Daftar = () => {
  const dispatch = useDispatch()
  const currentUser = useSelector(selectCurrentUser)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  // const [dataUser, setDataUser] = useState(null)
  // const [tokenPeserta, setTokenPeserta] = useState(null)

  const onFinish = (values) => {
    setLoading(true)
    const { name, email, username, phone, password, password_confirmation } =
      values
    const data = JSON.stringify({
      name,
      email,
      username,
      phone,
      password,
      password_confirmation,
    })
    axios({
      method: 'post',
      url: '/register',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then(({ data }) => {
        dispatch(setCurrentUser(data))
        form.resetFields()
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.data.message.email[0]) {
          message.error(`Email sudah digunakan`)
        }
        if (error.response.data.message.username[0]) {
          message.error(`Username sudah digunakan`)
        }
        if (error.response.status === 500) {
          message.error('We are Under Maintenance')
        }
      })
  }

  return (
    <>
      {!currentUser ? (
        <Layout idPage="daftarPage">
          <div id="daftar-page">
            <div className={styles.container}>
              <div className={styles.card}>
                <div className={styles.header}>
                  <LogoMindoWhite />
                </div>
                <div className={styles.content}>
                  <p>
                    Selamat datang, <span>#SobatMindo!</span>
                  </p>
                  <Form form={form} name="daftarForm" onFinish={onFinish}>
                    <Form.Item
                      name="name"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'nama belum di isi!',
                        },
                      ]}>
                      <Input
                        placeholder="Nama Lengkap (untuk sertifikat)"
                        size="large"
                      />
                    </Form.Item>
                    <Form.Item
                      name="username"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'username belum di isi!',
                        },
                        () => ({
                          validator(_, value) {
                            if (!value || value.indexOf(' ') <= 0) {
                              return Promise.resolve()
                            }
                            return Promise.reject(
                              new Error(
                                'Tidak boleh mengandung spasi dan simbol, Contoh: mika1234'
                              )
                            )
                          },
                        }),
                      ]}>
                      <Input
                        placeholder="Username (akan digunakan untuk Login nanti)"
                        size="large"
                      />
                    </Form.Item>
                    <Form.Item
                      name="phone"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'phone belum di isi!',
                        },
                      ]}>
                      <Input
                        placeholder="No Whatsapp / No Telpon"
                        size="large"
                      />
                    </Form.Item>
                    <Form.Item
                      name="email"
                      hasFeedback
                      rules={[
                        {
                          type: 'email',
                          message: 'Email Tidak Valid',
                        },
                        {
                          required: true,
                          message: '',
                        },
                      ]}>
                      <Input
                        placeholder="Alamat e-mail (aktif dan disarankan gmail)"
                        size="large"
                      />
                    </Form.Item>

                    <Form.Item
                      name="password"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          min: 8,
                          message: 'Minimal 8 Karakter',
                        },
                      ]}>
                      <Input.Password placeholder="Password" size="large" />
                    </Form.Item>
                    <Form.Item
                      name="password_confirmation"
                      dependencies={['password']}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve()
                            }

                            return Promise.reject(
                              new Error(
                                'Password yang anda masukkan tidak sesuai'
                              )
                            )
                          },
                        }),
                      ]}>
                      <Input.Password
                        placeholder="Konfirmasi Password"
                        size="large"
                      />
                    </Form.Item>

                    <Form.Item
                      name="agreement"
                      valuePropName="checked"
                      rules={[
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject(
                                  new Error('Harus menerima kesepakatan')
                                ),
                        },
                      ]}>
                      <Checkbox className={styles.checkbox}>
                        Saya setuju dengan <span>Syarat & Ketentuan</span> dan{' '}
                        <span>Kebijakan Privasi</span>
                      </Checkbox>
                    </Form.Item>

                    <Form.Item>
                      <Button
                        loading={loading}
                        type="primary"
                        htmlType="submit"
                        block
                        className={styles.btnSubmit}>
                        Daftar
                      </Button>
                    </Form.Item>

                    <p>
                      Saya sudah punya akun,{' '}
                      <Link to="/masuk">Login sekarang</Link>
                    </p>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      ) : (
        <Verify />
      )}
    </>
  )
}

export default Daftar
