import { Card, Col, Collapse, Tooltip } from 'antd'
import { useState } from 'react'

import styles from './PembayaranOtomatis.module.css'
import BCA from '../../../../assets/images/method_payment/BCA.svg'
import BJB from '../../../../assets/images/method_payment/BJB.svg'
import BNI from '../../../../assets/images/method_payment/BNI.svg'
import BRI from '../../../../assets/images/method_payment/BRI.svg'
import BSI from '../../../../assets/images/method_payment/BSI.svg'
import MANDIRI from '../../../../assets/images/method_payment/MANDIRI.svg'
import PERMATA from '../../../../assets/images/method_payment/PERMATA.svg'
import SAHABAT_SAMPOERNA from '../../../../assets/images/method_payment/SAHABAT_SAMPOERNA.svg'
import ALFAMART from '../../../../assets/images/method_payment/ALFAMART.svg'
import INDOMARET from '../../../../assets/images/method_payment/INDOMARET.svg'
import iconCopy from '../../../../assets/icon/ic-copy.svg'

import './PembayaranOtomatis.scss'
import { Link } from 'react-router-dom'

const PembayaranOtomatis = ({ payment }) => {
  const [textCopy, setTextCopy] = useState('Copy')

  const { Panel } = Collapse

  const digitNumber1 = payment.account_number.substring(0, 4)
  const digitNumber2 = payment.account_number.substring(4, 8)
  const digitNumber3 = payment.account_number.substring(8, 12)
  const digitNumber4 = payment.account_number.substring(12)
  const paymentNumber = `${digitNumber1}-${digitNumber2}-${digitNumber3}-${digitNumber4}`

  const onCopy = () => {
    setTextCopy('Copied!!')
    setTimeout(() => {
      setTextCopy('Copy')
    }, 2000)
    navigator.clipboard.writeText(payment.account_number)
  }

  return (
    <Col sm={{ span: 24 }} xl={{ span: 12 }}>
      <Card className={styles.card} id="pembayaran">
        <div className={styles.price}>
          <p>Total Pembayaran :</p>
          <p>Rp {payment.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</p>
        </div>
        <div className={styles.bankName}>
          <img
            src={
              payment.method_payment === 'BCA'
                ? BCA
                : payment.method_payment === 'BJB'
                ? BJB
                : payment.method_payment === 'BNI'
                ? BNI
                : payment.method_payment === 'BRI'
                ? BRI
                : payment.method_payment === 'BSI'
                ? BSI
                : payment.method_payment === 'MANDIRI'
                ? MANDIRI
                : payment.method_payment === 'PERMATA'
                ? PERMATA
                : payment.method_payment === 'SAHABAT_SAMPOERNA'
                ? SAHABAT_SAMPOERNA
                : payment.method_payment === 'ALFAMART'
                ? ALFAMART
                : payment.method_payment === 'INDOMARET'
                ? INDOMARET
                : null
            }
            alt={payment.method_payment}
          />
          <p>{payment.method_payment} (Dicek Otomatis)</p>
        </div>
        <div className={styles.noRek}>
          <p>
            {payment.payment_channel === 'RETAIL_OUTLETS'
              ? 'Nomor Pembayaran'
              : 'No. Rekening:'}
          </p>
          <p>
            {payment.payment_channel === 'VIRTUAL_ACCOUNT'
              ? paymentNumber
              : payment.account_number}{' '}
            <span>
              <Tooltip title={textCopy}>
                <img
                  className={styles.iconCopy}
                  src={iconCopy}
                  alt="copy"
                  onClick={onCopy}
                />
              </Tooltip>
            </span>
          </p>
          <p>
            Dicek maksimal 10 menit setelah pembayaran. Bayar sebelum{' '}
            <b>
              {payment.expiration_date
                .slice(0, 10)
                .split('-')
                .reverse()
                .join('-')}
            </b>
          </p>
        </div>
      </Card>
      {payment.payment_channel === 'RETAIL_OUTLETS' ? null : (
        <Card className={styles.cardInformation} id="informasi">
          <Collapse defaultActiveKey={['3']} expandIconPosition="right" ghost>
            {payment.method_payment === 'BNI' ? (
              <>
                <Panel header="Petunjuk Transfer mBanking" key="1">
                  <ol className={styles.textInfo}>
                    <li>Pilih Transfer {'>'} Virtual Account Billing.</li>
                    <li>
                      Pilih Rekening Debet {'>'} Masukkan nomor Virtual Account{' '}
                      {payment.account_number} pada menu Input Baru.
                    </li>

                    <li>
                      Tagihan yang harus dibayar akan muncul pada layar
                      konfirmasi
                    </li>
                    <li>
                      Periksa informasi yang tertera di layar pastikan sudah
                      benar. Jika benar, masukkan password transaksi dan pilih
                      Lanjut.
                    </li>
                  </ol>
                </Panel>
                <Panel header="Petunjuk Transfer iBanking" key="2">
                  <ol className={styles.textInfo}>
                    <li>
                      Buka situs iBanking BNI,{' '}
                      <a
                        href="https://ibank.bni.co.id"
                        target="_blank"
                        rel="noreferrer">
                        ibank.bni.co.id
                      </a>
                      , masukkan User ID dan Password
                    </li>
                    <li>Pilih Transfer {'>'} Virtual Account Billing.</li>
                    <li>
                      Masukkan Nomor Virtual Account {payment.account_number}
                    </li>
                    <li>Pilih Rekening Debet dan piliih Lanjut.</li>
                    <li>
                      Tagihan yang harus dibayar akan muncul pada layar
                      konfirmasi
                    </li>
                    <li>
                      Periksa informasi yang tertera di layar pastikan sudah
                      benar. Jika benar, pilih Ya.
                    </li>
                    <li>Masukkan Kode Otentikasi Token lalu klik Proses.</li>
                  </ol>
                </Panel>
                <Panel header="Petunjuk Transfer ATM" key="3">
                  <ol className={styles.textInfo}>
                    <li>Pilih Menu Lain {'>'} Transfer.</li>
                    <li>
                      Pilih Jenis rekening asal dan pilih Virtual Account
                      Billing.
                    </li>
                    <li>
                      Masukkan Nomor Virtual Account {payment.account_number}{' '}
                      dan pilih Benar
                    </li>
                    <li>
                      Tagihan yang harus dibayar akan muncul pada layar
                      konfirmasi.
                    </li>
                    <li>
                      Periksa informasi yang tertera di layar pastikan sudah
                      benar. Jika benar, pilih Ya.
                    </li>
                  </ol>
                </Panel>
              </>
            ) : payment.method_payment === 'MANDIRI' ? (
              <>
                <Panel header="Petunjuk Transfer mBanking" key="1">
                  <ol className={styles.textInfo}>
                    <li>Pilih "Bayar"</li>
                    <li>Pilih "Multipayment"</li>
                    <li>Cari “Xendit 88908” (untuk Closed VA)</li>
                    <li>
                      Masukkan Nomor Virtual Account {payment.account_number}
                    </li>
                    <li>Tekan Lanjut</li>
                    <li>
                      Tinjau dan konfirmasi detail transaksi, lalu tekan
                      Konfirmasi
                    </li>
                    <li>Selesaikan transaksi dengan memasukkan MPIN</li>
                  </ol>
                </Panel>
                <Panel header="Petunjuk Transfer iBanking" key="2">
                  <ol className={styles.textInfo}>
                    <li>
                      Buka situs iBanking Mandiri,{' '}
                      <a
                        href="https://ibank.bankmandiri.co.id"
                        target="_blank"
                        rel="noreferrer">
                        ibank.bankmandiri.co.id
                      </a>
                      , masukkan User ID dan Password
                    </li>
                    <li>
                      Pilih 88908 XENDIT (untuk closed VA) dan 88608 XENDIT
                      (untuk open VA) sebagai penyedia jasa
                    </li>
                    <li>
                      Masukkan Nomor Virtual Account {payment.account_number}
                    </li>
                    <li>Lalu pilih Lanjut</li>
                    <li>Apabila semua detail benar tekan "KONFIRMASI"</li>
                    <li>Masukkan PIN / Challenge Code Token</li>
                  </ol>
                </Panel>
                <Panel header="Petunjuk Transfer ATM" key="3">
                  <ol className={styles.textInfo}>
                    <li>Pilih Bayar/Beli.</li>
                    <li>
                      Pilih Lainnya {'>'} Lainnya {'>'} Multi Payment.
                    </li>
                    <li>
                      Masukkan kode perusahaan 88908 XENDIT and pilih Benar.
                    </li>
                    <li>
                      Untuk open amount VA, masukkan nominal yang ingin di
                      transfer, lalu tekan "BENAR"
                    </li>
                    <li>
                      Informasi pelanggan akan ditampilkan, pilih nomor 1 sesuai
                      dengan nominal pembayaran kemudian tekan "YA"
                    </li>
                    <li>
                      Konfirmasi pembayaran akan muncul, tekan "YES", untuk
                      melanjutkan
                    </li>
                  </ol>
                </Panel>
              </>
            ) : payment.method_payment === 'BRI' ? (
              <>
                <Panel header="Petunjuk Transfer mBanking" key="1">
                  <ol className={styles.textInfo}>
                    <li>Pilih "Pembayaran" dan pilih "Briva"</li>
                    <li>
                      Masukkan Nomor Virtual Account {payment.account_number}{' '}
                      dan jumlah yang ingin dibayarkan
                    </li>
                    <li>Masukkan PIN Mobile Banking BRI</li>
                  </ol>
                </Panel>
                <Panel header="Petunjuk Transfer iBanking" key="2">
                  <ol className={styles.textInfo}>
                    <li>
                      Buka situs iBanking Mandiri,{' '}
                      <a
                        href="https://ib.bri.co.id/ib-bri/"
                        target="_blank"
                        rel="noreferrer">
                        ib.bri.co.id/ib-bri/
                      </a>
                      , masukkan User ID dan Password
                    </li>
                    <li>Pilih "Pembayaran" dan pilih "Briva"</li>
                    <li>
                      Masukkan Nomor Virtual Account {payment.account_number}{' '}
                      dan jumlah yang ingin dibayarkan
                    </li>
                    <li>
                      Masukkan password kemudian masukkan mToken internet
                      banking
                    </li>
                  </ol>
                </Panel>
                <Panel header="Petunjuk Transfer ATM" key="3">
                  <ol className={styles.textInfo}>
                    <li>Pilih "Transaksi Lain" dan pilih "Pembayaran"</li>
                    <li>Pilih menu "Lainnya" dan pilih "Briva"</li>
                    <li>
                      Masukkan kode perusahaan 88908 XENDIT and pilih Benar.
                    </li>
                    <li>
                      Masukkan Nomor Virtual Account {payment.account_number}{' '}
                      dan jumlah yang ingin dibayarkan
                    </li>
                    <li>Periksa data transaksi dan tekan "YA"</li>
                  </ol>
                </Panel>
              </>
            ) : payment.method_payment === 'PERMATA' ? (
              <>
                <Panel header="Petunjuk Transfer mBanking" key="1">
                  <ol className={styles.textInfo}>
                    <li>Pilih "Pembayaran Tagihan"</li>
                    <li>Pilih "Virtual Account"</li>
                    <li>
                      Masukkan Nomor Virtual Account {payment.account_number}
                    </li>
                    <li>Masukkan otentikasi transaksi/token</li>
                  </ol>
                </Panel>
                <Panel header="Petunjuk Transfer iBanking" key="2">
                  <ol className={styles.textInfo}>
                    <li>
                      Buka situs iBanking Mandiri,{' '}
                      <a
                        href="https://new.permatanet.com"
                        target="_blank"
                        rel="noreferrer">
                        new.permatanet.com
                      </a>
                      , masukkan User ID dan Password
                    </li>
                    <li>Pilih "Pembayaran Tagihan"</li>
                    <li>Pilih "Virtual Account"</li>
                    <li>
                      Masukkan Nomor Virtual Account {payment.account_number}
                    </li>
                    <li>Periksa kembali detail pembayaran</li>
                    <li>Masukkan otentikasi transaksi/token</li>
                  </ol>
                </Panel>
                <Panel header="Petunjuk Transfer ATM" key="3">
                  <ol className={styles.textInfo}>
                    <li>Pilih menu "Transaksi Lainnya"</li>
                    <li>Pilih menu "Pembayaran"</li>
                    <li>Pilih menu "Pembayaran Lainnya"</li>
                    <li>Pilih menu "Virtual Account"</li>
                    <li>
                      Masukkan Nomor Virtual Account {payment.account_number}
                    </li>
                    <li>Lalu pilih rekening debet yang akan digunakan</li>
                    <li>Konfirmasi detail transaksi</li>
                  </ol>
                </Panel>
              </>
            ) : payment.method_payment === 'SAHABAT_SAMPOERNA' ? (
              <>
                <Panel header="Petunjuk Transfer mBanking" key="1">
                  <ol className={styles.textInfo}>
                    <li>Pilih menu TRANSFER DANA → TRANSFER KE ANTAR BANK</li>
                    <li>
                      Masukkan kode BANK SAHABAT SAMPOERNA yaitu 523 → Pilih YA.
                    </li>
                    <li>
                      Masukkan 16 digit nomor rekening virtual account{' '}
                      {payment.account_number}
                    </li>
                    <li>Input token M-Banking</li>
                    <li>Pembayaran melalui M-Banking sukses dilakukan.</li>
                  </ol>
                </Panel>
                <Panel header="Petunjuk Transfer iBanking" key="2">
                  <ol className={styles.textInfo}>
                    <li>Login akun Internet Banking pada handphone.</li>
                    <li>
                      Pilih menu TRANSFER DANA → TRANSFER KE REKENING BANK
                      SAHABAT SAMPOERNA.
                    </li>
                    <li>
                      Masukkan 16 digit nomor rekening virtual account{' '}
                      {payment.account_number}
                    </li>
                    <li>Masukkan nominal pembayaran.</li>
                    <li>Input token i-Banking.</li>
                    <li>Pembayaran melalui i-Banking sukses dilakukan.</li>
                  </ol>
                </Panel>
                <Panel header="Petunjuk Transfer ATM" key="3">
                  <ol className={styles.textInfo}>
                    <li>
                      Pilih menu TRANSAKSI LAINNYA → TRANSFER → TRANSFER KE BANK
                      SAHABAT SAMPOERNA.
                    </li>
                    <li>
                      Masukkan 16 digit nomor rekening virtual account{' '}
                      {payment.account_number}
                    </li>
                    <li>Masukkan nominal pembayaran.</li>
                    <li>
                      Cek kembali transaksi, lalu pilih YA untuk melanjutkan.
                    </li>
                    <li>Pembayaran melalui ATM sukses dilakukan.</li>
                  </ol>
                </Panel>
              </>
            ) : null}
          </Collapse>
        </Card>
      )}
      <div className={styles.bayarButtonCenter}>
        <Link className={styles.bayarButton} to="/dashboard/transaksi">
          Lihat Daftar Transaksi saya
        </Link>
      </div>
    </Col>
  )
}

export default PembayaranOtomatis
