import { message } from 'antd'
import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import Layout from '../../components/Layout'
import { selectCurrentUser } from '../../redux/user/user.selectors'
import Organigram from './assets/Organigram.png'
import Poster from './assets/Poster.png'
import {
  AlumniPelatihan,
  KonsultasiDanSertifikasi,
  MindoSertifikasi,
  ProgramKami,
  Testimoni,
  VisiMisi,
} from './components'
import styles from './TentangMindo.module.css'

import './TentangMindo.scss'

const TentangMindo = () => {
  const [testimoniData, setTestimoniData] = useState(null)
  const currentUser = useSelector(selectCurrentUser)
  useEffect(() => {
    axios
      .get('/show-index')
      .then((res) => {
        const data = res.data
        setTestimoniData(data.testimoni)
      })
      .catch((error) => {
        if (error.response.status === 500) {
          message.error('We are Under Maintenance')
        }
      })
  }, [currentUser])

  return (
    <Layout idPage="tentang-mindo-page">
      <div className={styles.aboutPage}>
        <div className="container">
          <div className={styles.titleGroup}>
            <h2>Tentang Mindo Education</h2>
          </div>
          <div className={styles.imageContainer}>
            <img src={Organigram} alt="struktur organisasi" hidden />
            <img src={Poster} alt="struktur organisasi" />
          </div>
          <div className={styles.description}>
            <p>
              Mindo Education yang secara hukum berada di bawah naungan PT
              Impiana Generasi Bangsa bermula dari Mina Indonesia yang merupakan
              platform pelatihan dengan fokus seputar Teknis dan Bisnis Budidaya
              Perikanan, Sistem Manajemen Keamanan Pangan, Sistem Manajemen
              Mutu, Quality Assurance, dan Quality Control. Platform ini
              didirikan pada tahun 2018 dan seiring berkembangnya produk
              pelatihan, Mina Indonesia melakukan rebranding pada tahun 2021
              menjadi Mindo Education dan telah memberikan banyak pelatihan bagi
              puluhan ribu masyarakat yang tersebar di seluruh Indonesia.
            </p>
          </div>
          <VisiMisi />
          <ProgramKami />
          <AlumniPelatihan />
          <KonsultasiDanSertifikasi />
          <MindoSertifikasi />
          <Testimoni testimoniData={testimoniData} />
        </div>
      </div>
    </Layout>
  )
}

export default TentangMindo
