import React, { useEffect, useState } from 'react'
import styles from './BayarPesanan.module.css'

import './BayarPesanan.scss'
import Layout from '../../components/Layout'
import { message, Row } from 'antd'
import { PembayaranOtomatis } from './components'
import { ListPelatihan } from '../../components'
import { selectCurrentUser } from '../../redux/user/user.selectors'
import { useSelector } from 'react-redux'
import axios from 'axios'
import FetchLoading from '../../components/FetchLoading'
import { useParams } from 'react-router-dom'

const BayarPesanan = () => {
  const [payment, setpayment] = useState(null)
  const currentUser = useSelector(selectCurrentUser)
  const { slug } = useParams()

  useEffect(() => {
    axios({
      method: 'get',
      url: `/check-payment/${atob(slug)}`,
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    })
      .then(({ data }) => {
        console.log(typeof data.amount === 'number' ? data.amount : data.amount.replace(/\B(?=(\d{3})+(?!\d))/g, '.'))
        setpayment(data)
      })
      .catch((error) => {
        if (error.response.status === 500) {
          message.error('We are Under Maintenance')
        }
      })
  }, [currentUser.token, slug])

  return (
    <Layout idPage="bayarPesananPage">
      <div className={styles.content}>
        <FetchLoading data={payment}>
          <div className="container">
            <div className={styles.titleGroup}>
              <h2>Pembayaran</h2>
            </div>
            <Row justify="space-between">
              <ListPelatihan transaction={payment} />
              <PembayaranOtomatis payment={payment} />
            </Row>
          </div>
        </FetchLoading>
      </div>
    </Layout>
  )
}

export default BayarPesanan
