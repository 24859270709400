import MenuItems from './MenuItems'
import styles from './Sidebar.module.css'

import ProfileLogo from '../../../../assets/images/logo/logo-profile.svg'

import './Sidebar.scss'

const Sidebar = ({ name }) => {
  return (
    <>
      <div id="sidebar">
        <div className={styles.logo}>
          <img src={ProfileLogo} alt="profile-logo" />
          <h1>{name}</h1>
        </div>
        <div className={styles.inner}>
          <MenuItems />
        </div>
      </div>
    </>
  )
}

export default Sidebar
