import React from 'react'
import { CardProgram, DynamicContent } from '../../../../components'

import './ProgramBootcamp.scss'
import styles from './ProgramBootcamp.module.css'

const ProgramBootcamp = ({ programBootcampData, dataUser }) => {
  return (
    <article id="home-page__programBootcamp">
      <div className="container">
        <div data-aos="fade-up">
          <div className={styles.text}>
            <h2>Program Bootcamp</h2>
            <p>
              Program Bootcamp dari Mindo Education untuk menunjang skill kamu
              ketika bekerja nanti di industri
            </p>
          </div>
        </div>
        <DynamicContent data={programBootcampData}>
          <>
            <div className={styles.programItems} data-aos="fade-up">
              {programBootcampData?.map((program, index) =>
                program?.category?.name === 'Bootcamp' ? (
                  <CardProgram
                    key={index}
                    program={program}
                    dataUser={dataUser}
                  />
                ) : null
              )}
            </div>
          </>
        </DynamicContent>
      </div>
    </article>
  )
}

export default ProgramBootcamp
