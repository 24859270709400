import React from 'react'
import { ReactComponent as IcBiayaLebihTerjangkau } from './icon/ic-biaya-lebih-terjangkau.svg'
import { ReactComponent as IcBimbinganKarir } from './icon/ic-bimbingan-karir.svg'
import { ReactComponent as IcStudiKasus } from './icon/ic-studi-kasus.svg'
import { ReactComponent as IcGrupDiskusi } from './icon/ic-grup-diskusi.svg'

import './KenapaHarusBootcamp.scss'
import styles from './KenapaHarusBootcamp.module.css'
const KenapaHarusBootcamp = () => {
  return (
    <article id="home-page__KenapaHarusBootcamp" className="container">
      <div className={styles.titleGroup} data-aos="fade-up">
        <div className={styles.titleContainer}>
          <h2>Kenapa Harus Ikut Mindo Intensive Bootcamp?</h2>
          <p>
            Beberapa keuntungan yang bisa kamu dapatkan jika <br /> memilih
            program Mindo Intensive Bootcamp
          </p>
        </div>
      </div>

      <div className={styles.cardContainer} data-aos="fade-up">
        <div className={styles.cardItem}>
          <div className={styles.iconContainer}>
            <IcBiayaLebihTerjangkau />
          </div>
          <h3>Biaya Lebih Terjangkau</h3>
          <p>
            Biaya Mindo Intensive Bootcamp lebih terjangkau cocok untuk pemula
          </p>
        </div>
        <div className={styles.cardItem}>
          <div className={styles.iconContainer}>
            <IcBimbinganKarir />
          </div>
          <h3>Bimbingan Karir</h3>
          <p>
            Terdapat kelas bedah CV dan kelas interview agar kamu bisa lebih
            siap dalam berkarir
          </p>
        </div>
        <div className={styles.cardItem}>
          <div className={styles.iconContainer}>
            <IcStudiKasus />
          </div>
          <h3>Studi Kasus</h3>
          <p>
            Mindo Intensive Bootcamp dilengkapi studi kasus yang lebih mendalam
            dan diberikan langsung oleh praktisinya
          </p>
        </div>
        <div className={styles.cardItem}>
          <div className={styles.iconContainer}>
            <IcGrupDiskusi />
          </div>
          <h3>Grup Berdiskusi</h3>
          <p>
            Mindo Intensive Bootcamp dirancang agar peserta terbiasa dalam
            mengemukakan pendapat dan bekerja sama
          </p>
        </div>
      </div>
    </article>
  )
}

export default KenapaHarusBootcamp
