import React from 'react'
import { Form, Input, Button, InputNumber, Select, message } from 'antd'
const { Option } = Select

message.config({
  duration: 2,
  maxCount: 1,
})
const FormGroup = ({ onFinish }) => {
  const [form] = Form.useForm()
  return (
    <Form
      form={form}
      layout="vertical"
      name="formPendaftaran"
      onFinish={onFinish}
      autoComplete="off">
      <Form.Item
        label="Nama Lengkap"
        name="nama"
        rules={[{ required: true, message: 'Nama Lengkap harus diisi!' }]}>
        <Input placeholder="Mika Michaella" />
      </Form.Item>
      <Form.Item
        label="Alamat Email Aktif (disarankan gmail)"
        name="email"
        rules={[
          {
            required: true,
            message: 'Alamat email tidak valid!',
            type: 'email',
          },
        ]}>
        <Input placeholder="michaella@example.com" />
      </Form.Item>
      <Form.Item
        name="phone"
        label="Nomor Whatsapp"
        rules={[
          {
            required: true,
            message: 'Nomor Whatsaapp harus diisi!',
            type: 'number',
          },
        ]}>
        <InputNumber min={0} controls={false} placeholder="0812345678" />
      </Form.Item>
      <Form.Item
        name="status"
        label="Apakah statusmu saat ini?"
        rules={[
          {
            required: true,
            message: 'Status Harus Dipilih!',
          },
        ]}>
        <Select placeholder="Apakah statusmu saat ini?" allowClear>
          <Option value="mahasiswa">Mahasiswa</Option>
          <Option value="bekerja">Bekerja</Option>
          <Option value="freshgraduate">Fresh graduate (max 2 tahun)</Option>
          <Option value="umum">Umum</Option>
        </Select>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.status !== currentValues.status
        }>
        {({ getFieldValue }) =>
          getFieldValue('status') === 'mahasiswa' ||
          getFieldValue('status') === 'freshgraduate' ? (
            <>
              <Form.Item
                name="asalPerguruan"
                label="Asal Perguruan Tinggi"
                rules={[
                  {
                    message: 'Asal Perguruan Tinggi harus diisi!',
                    required: true,
                  },
                ]}>
                <Input placeholder="Asal Perguruan Tinggi" />
              </Form.Item>
              <Form.Item
                name="jurusan"
                label="Jurusan"
                rules={[
                  {
                    message: 'Jurusan harus diisi!',
                    required: true,
                  },
                ]}>
                <Input placeholder="Jurusan" />
              </Form.Item>
              <Form.Item
                name="tahunAngkatan"
                label="Tahun Angkatan"
                rules={[
                  {
                    message: 'Tahun Angkatan harus diisi!',
                    required: true,
                  },
                ]}>
                <Input placeholder="2022" />
              </Form.Item>
            </>
          ) : getFieldValue('status') === 'bekerja' ? (
            <>
              <Form.Item
                name="asalInstansi"
                label="Asal Instansi/Perusahaan"
                rules={[
                  {
                    message: 'Asal Instansi/Perusahaan harus diisi!',
                    required: true,
                  },
                ]}>
                <Input placeholder="Nama Instansi/Perusahaan" />
              </Form.Item>
              <Form.Item
                name="jabatan"
                label="Jabatan"
                rules={[
                  {
                    message: 'Jabatan harus diisi!',
                    required: true,
                  },
                ]}>
                <Input placeholder="Jabatan di perusahaan" />
              </Form.Item>
            </>
          ) : null
        }
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" block>
          Daftar
        </Button>
      </Form.Item>
    </Form>
  )
}

export default FormGroup
