import React from 'react'
import styles from './DynamicContent.module.css'
import { Spin, Empty } from 'antd'

const DynamicContent = ({ data, children }) => {
  return (
    <>
      {data === null ? (
        <div className={styles.container}>
          <Spin size="large" />
        </div>
      ) : data.length === 0 ? (
        <div className={styles.container}>
          <Empty />
        </div>
      ) : (
        children
      )}
    </>
  )
}

export default DynamicContent
