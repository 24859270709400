import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'

import { useSelector } from 'react-redux'
import axios from 'axios'

import { CardProgram, DynamicContent } from '../../components/'

import styles from './Bootcamp.module.css'
import { message } from 'antd'
import { selectCurrentUser } from '../../redux/user/user.selectors'
const Bootcamp = () => {
  const currentUser = useSelector(selectCurrentUser)
  const [dataUser, setDataUser] = useState(null)
  const [bootcampDatas, setbootcampDatas] = useState(null)

  useEffect(() => {
    axios
      .get('/show-product')
      .then((res) => {
        const data = res.data
        setbootcampDatas(data.program)
      })
      .catch((error) => {
        if (error.response.status === 500) {
          message.error('We are Under Maintenance')
        }
      })
    if (currentUser) {
      axios({
        method: 'get',
        url: '/profile',
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      }).then(({ data }) => {
        setDataUser(data)
      })
    }
  }, [currentUser])

  return (
    <Layout idPage="program-page">
      <div className={styles.productPage}>
        <div className="container">
          <div className={styles.titleGroup}>
            <h2>Program Bootcamp</h2>
            <p>
              Program Bootcamp dari Mindo Education untuk menunjang skill kamu
              ketika bekerja nanti di industri
            </p>
          </div>
          <DynamicContent data={bootcampDatas}>
            <div className={styles.productGroup}>
              {bootcampDatas?.map((program, index) =>
                program?.category?.name === 'Bootcamp' ? (
                  <CardProgram
                    key={index}
                    program={program}
                    dataUser={dataUser}
                  />
                ) : null
              )}
            </div>
          </DynamicContent>
        </div>
      </div>
    </Layout>
  )
}

export default Bootcamp
