import React, { useEffect } from 'react'
import AOS from 'aos'
import 'moment-timezone'

// ROUTER
import { Routes, Route } from 'react-router-dom'

// GLOBAL STYLING
import 'antd/dist/antd.min.css'
import 'aos/dist/aos.css'
import './App.scss'

// REDUX
import { useSelector } from 'react-redux'
import { selectCurrentUser } from './redux/user/user.selectors'

// IMPORT COMPONENTS PAGE
import Home from './pages/Home'
import NoMatch from './pages/NoMatch/'
import Program from './pages/Program'
import ProgramDetail from './pages/ProgramDetail'
import FormPendaftaran from './pages/FormPendaftaran'
import Login from './pages/Login'
import Daftar from './pages/Daftar'
import Kelas from './pages/Profil/Kelas'
import Profile from './pages/Profil/Profile'
import Transaksi from './pages/Profil/Transaksi'
import Settings from './pages/Profil/Settings'
import Dashboard from './pages/Profil'
import Invoice from './pages/Profil/Transaksi/Invoice'
import Keranjang from './pages/Keranjang'
// import Checkout from './pages/Checkout'
import KonfirmasiPembayaran from './pages/KonfirmasiPembayaran'
import DetailCheckout from './pages/DetailCheckout'
import BayarPesanan from './pages/BayarPesanan'
import Verified from './pages/Verified'
import PrintInvoice from './pages/Profil/Transaksi/PrintInvoice'
import Verify from './pages/Daftar/components/Verify'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
// import PembelianLangsung from './pages/PembelianLangsung'
import DetailKelas from './pages/DetailKelas'
import BootcampDetail from './pages/BootcampDetail'
import Bootcamp from './pages/Bootcamp'
import TentangMindo from './pages/TentangMindo'
const App = () => {
  const currentUser = useSelector(selectCurrentUser)

  useEffect(() => {
    // INTIAL AOS FUNCTION
    AOS.init()
    AOS.refresh()
  }, [])

  return (
    <div>
      <Routes>
        {!currentUser && (
          <>
            <Route path="/masuk" element={<Login />} exact />
            <Route path="/forgot-password" element={<ForgotPassword />} exact />
            <Route path="/reset-password" element={<ResetPassword />} exact />
          </>
        )}
        <Route path="/daftar" element={<Daftar />} exact />
        <Route path="/verify" element={<Verify />} exact />
        <Route path="/verified" element={<Verified />} exact />

        <Route path="/" element={<Home />} exact />
        <Route path="/program" element={<Program />} exact />
        <Route path="/program/:slug" element={<ProgramDetail />} />
        <Route path="/tentangmindo" element={<TentangMindo />} exact />
        <Route path="/bootcamp" element={<Bootcamp />} exact />
        <Route path="/bootcamp/:slug" element={<BootcampDetail />} />
        <Route path="/form-pendaftaran" element={<FormPendaftaran />} exact />
        {currentUser && (
          <>
            <Route path="/keranjang" element={<Keranjang />} exact />
            <Route path="/checkout" element={<DetailCheckout />} exact />
            <Route path="/checkout/:slug" element={<DetailCheckout />} exact />
            <Route
              path="/checkout/konfirmasi-pembayaran/:slug"
              element={<KonfirmasiPembayaran />}
              exact
            />
            <Route
              path="/checkout/detail-checkout"
              element={<DetailCheckout />}
              exact
            />
            <Route
              path="/bayar-pesanan/:slug"
              element={<BayarPesanan />}
              exact
            />
            <Route path="/dashboard" element={<Dashboard />} exact />
            <Route path="/dashboard/kelas-saya" element={<Kelas />} exact />
            <Route
              path="/dashboard/kelas-saya/:slug"
              element={<DetailKelas />}
              exact
            />
            <Route path="/dashboard/transaksi" element={<Transaksi />} exact />
            <Route
              path="/dashboard/transaksi/:slug"
              element={<Invoice />}
              exact
            />
            <Route
              path="/dashboard/transaksi/:slug/print"
              element={<PrintInvoice />}
              exact
            />
            <Route path="/dashboard/profile" element={<Profile />} exact />
            <Route path="/dashboard/settings" element={<Settings />} exact />
          </>
        )}
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </div>
  )
}

export default App
