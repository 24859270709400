import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import {
  handleFilteringProgram,
  handleFilterSearchOption,
} from './Program.utils'

import { useSelector } from 'react-redux'
import { selectSearchField } from '../../redux/program/program.selectors'
import axios from 'axios'

import { SearchProgram, BtnFilterCategory } from './components'
import { CardProgram, DynamicContent } from '../../components/'

import styles from './Program.module.css'
import { message } from 'antd'
import { selectCurrentUser } from '../../redux/user/user.selectors'
const Program = () => {
  const currentUser = useSelector(selectCurrentUser)
  const [dataUser, setDataUser] = useState(null)
  const searchField = useSelector(selectSearchField)
  const [programDatas, setprogramDatas] = useState(null)
  const [programFilter, setProgramFilter] = useState(programDatas)
  const [searchOptionFilter, setSearchOptionFilter] = useState(programDatas)

  const [categoryData, setCategoryData] = useState(null)
  const [categoryField, setCategoryField] = useState('')

  const filterByDateProgram = programFilter?.sort(
    (a, b) =>
      Number(new Date(a.time_start.slice(0, 10))) -
      Number(new Date(b.time_start.slice(0, 10)))
  )

  useEffect(() => {
    axios
      .get('/show-product')
      .then((res) => {
        const data = res.data
        setprogramDatas(data.program)
        setCategoryData(data.category)
      })
      .catch((error) => {
        if (error.response.status === 500) {
          message.error('We are Under Maintenance')
        }
      })
    if (currentUser) {
      axios({
        method: 'get',
        url: '/profile',
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      }).then(({ data }) => {
        setDataUser(data)
      })
    }
  }, [currentUser])

  useEffect(() => {
    const programFilterBySearch =
      programDatas !== null
        ? handleFilteringProgram(programDatas, searchField, categoryField)
        : programDatas

    setProgramFilter(programFilterBySearch)
  }, [programDatas, searchField, categoryField])

  useEffect(() => {
    const searchOption = programDatas
      ? handleFilterSearchOption(programDatas, categoryField)
      : null

    setSearchOptionFilter(searchOption)
  }, [programDatas, categoryField])

  return (
    <Layout idPage="program-page">
      <div className={styles.productPage}>
        <div className="container">
          <div className={styles.titleGroup}>
            <h2>Semua kelas di Mindo Education</h2>
            <p>
              Pilih pelatihan yang kamu suka sesuai minat dan penunjang karir
              mu!
            </p>
          </div>
          <div className={styles.filterGroup}>
            <SearchProgram
              optionSearchField={searchOptionFilter}
              initSearchField={searchField}
            />
            <BtnFilterCategory
              setCategoryField={setCategoryField}
              categoryData={categoryData}
            />
          </div>
          <DynamicContent data={programFilter}>
            <div className={styles.productGroup}>
              {filterByDateProgram?.map((program) => (
                <CardProgram
                  program={program}
                  key={program.slug}
                  dataUser={dataUser}
                />
              ))}
            </div>
          </DynamicContent>
        </div>
      </div>
    </Layout>
  )
}

export default Program
