import { Card, Col, message, Row, Table } from 'antd'
import { useParams } from 'react-router-dom'
import ProfileLogo from '../../../../assets/images/logo/logo-profile.svg'
import './PrintInvoice.scss'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../../../redux/user/user.selectors'
import axios from 'axios'
import FetchLoading from '../../../../components/FetchLoading'

import styles from './PrintInvoice.module.css'
import { Preview, print } from 'react-html2pdf'

const PrintInvoice = () => {
  const [data, setData] = useState(null)
  const currentUser = useSelector(selectCurrentUser)
  const { slug } = useParams()

  useEffect(() => {
    axios({
      method: 'get',
      url: `/view-invoice/${atob(slug)}`,
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    })
      .then(({ data }) => {
        setData(data)
        print(`Invoice Mindo Education ${data?.external_id}`, 'print')
        setTimeout(() => {
          window.close()
        }, 1000)
      })
      .catch((error) => {
        if (error.response.status === 500) {
          message.error('We are Under Maintenance')
        }
      })
  }, [currentUser.token, slug])

  const column = [
    {
      title: 'Produk',
      dataIndex: 'product',
      key: 'product',
      render: (product) => product.name,
    },
    {
      title: 'Harga',
      dataIndex: 'price',
      key: 'price',
      render: (price) => `Rp ${price.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`,

      width: '30%',
    },
  ]

  return (
    <Preview id={'print'}>
      <div id="printInvoice">
        <div className={styles.invoicePage}>
          <FetchLoading data={data}>
            <div className="container">
              <Card>
                <div className="overflowInvoice">
                  <Row>
                    <Col span={12} className="code">
                      <img src={ProfileLogo} alt="profile-logo" />
                      <h2>#{data?.external_id}</h2>
                    </Col>
                    <Col span={12} className="date">
                      <p>
                        Tanggal Invoice:{' '}
                        <span>
                          {data?.created_at
                            .slice(0, 10)
                            .split('-')
                            .reverse()
                            .join('-')}
                        </span>
                      </p>
                      <p>
                        Status: <span>{data?.status.toUpperCase()}</span>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12} className="info">
                      <p>Info Perusahaan:</p>
                      <p>
                        <span>PT Impiana Generasi Bangsa</span>
                      </p>
                      <p>
                        Soho Pancoran, Tower Splendor Lt. 15 No 1505, Jalan MT
                        Haryono Kav. 2-3 RT 1/RW 6, Tebet Barat, Tebet Jakarta
                        Selatan, DKI Jakarta
                      </p>
                      <p>Telp: 0822-1777-7616</p>
                      <p>Email: mika@mindoeducation.co.id</p>
                    </Col>
                    <Col span={12} className="identity">
                      <p>Untuk:</p>
                      <p>
                        <span>{data?.user.name}</span>
                      </p>
                      <p>{data?.user.phone}</p>
                      <p>{data?.user.email}</p>
                      <p>{data?.user.address}</p>
                    </Col>
                  </Row>
                  <Row className="table-produk">
                    <Table
                      style={{ width: '100%' }}
                      rowKey={(obj) => obj.id}
                      columns={column}
                      dataSource={data?.detail_transaction}
                      pagination={false}
                    />
                  </Row>
                  <table className="price">
                    <div>
                      <tr>
                        <td style={{ paddingRight: 20 }}>
                          <span>Subtotal</span>
                        </td>
                        <td>
                          RP{' '}
                          {data?.subtotal
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Diskon</span>
                        </td>
                        <td>
                          RP{' '}
                          {data?.discount.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                        </td>
                      </tr>
                      {/* <tr>
                        <td style={{ paddingRight: 20 }}>
                          <span>Biaya Penanganan</span>
                        </td>
                        <td>
                          RP{' '}
                          {data?.xendit_payment
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                        </td>
                      </tr> */}
                      <tr>
                        <td>
                          <span>Total</span>
                        </td>
                        <td>
                          <span>
                            RP{' '}
                            {data?.amount
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                          </span>
                        </td>
                      </tr>
                    </div>
                  </table>
                  <Row>
                    <p>
                      Metode Pembayaran{' '}
                      <span>
                        {data?.method_payment
                          ? data?.method_payment
                          : data?.method_payment === null
                          ? data?.payment_channel
                          : 'Manual Transfer'}
                      </span>
                    </p>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <p>
                        Invoice ini sah dan diproses secara otomatis. Silahkan
                        hubungi
                        <span> Mika (0822-1777-7616)</span> jika membutuhkan
                        bantuan
                      </p>
                    </Col>
                    <Col span={12}>
                      <div className="footer">
                        <i>Dikeluarkan oleh</i>
                        <i>PT Impiana Generasi Bangsa</i>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </div>
          </FetchLoading>
        </div>
      </div>
    </Preview>
  )
}

export default PrintInvoice
