import React from 'react'
import { CardProgram, DynamicContent } from '../../../../components/'

import './ProgramUnggulan.scss'
import styles from './ProgramUnggulan.module.css'

const ProgramUnggulan = ({ programUnggulanData, dataUser }) => {
  return (
    <article id="home-page__programUnggulan">
      <div className="container">
        <div data-aos="fade-up">
          <div className={styles.text}>
            <h2>Program Unggulan</h2>
            <p>
              Ikuti pelatihan yang sudah diikuti oleh banyak orang yang ingin
              berkarir di industri
            </p>
          </div>
        </div>
        <DynamicContent data={programUnggulanData}>
          <div className={styles.programItems} data-aos="fade-up">
            {programUnggulanData?.map((program, index) => (
              <CardProgram
                key={program.slug}
                program={program}
                dataUser={dataUser}
              />
            ))}
          </div>
        </DynamicContent>
      </div>
    </article>
  )
}

export default ProgramUnggulan
