import React from 'react'
import IcMindoOnline from './icon/ic-onlineTraining.svg'
import IcMindoIntensive from './icon/ic-intensiveBootcamp.svg'
import IcMindoLearning from './icon/ic-learning.svg'
import IcMindoVideo from './icon/ic-videoLearning.svg'
import IcMindoInHouse from './icon/ic-inhouseTraining.svg'
import './Programkami.scss'
import styles from './Programkami.module.css'

const Programkami = () => {
  return (
    <article id="tentang-mindo-page__Programkami" className="container">
      <div className={styles.titleGroup}>
        <div className={styles.titleContainer}>
          <h2>Program Kami</h2>
        </div>
      </div>

      <div className={styles.cardContainer}>
        <a
          href="https://mindoeducation.co.id/program"
          target="_blank"
          rel="noreferrer">
          <div className={styles.cardItem}>
            <div className={styles.iconContainer}>
              <img src={IcMindoOnline} alt="IcMindoOnline" />
            </div>
            <h3>Mindo Online Training</h3>
          </div>
        </a>
        <a
          href="https://mindoeducation.co.id/bootcamp"
          target="_blank"
          rel="noreferrer">
          <div className={styles.cardItem}>
            <div className={styles.iconContainer}>
              <img src={IcMindoIntensive} alt="IcMindoIntensive" />
            </div>
            <h3>Mindo Intensive Bootcamp</h3>
          </div>
        </a>
        <a href="https://bit.ly/minlearn-2023" target="_blank" rel="noreferrer">
          <div className={styles.cardItem}>
            <div className={styles.iconContainer}>
              <img src={IcMindoLearning} alt="IcMindoLearning" />
            </div>
            <h3>Mindo Learning</h3>
          </div>
        </a>
        <a
          href="https://bit.ly/vlearn-mindo2023"
          target="_blank"
          rel="noreferrer">
          <div className={styles.cardItem}>
            <div className={styles.iconContainer}>
              <img src={IcMindoVideo} alt="IcMindoVideo" />
            </div>
            <h3>Mindo Video Learning</h3>
          </div>
        </a>
        <a href="https://wa.me/6282125984535" target="_blank" rel="noreferrer">
          <div className={styles.cardItem}>
            <div className={styles.iconContainer}>
              <img src={IcMindoInHouse} alt="IcMindoInHouse" />
            </div>
            <h3>Inhouse Training | Konsultasi & Pendampingan Sertifikasi</h3>
          </div>
        </a>
      </div>
    </article>
  )
}

export default Programkami
