import React, { useState } from 'react'
import { ReactComponent as IcUnlocked } from './assets/ic-unlocked.svg'
import { ReactComponent as IcLoked } from './assets/ic-locked.svg'
import styles from './PersiapanKelas.module.css'
import Modal from 'antd/lib/modal/Modal'
import { CloseCircleTwoTone, InfoCircleTwoTone } from '@ant-design/icons'

const PersiapanKelas = ({ program }) => {
  const [modalVisibleGroup, setModalVisibleGroup] = useState(false)
  const [modalVisibleLink, setModalVisibleLink] = useState(false)
  const [modalVisibleWorkShop, setModalVisibleWorkShop] = useState(false)

  const timeStartGroup = new Date(program.time_start)
  const timeStartLink = new Date(program.time_start)

  const timeGroup = new Date(
    timeStartGroup.setDate(timeStartGroup.getDate() - 2)
  )
  const timeLink = new Date(timeStartLink.getTime() - 3600000)

  const dateNow = new Date()

  const workshopFacility = program.facility_product.find(
    (item) => item.facility.name === 'Workshop'
  )

  return (
    <div>
      <h2 className={styles.title}>Persiapan Kelas</h2>
      <div className={styles.persiapanItem}>
        {dateNow >= timeGroup ? (
          program.link_group === '-' ? (
            <>
              <div className={styles.statusIcon}>
                <IcLoked />
              </div>
              <p onClick={() => setModalVisibleGroup(true)}>
                Join Whatsapp Group
              </p>
            </>
          ) : (
            <>
              <div className={styles.statusIcon}>
                <IcUnlocked />
              </div>
              <a href={program.link_group} target="_blank" rel="noreferrer">
                Join Whatsapp Group
              </a>
            </>
          )
        ) : (
          <>
            <div className={styles.statusIcon}>
              <IcLoked />
            </div>
            <p onClick={() => setModalVisibleGroup(true)}>
              Join Whatsapp Group
            </p>
          </>
        )}
      </div>
      <div className={styles.persiapanItem}>
        {dateNow >= timeLink ? (
          program.link_zoom === '-' ? (
            <>
              <div className={styles.statusIcon}>
                <IcLoked />
              </div>
              <p onClick={() => setModalVisibleLink(true)}>Link Kelas (Zoom)</p>
            </>
          ) : (
            <>
              <div className={styles.statusIcon}>
                <IcUnlocked />
              </div>
              <a href={program.link_zoom} target="_blank" rel="noreferrer">
                Link Kelas (Zoom)
              </a>
            </>
          )
        ) : (
          <>
            <div className={styles.statusIcon}>
              <IcLoked />
            </div>
            <p onClick={() => setModalVisibleLink(true)}>Link Kelas (Zoom)</p>
          </>
        )}
      </div>
      <div className={styles.persiapanItem}>
        {dateNow >= timeLink || program.link_theory !== '-' ? (
          <>
            <div className={styles.statusIcon}>
              <IcUnlocked />
            </div>
            <a href={program.link_theory} target="_blank" rel="noreferrer">
              Link Materi
            </a>
          </>
        ) : (
          <>
            <div className={styles.statusIcon}>
              <IcLoked />
            </div>
            <p onClick={() => setModalVisibleLink(true)}>Link Materi</p>
          </>
        )}
      </div>
      <div className={styles.persiapanItem}>
        {workshopFacility ? (
          dateNow >= timeLink ||
          program.link_workshop !== '-' ||
          program.link_workshop !== 'null' ? (
            <>
              <div className={styles.statusIcon}>
                <IcUnlocked />
              </div>
              <a href={program.link_workshop} target="_blank" rel="noreferrer">
                Link Workshop
              </a>
            </>
          ) : (
            <>
              <div className={styles.statusIcon}>
                <IcLoked />
              </div>
              <p onClick={() => setModalVisibleLink(true)}>Link Workshop</p>
            </>
          )
        ) : (
          <>
            <div className={styles.statusIcon}>
              <IcLoked />
            </div>
            <p onClick={() => setModalVisibleWorkShop(true)}>Link Workshop</p>
          </>
        )}
      </div>
      <div className={styles.persiapanItem}>
        <div className={styles.statusIcon}>
          <IcUnlocked />
        </div>
        <a href="https://bit.ly/tele-komindo" target="_blank" rel="noreferrer">
          Join Komindo
        </a>
      </div>
      <Modal
        centered
        visible={modalVisibleGroup}
        footer={null}
        onCancel={() => setModalVisibleGroup(false)}>
        <div className={styles.modalContainer}>
          <InfoCircleTwoTone style={{ fontSize: 50 }} />
          <p className={styles.modalTitle}>
            Group akan tersedia H-2 sebelum pelatihan, apabila belum tersedia
            silahkan{' '}
            <a
              href="https://api.whatsapp.com/send/?phone=6282217777616&text=%23askmika%20Halo%2C%20Mika!%20Saya%20ingin%20bertanya%20mengenai.."
              target="_blank"
              rel="noreferrer">
              hubungi Mika
            </a>
          </p>
        </div>
      </Modal>
      <Modal
        centered
        visible={modalVisibleLink}
        footer={null}
        onCancel={() => setModalVisibleLink(false)}>
        <div className={styles.modalContainer}>
          <InfoCircleTwoTone style={{ fontSize: 50 }} />
          <p className={styles.modalTitle}>
            Link belum tersedia, silahkan cek kembali 1 jam sebelum pelatihan
          </p>
        </div>
      </Modal>
      <Modal
        centered
        visible={modalVisibleWorkShop}
        footer={null}
        onCancel={() => setModalVisibleWorkShop(false)}>
        <div className={styles.modalContainer}>
          <CloseCircleTwoTone twoToneColor="#FB0100" style={{ fontSize: 50 }} />
          <p className={styles.modalTitle}>
            Pelatihan ini tidak tersedia workshop
          </p>
        </div>
      </Modal>
    </div>
  )
}

export default PersiapanKelas
