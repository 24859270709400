import React from 'react'
import styles from './InfoProgram.module.css'
import { BASE_URL_IMAGE_PROGRAM } from '../../../../utils'
import IcTimer from '../../../../assets/icon/ic-timer.svg'
import IcClock from '../../../../assets/icon/ic-clock.svg'
import IcCalender from '../../../../assets/icon/ic-calender.svg'
import { Rate } from 'antd'
import Moment from 'react-moment'

const InfoProgram = ({ program }) => {
  const { name, category, hours, rating, time_start, time_end, cover } = program

  return (
    <div className={styles.infoProduct}>
      <div className={`${styles.infoProductContainer}`}>
        <div className={styles.poster}>
          <img src={`${BASE_URL_IMAGE_PROGRAM}/${cover}`} alt="poster" />
        </div>
        <div className={styles.infoProductTextGroup}>
          <h1 className={styles.infoProductTitle}>{name}</h1>
          <p className={styles.infoProductTag}>{category.name}</p>
          <div className={styles.infoProductTypeGroup}>
            <div className={styles.infoProductDuration}>
              <img src={IcTimer} alt="clock" />
              <p>{hours} jam</p>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.infoProductRate}>
              <Rate allowHalf disabled defaultValue={parseFloat(rating)} />
              <p>
                {rating}
                {/* <span>(257)</span> */}
              </p>
            </div>
          </div>
          <div className={styles.infoProductTimeGroup}>
            <div className={styles.infoProductTime}>
              <img src={IcCalender} alt="calender" />
              <p>
                <Moment
                  locale="id"
                  date={time_start}
                  format="dddd, DD MMMM YYYY"
                />
              </p>
            </div>
            <div className={styles.infoProductTime}>
              <img src={IcClock} alt="clock" />
              <p>
                <Moment locale="id" date={time_start} format="HH:mm" />
                <span> - </span>
                <Moment locale="id" date={time_end} format="HH:mm" />
                <span> WIB</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfoProgram
