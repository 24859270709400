import React from 'react'
import { Link } from 'react-router-dom'
const ButtonLink = ({
  urlRoute = '/',
  title = 'title',
  variant = 'primary',
  block = false,
  disbaled = false,
}) => {
  return disbaled ? (
    <button
      type="button"
      className={`btn btn-disbaled ${block ? 'btn-block' : ''}`}>
      {title}
    </button>
  ) : (
    <Link
      to={urlRoute}
      className={`btn btn-${variant} ${block ? 'btn-block' : ''}`}>
      {title}
    </Link>
  )
}

export default ButtonLink
