import React, { useState } from 'react'
import Layout from '../../components/Layout'
import styles from './FormPendaftaran.module.css'
import './FormPendaftaran.scss'
import { message } from 'antd'
import FormGroup from './components/FormGroup'
import Pembayaran from './components/Pembayaran'

const FormPendaftaran = () => {
  const [daftar, setDaftar] = useState(false)

  message.config({
    duration: 2,
    maxCount: 1,
  })

  const onFinish = (values) => {
    message.success('Berhasil Mendaftar')
    setDaftar(true)
  }

  return (
    <Layout idPage="form-pendaftaran">
      <div className={styles.container}>
        <div className={styles.card}>
          {daftar ? (
            <Pembayaran />
          ) : (
            <>
              <h1 className={styles.title}>Pendaftaran Prakerja</h1>
              <FormGroup onFinish={onFinish} />
            </>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default FormPendaftaran
