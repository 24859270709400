import React, { useState } from 'react'
import styles from './InfoProgram.module.css'
import { BASE_URL_IMAGE_PROGRAM } from '../../../../utils'
import IcTimer from '../../../../assets/icon/ic-timer.svg'
import IcClock from '../../../../assets/icon/ic-clock.svg'
import IcCalender from '../../../../assets/icon/ic-calender.svg'
import { ReactComponent as IconAddShoppingChart } from '../../assets/iconAddShoppingChart.svg'
import { Button, message, Modal, Rate } from 'antd'
import Moment from 'react-moment'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser } from '../../../../redux/user/user.selectors'
import axios from 'axios'
import { removeCurrentUser } from '../../../../redux/user/user.action'
import ButtonLink from '../../../../components/ButtonLink'
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  ExclamationCircleTwoTone,
} from '@ant-design/icons'

const InfoProgram = ({ program, dataUser }) => {
  const [modalVisibleSuccess, setModalVisibleSuccess] = useState(false)
  const [modalVisibleFail, setModalVisibleFail] = useState(false)
  const [modalVisibleAuth, setModalVisibleAuth] = useState(false)
  const [modalVisibleStatus, setModalVisibleStatus] = useState(false)
  const [messageError, setMessageError] = useState('')
  const [loading, setLoading] = useState(false)
  const currentUser = useSelector(selectCurrentUser)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    id,
    name,
    category,
    kuota,
    hours,
    rating,
    time_start,
    time_end,
    price,
    discount,
    promo_price,
    cover,
    link_campsite,
  } = program

  const addCart = (id) => {
    let data = JSON.stringify({
      product_id: id,
    })

    axios({
      method: 'post',
      url: '/cart',
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then(() => {
        setModalVisibleSuccess(true)
      })
      .catch((error) => {
        setMessageError(error.response.data.message)
        if (error.response.status === 401) {
          navigate('/masuk')
          dispatch(removeCurrentUser())
        }
        if (error.response.status === 500) {
          message.error('We are Under Maintenance')
        }
        if (error.response.status === 403) {
          setModalVisibleFail(true)
        }
      })
  }

  const buyNow = (id) => {
    setLoading(true)
    let data = JSON.stringify({
      product_id: id,
    })

    axios({
      method: 'get',
      url: `/direct-buy/${id}`,
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then(() => {
        navigate(`/checkout/${btoa(id)}`)
      })
      .catch((error) => {
        setMessageError(error.response.data.message)
        if (error.response.status === 403) {
          setModalVisibleFail(true)
        }
        if (error.response.status === 401) {
          navigate('/masuk')
          dispatch(removeCurrentUser())
        }
        if (error.response.status === 500) {
          message.error('We are Under Maintenance')
        }
        setLoading(false)
      })
  }

  return (
    <div className={styles.infoProduct}>
      <div className={`${styles.infoProductContainer}`}>
        <div className={styles.poster}>
          <img src={`${BASE_URL_IMAGE_PROGRAM}/${cover}`} alt="poster" />
        </div>
        <div className={styles.infoProductTextGroup}>
          <h1 className={styles.infoProductTitle}>{name}</h1>
          <p className={styles.infoProductTag}>{category.name}</p>
          <div className={styles.infoProductTypeGroup}>
            <p>Kuota Tersisa: {kuota}</p>
            <div className={styles.divider}></div>
            <div className={styles.infoProductDuration}>
              <img src={IcTimer} alt="clock" />
              <p>{hours} jam</p>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.infoProductRate}>
              <Rate allowHalf disabled defaultValue={parseFloat(rating)} />
              <p>
                {rating}
                {/* <span>(257)</span> */}
              </p>
            </div>
          </div>
          <div className={styles.infoProductTimeGroup}>
            <div className={styles.infoProductTime}>
              <img src={IcCalender} alt="calender" />
              <p>
                <Moment
                  locale="id"
                  date={time_start}
                  format="dddd, DD MMMM YYYY"
                />
              </p>
            </div>
            <div className={styles.infoProductTime}>
              <img src={IcClock} alt="clock" />
              <p>
                <Moment locale="id" date={time_start} format="HH:mm" />
                <span> - </span>
                <Moment locale="id" date={time_end} format="HH:mm" />
                <span> WIB</span>
              </p>
            </div>
          </div>
          <div className={styles.infoProductPriceGroup}>
            <p className={styles.infoProductPrice}>
              Rp {discount === '0' ? price : promo_price}
            </p>
            <div
              className={
                discount !== '0'
                  ? styles.infoProductPriceDiscountGroup
                  : styles.infoProductPriceDiscountGroupHidden
              }>
              <p>{price}</p>
              <p>-{discount}%</p>
            </div>
          </div>
          <div className={styles.btnContainer}>
            {link_campsite === '/form-prakerja' ? (
              <Link
                to="/form-pendaftaran"
                className={`btn btn-primary ${styles.infoProductBtn}`}>
                Beli Pelatihan Langsung
              </Link>
            ) : currentUser === null ? (
              <>
                <div className={styles.addToChartContainer}>
                  <IconAddShoppingChart
                    onClick={() => setModalVisibleAuth(true)}
                  />
                </div>
                <Button
                  className={`btn btn-primary ${styles.infoProductBtn}`}
                  onClick={() => setModalVisibleAuth(true)}>
                  Beli Pelatihan Langsung
                </Button>
              </>
            ) : dataUser?.user.status === null ? (
              <>
                <div className={styles.addToChartContainer}>
                  <IconAddShoppingChart
                    onClick={() => setModalVisibleStatus(true)}
                  />
                </div>
                <Button
                  className={`btn btn-primary ${styles.infoProductBtn}`}
                  onClick={() => setModalVisibleStatus(true)}>
                  Beli Pelatihan Langsung
                </Button>
              </>
            ) : (
              <>
                <div className={styles.addToChartContainer}>
                  <IconAddShoppingChart onClick={() => addCart(id)} />
                </div>
                <Button
                  className={`btn btn-primary ${styles.infoProductBtn}`}
                  onClick={() => buyNow(id)}
                  loading={loading}>
                  Beli Pelatihan Langsung
                </Button>
              </>
            )}
          </div>
          <Modal
            centered
            visible={modalVisibleSuccess}
            footer={null}
            onCancel={() => setModalVisibleSuccess(false)}>
            <div className={styles.modalContainer}>
              <CheckCircleTwoTone
                twoToneColor="#1BBC34"
                style={{ fontSize: 50 }}
              />
              <p className={styles.modalTitle}>
                Produk Berhasil ditambahkan ke keranjang
              </p>
              <ButtonLink title="Lihat Keranjang" urlRoute="/keranjang" />
            </div>
          </Modal>
          <Modal
            centered
            visible={modalVisibleFail}
            footer={null}
            onCancel={() => setModalVisibleFail(false)}>
            <div className={styles.modalContainer}>
              <CloseCircleTwoTone
                twoToneColor="#FB0100"
                style={{ fontSize: 50 }}
              />
              <p className={styles.modalTitle}>{messageError}</p>
            </div>
          </Modal>
          <Modal
            centered
            visible={modalVisibleAuth}
            footer={null}
            onCancel={() => setModalVisibleAuth(false)}>
            <div className={styles.modalContainer}>
              <ExclamationCircleTwoTone
                twoToneColor="#ffa940"
                style={{ fontSize: 50 }}
              />
              <p className={styles.modalTitle}>
                Silahkan masuk terlebih dahulu
              </p>
              <ButtonLink title="Masuk" urlRoute="/masuk" />
            </div>
          </Modal>
          <Modal
            centered
            visible={modalVisibleStatus}
            footer={null}
            onCancel={() => setModalVisibleStatus(false)}>
            <div className={styles.modalContainer}>
              <ExclamationCircleTwoTone
                twoToneColor="#ffa940"
                style={{ fontSize: 50 }}
              />
              <p className={styles.modalTitle}>
                Silahkan lengkapi profil terlebih dahulu
              </p>
              <ButtonLink title="Profil" urlRoute="/dashboard/profile" />
            </div>
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default InfoProgram
