import React from 'react'
import styles from './Pembayaran.module.css'
import LogoBSI from './LogoBSI.svg'
import LogoQRIS from './LogoQRIS.svg'
const Pembayaran = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <p className={styles.note}>
          Screenshot halaman ini terlebih dahulu agar tidak mengulang melakukan pendaftaran
        </p>
        <h1>Silahkan Melakukan Pembayaran</h1>
        <p>
          Pembayaran minimal DP dilakukan maksimal 1 x 24 jam setelah
          pendaftaran
        </p>
      </div>
      <div className={styles.total}>
        <p>Jumlah yang harus dibayarkan:</p>
        <p className={styles.totalBayar}>Rp. 600.000</p>
      </div>
      <div className={styles.metode}>
        <p>Metode Pembayaran:</p>
        <div className={styles.metodePembayaran}>
          <div className={styles.logo}>
            <img src={LogoBSI} alt="" />
          </div>
          <p>Rekening a.n. PT. Impiana Generasi Bangsa</p>
          <p className={styles.rekening}>5880080088</p>
        </div>
        <div className={styles.metodePembayaran}>
          <div className={styles.logo}>
            <img src={LogoQRIS} alt="" />
          </div>
          <p>Klik link berikut untuk scan QR Code</p>
          <a href="https://bit.ly/QRIS-mindo" target="_blank" rel="noreferrer">
            https://bit.ly/QRIS-mindo
          </a>
        </div>
      </div>
    </div>
  )
}

export default Pembayaran
