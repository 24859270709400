import React from 'react'
import { ReactComponent as IcKelasPengenalan } from './icon/icKelasPengenalan.svg'
import { ReactComponent as IcTrainerAhli } from './icon/icTrainerAhli.svg'
import { ReactComponent as IcKomunitasAlumni } from './icon/icKomunitasAlumni.svg'
import { ReactComponent as IcTersertifikasi } from './icon/icTersertifikasi.svg'

import './KenapaHarusMindo.scss'
import styles from "./KenapaHarusMindo.module.css"
const KenapaHarusMindo = () => {
  return (
    <article id="home-page__KenapaHarusMindo" className="container">
      <div className={styles.titleGroup} data-aos="fade-up">
        <div className={styles.titleContainer}>
          <h2>Kenapa Harus Mindo Education?</h2>
          <p>
            Beberapa keuntungan yang bisa kamu dapatkan jika <br /> memilih
            pelatihan di Mindo Education
          </p>
        </div>
      </div>

      <div className={styles.cardContainer} data-aos="fade-up">
        <div className={styles.cardItem}>
          <div className={styles.iconContainer}>
            <IcKelasPengenalan />
          </div>
          <h3>Kelas Pengenalan Karier di Industri</h3>
          <p>
            Menyediakan berbagai kelas untuk lebih tahu dan mengenal minat karir
            dan pelatihan yang cocok diikuti
          </p>
        </div>
        <div className={styles.cardItem}>
          <div className={styles.iconContainer}>
            <IcTrainerAhli />
          </div>
          <h3>Trainer Ahli Bersertifikat</h3>
          <p>
            Pengajar Mindo Education sudah berpengalaman di bidangnya dan
            bersertifikat
          </p>
        </div>
        <div className={styles.cardItem}>
          <div className={styles.iconContainer}>
            <IcKomunitasAlumni />
          </div>
          <h3>Komunitas Alumni</h3>
          <p>
            Terdapat informasi khusus alumni yaitu info regulasi-standar
            terupdate, info lowongan kerja maupun alumni sharing
          </p>
        </div>
        <div className={styles.cardItem}>
          <div className={styles.iconContainer}>
            <IcTersertifikasi />
          </div>
          <h3>Tersertifikasi ISO 9001:2015</h3>
          <p>
            Mindo Education di bawah PT Impiana Generasi Bangsa sudah
            tersertifikasi Sistem Jaminan Mutu
          </p>
        </div>
      </div>
    </article>
  )
}

export default KenapaHarusMindo
