import React from 'react'
import Sertifikat from './assets/Sertifikat.png'
import ISO90012015 from './assets/ISO90012015.png'
import './MindoSertifikasi.scss'
import styles from './MindoSertifikasi.module.css'
import { Col, Row } from 'antd'
const MindoSertifikasi = () => {
  return (
    <div id="tentang-mindo-page__MindoSertifikasi" className="container">
      <div className={styles.titleGroup}>
        <div className={styles.titleContainer}>
          <h2>Mindo Education sudah tersertifikasi ISO 9001:2015</h2>
          <p>
            Kami juga sudah tersertifikasi ISO 9001:2015 (Sistem Manajemen Mutu)
          </p>
        </div>
      </div>
      <Row gutter={[32, 32]}>
        <Col sm={12}>
          <div className={styles.imageContainer}>
            <img src={ISO90012015} alt="Alumni Pelatihan2" />
          </div>
        </Col>
        <Col sm={12}>
          <div className={styles.imageContainer}>
            <img src={Sertifikat} alt="Alumni Pelatihan1" />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default MindoSertifikasi
