import { Card, Col, Modal, Tooltip } from 'antd'
import { useState } from 'react'

import styles from './PembayaranManual.module.css'
import logoBSI from '../../assets/logo_BSI.svg'
import iconCopy from '../../assets/icon_copy.svg'
import logoQRIS from '../../assets/logo_QRIS.svg'
import QRIS_Mindo_Education from '../../assets/QRIS_Mindo_Education.jpeg'

import './PembayaranManual.scss'
import { WhatsAppOutlined } from '@ant-design/icons'

const PembayaranManual = ({ payment }) => {
  const [textCopy, setTextCopy] = useState('Copy')
  const [modalVisible, setModalVisible] = useState(false)

  const onCopy = () => {
    setTextCopy('Copied!!')
    setTimeout(() => {
      setTextCopy('Copy')
    }, 2000)
    navigator.clipboard.writeText('5880080088')
  }

  return (
    <Col sm={{ span: 24 }} xl={{ span: 12 }}>
      <Card className={styles.card} id="pembayaran">
        <div className={styles.price}>
          <p>Total Pembayaran :</p>
          <p>Rp {payment.amount.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</p>
        </div>
        <div className={styles.bankName}>
          <img src={logoBSI} alt="bsi" />
          <p>BSI (Dicek Manual)</p>
        </div>
        <div className={styles.noRek}>
          <p>No. Rekening:</p>
          <p>
            588 008 0088{' '}
            <span>
              <Tooltip title={textCopy}>
                <img
                  className={styles.iconCopy}
                  src={iconCopy}
                  alt="copy"
                  onClick={onCopy}
                />
              </Tooltip>
            </span>
          </p>
        </div>
        <p style={{ fontSize: 16 }}>atau bayar melalui QRIS</p>
        <div className={styles.qris}>
          <img src={logoQRIS} alt="Logo QRIS" />
          <p className={styles.linkQris} onClick={() => setModalVisible(true)}>
            Klik disini untuk scan QR Code
          </p>
        </div>
        <p>
          Silahkan kirim bukti pembayaran sebelum{' '}
          <b>
            {payment.expiration_date
              .slice(0, 10)
              .split('-')
              .reverse()
              .join('-')}
          </b>
        </p>
        <Modal
          title="QRIS Mindo Education"
          centered
          visible={modalVisible}
          footer={null}
          onCancel={() => setModalVisible(false)}>
          <img
            className={styles.codeQris}
            src={QRIS_Mindo_Education}
            alt="QRIS Mindo Education"
          />
        </Modal>
      </Card>
      <Card className={styles.cardInformation} id="informasi">
        <p className={styles.textInfo}>Informasi</p>
        <p className={styles.infoDesc}>
          Setelah melakukan pembayaran silahkan <b>Kirim Bukti Pembayaran</b>{' '}
          dan proses konfirmasi pembayaran maksimal diproses 1x24 jam.
        </p>
        <p className={styles.textHelp}>
          Kirim Bukti Pembayaran Melalui WhatsApp
        </p>
        <div className={styles.whatsappButtonCenter}>
          <a
            href="https://api.whatsapp.com/send/?phone=6282217777616&text=Halo%20Mika!%2C%20Saya%20ingin%20mengkonfirmasi%20pembayaran%2C%20berikut%20bukti%20pembayarannya.."
            target="_blank"
            rel="noreferrer"
            className={styles.buttonWhatsapp}>
            Hubungi Mika <WhatsAppOutlined className={styles.iconWhatsapp} />
          </a>
        </div>
      </Card>
    </Col>
  )
}

export default PembayaranManual
