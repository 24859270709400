import Layout from '../../components/Layout'
import styles from './KonfirmasiPembayaran.module.css'
import { useEffect, useState } from 'react'
import { message, Row } from 'antd'

import './KonfirmasiPembayaran.scss'
import { ListPelatihan } from '../../components'
import { PembayaranManual } from './components'
import { selectCurrentUser } from '../../redux/user/user.selectors'
import { useSelector } from 'react-redux'
import axios from 'axios'
import FetchLoading from '../../components/FetchLoading'
import { Link, useParams } from 'react-router-dom'

const KonfirmasiPembayaran = () => {
  const [payment, setpayment] = useState(null)
  const currentUser = useSelector(selectCurrentUser)
  const { slug } = useParams()

  useEffect(() => {
    axios({
      method: 'get',
      url: `/check-payment/${atob(slug)}`,
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    })
      .then(({ data }) => {
        setpayment(data)
      })
      .catch((error) => {
        if (error.response.status === 500) {
          message.error('We are Under Maintenance')
        }
      })
  }, [currentUser.token, slug])

  return (
    <Layout idPage='konfirmasiPembayaranPage'>
      <div className={styles.content}>
        <FetchLoading data={payment}>
          <div className='container'>
            <div className={styles.titleGroup}>
              <h2>Konfirmasi Pembayaran</h2>
            </div>
            <Row justify='space-between'>
              <ListPelatihan transaction={payment} />
              <PembayaranManual payment={payment} />
            </Row>

            <div className={styles.bayarButtonCenter}>
              <Link className={styles.bayarButton} to='/dashboard/transaksi'>
                Lihat Daftar Transaksi saya
              </Link>
            </div>
          </div>
        </FetchLoading>
      </div>
    </Layout>
  )
}

export default KonfirmasiPembayaran
