import React from 'react'
// import { ReactComponent as IcCertificate } from './assets/ic-certificate.svg'
// import { ReactComponent as IcRekaman } from './assets/ic-rekaman.svg'
// import { ReactComponent as IcVoucher } from './assets/ic-voucher.svg'
// import { ReactComponent as IcFullmateri } from './assets/ic-fullmateri.svg'
// import { ReactComponent as IcDoorprize } from './assets/ic-doorprize.svg'
// import { ReactComponent as IcKomunitas } from './assets/ic-komunitas.svg'
// import { ReactComponent as IcWorkshop } from './assets/ic-workshop.svg'
// import { ReactComponent as IcFreeongkir } from './assets/ic-freeongkir.svg'

import styles from './FasilitasPelatihan.module.css'
import { BASE_URL_ICON_FACILITY } from '../../../../utils'

const FasilitasPelatihan = ({ program }) => {
  // const handleRenderLogo = (fasilitas) => {
  //   const fasilitasLower = fasilitas.toLowerCase()
  //   if (fasilitasLower === 'sertifikat') return <IcCertificate />
  //   if (fasilitasLower === 'rekaman') return <IcRekaman />
  //   if (fasilitasLower === 'voucher') return <IcVoucher />
  //   if (fasilitasLower === 'full materi') return <IcFullmateri />
  //   if (fasilitasLower === 'doorprize') return <IcDoorprize />
  //   if (fasilitasLower === 'komunitas') return <IcKomunitas />
  //   if (fasilitasLower === 'workshop') return <IcWorkshop />
  //   if (fasilitasLower === 'free ongkir') return <IcFreeongkir />
  // }

  return (
    <div>
      <h2 className={styles.title}>Fasilitas Pelatihan</h2>
      <div className={styles.fasilitasItems}>
        {program.facility_product.map((data) => (
          <div className={styles.fasilitasItem} key={data.facility_id}>
            {/* {handleRenderLogo(data.facility.name)} */}
            <img
              src={`${BASE_URL_ICON_FACILITY}/${data.facility.icon}`}
              alt="icon"
              style={{ width: '44px', height: '44px' }}
            />
            <p>{data.facility.name}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FasilitasPelatihan
