import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './redux/store'
import { BASE_URL_API } from './utils'
import App from './App'
import 'moment/locale/id'
// import reportWebVitals from "./reportWebVitals";

import axios from 'axios'
import { message } from 'antd'
axios.defaults.baseURL = BASE_URL_API
axios.defaults.headers.common['X-Authorization'] = process.env.REACT_APP_API_KEY

message.config({
  top: 100,
  duration: 5,
  maxCount: 1,
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
