import { Rate } from 'antd'
import React from 'react'
import ImageGallery from 'react-image-gallery'
import { ReactComponent as IcQuote } from '../../../../assets/icon/ic-quotes.svg'
import { ReactComponent as ArrowLeft } from '../../../../assets/icon/ic-slim-arrow-left.svg'
import { ReactComponent as ArrowRigth } from '../../../../assets/icon/ic-slim-arrow-right.svg'
import { BASE_URL_IMAGE_TESTIMONI } from '../../../../utils'
import styles from './testimoni.module.css'

const Testimoni = ({ testimoniData }) => {
  const RenderItem = ({ name, foto, message, company, rating }) => {
    return (
      <div className={styles.testimoniInner}>
        <div className={styles.testimoniSideleft}>
          <div className={styles.testimoniTextGroup}>
            <div className={styles.quotes}>
              <IcQuote />
            </div>
            <p className={styles.testimoniText}>{message}</p>
          </div>
          <div className={styles.testimoniFooter}>
            <p className={styles.testimoniName}>{name}</p>
            <p className={styles.testimoniJobs}>{company}</p>
            <Rate allowHalf disabled defaultValue={parseFloat(rating)} />
          </div>
        </div>
        <div className={styles.testimoniSideright}>
          <div className={styles.image}>
            <img src={`${BASE_URL_IMAGE_TESTIMONI}/${foto}`} alt="cover" />
          </div>
        </div>
      </div>
    )
  }

  const RightNav = (onClick, disabled) => {
    return (
      <div
        className={`${styles.rightNav}`}
        onClick={onClick}
        disabled={disabled}>
        <ArrowRigth />
      </div>
    )
  }

  const LeftNav = (onClick, disabled) => {
    return (
      <div
        className={`${styles.leftNav}`}
        onClick={onClick}
        disabled={disabled}>
        <ArrowLeft />
      </div>
    )
  }

  return (
    <article id="tentang-mindo-page__testimoni">
      <div className={`container ${styles.testimoni}`}>
        <div className={styles.testimoniHeader}>
          <h2 className={styles.title}>Apa Kata Sobat Mindo?</h2>
          <p className={styles.subTitle}>
            Testimoni dari alumni peserta yang sudah mengikuti pelatihan di
            Mindo Education.
          </p>
        </div>
        {testimoniData && (
          <ImageGallery
            items={testimoniData}
            showPlayButton={false}
            renderItem={RenderItem}
            renderRightNav={RightNav}
            renderLeftNav={LeftNav}
            fullscreen={false}
            showFullscreenButton={false}
            infinite={false}
          />
        )}
      </div>
    </article>
  )
}

export default Testimoni
