import { Col, Row } from 'antd'
import React from 'react'
import LogoFakultasPertanian from './assets/LogoFakultasPertanian.png'
import styles from './KonsultasiDanSertifikasi.module.css'
import './KonsultasiDanSetifikasi.scss'
const KonsultasiDanSertifikasi = () => {
  return (
    <div
      id="tentang-mindo-page__KonsultasiDanSertifikasi"
      className="container">
      <div className={styles.titleGroup}>
        <div className={styles.titleContainer}>
          <h2>Konsultasi dan Pendampingan Sertifikasi</h2>
          <p>
            Kami menyediakan jasa pelatihan untuk perusahaan berdasarkan katalog
            yang kami sediakan
          </p>
        </div>
      </div>
      <div className={styles.container}>
        <Row gutter={[32, 32]}>
          <Col xl={12}>
            <div className={styles.listKatalog}>
              <p>Kick off & Gap Analysis</p>
              <p>Pelatihan Offline & Online</p>
              <p>Pendampingan Pembuatan Dokumen</p>
              <p>Implementasi dan Internal Audit</p>
              <p>Pendampingan Sertifikasi</p>
            </div>
          </Col>
          <Col xl={12}>
            <div className={styles.listContainter}>
              <ul>
                <li>
                  <p>Hazard Critical Control Point (HACCP)</p>
                </li>
                <li>
                  <p>Halal Assurance System (HAS) 23000</p>
                </li>
                <li>
                  <p>Quality Management System based on ISO 9001:2015</p>
                </li>
                <li>
                  <p>Food Safety Management System based on ISO 22000:2018</p>
                </li>
                <li>
                  <p>Environment Management System based on ISO 14001:2015</p>
                </li>
                <li>
                  <p>dan lainnya</p>
                </li>
              </ul>
            </div>
          </Col>
          <Col xl={12}>
            <p>
              Kami telah melaksanakan inhouse training dan partnership dengan
              berbagai perusahaan dan perguruan tinggi:
            </p>
            <div className={styles.listContainter}>
              <ul>
                <li>
                  <p>Lembaga pemerintahan</p>
                </li>
                <li>
                  <p>Lembaga swasta</p>
                </li>
                <li>
                  <p>Himpunan/BEM/Fakultas</p>
                </li>
                <li>
                  <p>Perusahaan</p>
                </li>
                <li>
                  <p>Katering</p>
                </li>
                <li>
                  <p>dan lainnya</p>
                </li>
              </ul>
            </div>
          </Col>
          <Col xl={12}>
            <div className={styles.imageContainer}>
              <img src={LogoFakultasPertanian} alt="LogoFakultasPertanian" />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default KonsultasiDanSertifikasi
