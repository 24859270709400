const landingPageMenuItem = [
  {
    title: 'Program',
    id: 'home-page__programTerdekat',
  },
  {
    title: 'Testimoni',
    id: 'home-page__testimoni',
  },
  // {
  //   title: 'Tentang Kami',
  //   id: 'home-page__KenapaHarusMindo',
  // },
]

/* Navbar Menu for Bootcamp */

// const landingPageMenuItem = [
//   {
//     title: 'Program',
//     id: 'home-page__programTerdekat',
//   },
//   {
//     title: 'Tentang Kami',
//     id: 'home-page__KenapaHarusMindo',
//   },
//   {
//     title: 'Testimoni',
//     id: 'home-page__testimoni',
//   },
//   {
//     title: 'Bootcamp',
//     id: 'home-page__programBootcamp',
//   },
// ]

export default landingPageMenuItem
