import React from 'react'
import styles from './Banner.module.css'
import './main.scss'

import { useMediaQuery } from 'react-responsive'
import { useDispatch } from 'react-redux'
import { setSearchProductField } from '../../../../redux/program/program.action'

import CountUp from 'react-countup'
import hero from './images/hero-banner.png'
import { Input, AutoComplete } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

import ButtonLink from '../../../../components/ButtonLink'

const Banner = ({ programDatas }) => {
  const dispatch = useDispatch()

  // const getProgramData = async () => {
  //   const response = await axios(`/show-product`)
  //   const data = await response.data
  //   const programs = data.program.map((program) => {
  //     return { value: program.name, text: program.name }
  //   })
  //   setprogramDatas(programs)
  // }

  // useEffect(() => {
  //   getProgramData()
  // }, [])

  const handleSearch = (value) => {
    dispatch(setSearchProductField(value))
  }

  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1280px)' })
  return (
    <article id="home-page__homeBanner">
      <div className={styles.bannerGroup}>
        <div className="container" data-aos="fade-up">
          <div className={styles.homeBannerInner}>
            <h1>
              Upgrade Skill Industri, <br /> Belajar Langsung
              <br /> dari Praktisi
            </h1>
            <p>
              Platform edukasi untuk meningkatkan skill dan pengetahuanmu
              <span> #UnggulBersama</span>
            </p>
            <div className={styles.searchGroup}>
              <div className={styles.searchGroupInner}>
                <AutoComplete
                  options={programDatas}
                  filterOption={(inputValue, option) =>
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  onChange={handleSearch}>
                  <Input
                    allowClear
                    size="large"
                    placeholder="Cari pelatihan disini"
                    prefix={<SearchOutlined />}
                    required
                  />
                </AutoComplete>
                <ButtonLink
                  variant="outlined"
                  urlRoute="program"
                  title="Cari"
                />
              </div>
              <p className={styles.textPalingDicari}>
                <span>Paling Dicari : </span>
                FSMS, QCQA, RND, Halal
              </p>
            </div>
            <div className={styles.counterGroup}>
              <div className={styles.counterItem}>
                <p className={styles.counterNumber}>
                  <CountUp end={15000} prefix="+" />
                </p>
                <p className={styles.counterTitle}>Alumni Peserta</p>
              </div>
              <div className={styles.divider}></div>
              <div className={styles.counterItem}>
                <p className={styles.counterNumber}>
                  <CountUp end={3500} prefix="+" />
                </p>
                <p className={styles.counterTitle}>Jam Pelatihan</p>
              </div>
              <div className={styles.divider}></div>
              <div className={styles.counterItem}>
                <p className={styles.counterNumber}>
                  <CountUp end={500} prefix="+" />
                </p>
                <p className={styles.counterTitle}>Kelas Terlaksana</p>
              </div>
            </div>
          </div>
        </div>
        {/* Hero Image */}
        {isDesktopOrLaptop && (
          <div className={styles.hero}>
            <img src={hero} alt="hero" />
          </div>
        )}
      </div>
    </article>
  )
}

export default Banner
