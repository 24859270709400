import React, { useState } from 'react'
import './main.scss'
import styles from './FAQ.module.css'
import { ReactComponent as IcWhatsapp } from '../../../../assets/icon/ic-whatsapp-v2.svg'

import { Collapse } from 'antd'
const { Panel } = Collapse

const FaqSection = ({ faqData }) => {
  const [lotsToShow, setLotsToShow] = useState(3)

  const handlingLoadMore = () => {
    setLotsToShow(faqData.length)
  }

  return (
    <article id="home-page__FAQ" className="container" data-aos="fade-up">
      <div className={styles.header}>
        <h2>FAQ</h2>
        <p>Berikut pertanyaan yang sering diajukan ke Mindo Education</p>
      </div>
      {faqData === null ? null : (
        <>
          <div className={styles.faqContainer}>
            <Collapse accordion expandIconPosition="right" ghost>
              {faqData.slice(0, lotsToShow).map((faq) => {
                return (
                  <Panel header={faq.question} key={faq.id}>
                    <div className={styles.silabusMateri}>{faq.answer}</div>
                  </Panel>
                )
              })}
            </Collapse>
          </div>
          <div className={styles.footerContainer}>
            {faqData.length !== lotsToShow ? (
              <button className="btn btn-outlined" onClick={handlingLoadMore}>
                Lihat Semua Pertanyaan
              </button>
            ) : (
              <div className={styles.pertanyaanLanjutanGroup}>
                <p className={styles.pertanyaanLanjutanTitle}>
                  Pertanyaanmu belum terjawab?
                </p>
                <p className={styles.pertanyaanLanjutanSubTitle}>
                  Silahkan klik tombol berikut untuk terhubung langsung melalui
                  Mika via whatsapp ya
                </p>
                <div className={styles.pertanyaanLanjutanBtnContainer}>
                  <a
                    href="https://api.whatsapp.com/send/?phone=6282217777616&text=%23askmika%20Halo%2C%20Mika!%20Saya%20ingin%20bertanya%20mengenai.."
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-tertiary btn-w-icon">
                    Hubungi Mika
                    <IcWhatsapp />
                  </a>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </article>
  )
}

export default FaqSection
