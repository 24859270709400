import './Kelas.scss'
import ProfileLayout from '../components/ProfileLayout'
import classNotFound from './assets/class_not_found.png'
import styles from './Kelas.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser } from '../../../redux/user/user.selectors'
import { Col, message, Row, Spin } from 'antd'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { BASE_URL_IMAGE_PROGRAM } from '../../../utils'
import { removeCurrentUser } from '../../../redux/user/user.action'
import { Link, useNavigate } from 'react-router-dom'
import { ReactComponent as IconStar } from './assets/iconStar.svg'
import { ReactComponent as IconJam } from './assets/iconJam.svg'

const Kelas = () => {
  const [data, setData] = useState(null)
  const currentUser = useSelector(selectCurrentUser)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    axios({
      method: 'get',
      url: '/user-classroom',
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    })
      .then(({ data }) => {
        setData(data)
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          navigate('/masuk')
          dispatch(removeCurrentUser())
        }
        if (error.response.status === 500) {
          message.error('We are Under Maintenance')
        }
      })
  }, [currentUser.token, dispatch, navigate])

  return (
    <ProfileLayout id="kelas" title="Kelas Saya" text="Daftar pembelian kelas">
      {data?.length === 0 ? (
        <div className="emptyContent">
          <img
            className="emptyImage"
            src={classNotFound}
            alt="class not found"
          />
          <p className="emptyText">
            Yah, {currentUser.user.name} belum ada kelas
          </p>
          <p className="emptySubText">
            Silahkan ikuti kelas sebelum jadwalnya terlewat~
          </p>
        </div>
      ) : data?.length > 0 ? (
        <div className={styles.mobileContainer}>
          <Row gutter={[24, 24]}>
            {data.map((item, index) => (
              <Col key={index} sm={{ span: 24 }} xl={{ span: 8 }}>
                <Link
                  to={
                    item.status_product === 'Tidak Berlangsung'
                      ? '/dashboard/kelas-saya'
                      : `/dashboard/kelas-saya/${item.product.slug}`
                  }>
                  <div className={styles.cardProgram}>
                    <div className={styles.thumbnail}>
                      <img
                        src={`${BASE_URL_IMAGE_PROGRAM}/${item.product.cover}`}
                        alt={item.product.cover}
                      />
                    </div>
                    <div className={styles.programDescription}>
                      <div className={styles.programInfo}>
                        <div className={styles.programInfoRatingGroup}>
                          <IconStar />
                          <p>{item.product.rating}</p>
                        </div>
                        <div className={styles.verticalLine}></div>
                        <div className={styles.programInfoDuration}>
                          <IconJam />
                          <p>{item.product.hours} Jam</p>
                        </div>
                        <div className={styles.verticalLine}></div>
                        {/* <div className={styles.programInfotrainingTypeGroup}>
              <p>{type}</p>
            </div> */}
                        <div className={styles.programInfoTimeStart}>
                          <p>
                            {item.product.time_start
                              .slice(0, 10)
                              .split('-')
                              .reverse()
                              .join('-')}
                          </p>
                        </div>
                      </div>
                      <div className={styles.category}>
                        <p>{item.product.category.name}</p>
                      </div>
                      <div className={styles.titleContainer}>
                        <h3 className={styles.title}>{item.product.name}</h3>
                      </div>
                      <div className={styles.footer}>
                        {item.status_product === 'Sudah Berlangsung' ? (
                          <b style={{ color: '#1bbc34' }}>
                            {item.status_product}
                          </b>
                        ) : (
                          <b style={{ color: '#213a5e' }}>
                            {item.status_product}
                          </b>
                        )}
                      </div>
                    </div>
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
        </div>
      ) : (
        <div className={styles.container}>
          <Spin size="large" />
        </div>
      )}
    </ProfileLayout>
  )
}

export default Kelas
