import React from 'react'
import AlumniPelatihan1 from './assets/AlumniPelatihan1.png'
import AlumniPelatihan2 from './assets/AlumniPelatihan2.png'
import AlumniMindo from './assets/AlumniMindo.png'
import './AlumniPelatihan.scss'
import styles from './AlumniPelatihan.module.css'
const AlumniPelatihan = () => {
  return (
    <div id="tentang-mindo-page__AlumniPelatihan" className="container">
      <div className={styles.titleGroup}>
        <div className={styles.titleContainer}>
          <h2>Alumni Pelatihan Kami</h2>
          <p>
            Terdapat dari berbagai perusahaan, lembaga swasta dan pemerintah
            maupun kampus-kampus di seluruh Indonesia dan luar negeri
          </p>
        </div>
      </div>
      <div className={styles.imageContainer}>
        <img src={AlumniPelatihan1} alt="Alumni Pelatihan1" />
        <img src={AlumniPelatihan2} alt="Alumni Pelatihan2" />
      </div>

      <div className={styles.titleGroup}>
        <div className={styles.titleContainer}>
          <h2 style={{ marginTop: 70 }}>Alumni Mindo Education</h2>
          <p>Alumni Mindo Education sudah tersebar di banyak perusahaan.</p>
        </div>
      </div>
      <div className={styles.imageContainer}>
        <img src={AlumniMindo} alt="Alumni Mindo" />
      </div>
    </div>
  )
}

export default AlumniPelatihan
