import React, { useState } from 'react'
import styles from './ForgotPassword.module.css'

// COMPONENTS
import { ReactComponent as LogoMindoWhite } from '../../assets/images/logo/logo-mindo--white.svg'
import { Form, Input, Button, message } from 'antd'

import './ForgotPassword.scss'
import axios from 'axios'
import Modal from 'antd/lib/modal/Modal'
import { CheckCircleTwoTone } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { ButtonLink } from '../../components'
import Layout from '../../components/Layout'

const ForgotPassword = () => {
  const navigate = useNavigate()

  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)

  const onFinish = (values) => {
    setLoading(true)
    const { email } = values
    const data = JSON.stringify({
      email,
    })
    axios({
      method: 'post',
      url: '/forgot-password',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then(() => {
        setModalVisible(true)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.data.message === 'The given data was invalid.') {
          message.error('Email tidak ditemukan')
        }
        if (error.response.status === 500) {
          message.error('We are Under Maintenance')
        }
      })
  }

  return (
    <Layout idPage="forgotPass">
      <div id="forgotPass-page">
        <div className={styles.container}>
          <div className={styles.card}>
            <div className={styles.header}>
              <LogoMindoWhite />
            </div>
            <div className={styles.content}>
              <p>
                Konfirmasi <span>Lupa Password</span>
              </p>
              <Form form={form} name="forgotPassForm" onFinish={onFinish}>
                <Form.Item
                  name="email"
                  hasFeedback
                  rules={[
                    {
                      type: 'email',
                      message: 'Email Tidak Valid',
                    },
                    {
                      required: true,
                      message: '',
                    },
                  ]}>
                  <Input placeholder="Alamat e-mail " size="large" />
                </Form.Item>
                <Form.Item>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    block
                    className={styles.btnSubmit}>
                    Kirim Email
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
          <Modal
            centered
            visible={modalVisible}
            footer={null}
            onCancel={() => navigate('/masuk')}>
            <div className={styles.modalContainer}>
              <CheckCircleTwoTone
                twoToneColor="#1BBC34"
                style={{ fontSize: 50 }}
              />
              <p className={styles.modalTitle}>
                Email Terkirim, Silahkan cek email kamu ya untuk link reset
                passwordnya
              </p>
              <ButtonLink urlRoute="/masuk" title="Oke" />
            </div>
          </Modal>
        </div>
      </div>
    </Layout>
  )
}

export default ForgotPassword
