import './Verified.scss'
import verifyEmailSuccess from './assets/verify-email-success.png'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser } from '../../redux/user/user.selectors'
import { useNavigate } from 'react-router-dom'
import { Button, Form, Input, message } from 'antd'
import { removeCurrentUser, setCurrentUser } from '../../redux/user/user.action'
import styles from './Verified.module.css'
import { ReactComponent as LogoMindoWhite } from '../../assets/images/logo/logo-mindo--white.svg'

const Verified = () => {
  const currentUser = useSelector(selectCurrentUser)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const url_string = window.location.href.toString()
  const url = new URL(url_string)
  const verfiy = url.searchParams.get('verfiy')

  useEffect(() => {
    dispatch(removeCurrentUser())
  }, [dispatch])

  const onFinish = (values) => {
    setLoading(true)
    const { username, password } = values
    const data = JSON.stringify({
      username,
      password,
    })
    axios({
      method: 'post',
      url: '/login',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then((response) => {
        dispatch(setCurrentUser(response.data))
        form.resetFields()
        axios({
          method: 'get',
          url: verfiy,
          headers: {
            Authorization: `Bearer ${response.data.token}`,
          },
        })
          .then(() => {
            axios({
              method: 'post',
              url: '/login',
              headers: {
                'Content-Type': 'application/json',
              },
              data: data,
            }).then((response) => {
              dispatch(setCurrentUser(response.data))
              setTimeout(() => {
                navigate('/')
              }, 3000)
            })
          })
          .catch((error) => {
            console.log(error.response)
            if (error.response.status === 500) {
              message.error('We are Under Maintenance')
              navigate('/masuk')
            }
          })
        setLoading(false)
      })
      .catch((error) => {
        if (error.response.status === 500) {
          message.error('We are Under Maintenance')
        }
        if (error.response === undefined) {
          message.error('Akun anda diblokir')
        } else {
          message.error('Username atau Password salah')
        }
        setLoading(false)
      })
  }

  return (
    <div id="verified-page">
      {currentUser ? (
        <>
          <img
            className="image"
            src={verifyEmailSuccess}
            alt="verifyEmailSuccess"
          />
          <p className="title">Verifikasi Email Berhasil</p>
          <p className="text">Selamat email sudah bisa digunakan!</p>
          <Button className="btn ant-btn-primary" loading>
            Halaman Utama
          </Button>
        </>
      ) : (
        <div className={styles.container}>
          <div className={styles.card}>
            <div className={styles.header}>
              <LogoMindoWhite />
            </div>
            <div className={styles.content}>
              <p>
                Masuk Sebagai <span>Peserta</span>
              </p>
              <Form form={form} name="loginForm" onFinish={onFinish}>
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: 'username belum di isi!',
                    },
                  ]}>
                  <Input placeholder="username" size="large" />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'password belum di isi!!',
                    },
                  ]}>
                  <Input.Password placeholder="password" size="large" />
                </Form.Item>

                <Form.Item>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    block
                    className={styles.btnSubmit}>
                    Masuk
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Verified
