import axios from 'axios'
import { useEffect, useState } from 'react'
import './Verify.scss'

import verifyEmail from '../../assets/verify-email.png'
import verifyEmailFail from '../../assets/verify-email-fail.png'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser } from '../../../../redux/user/user.selectors'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Form, Input, message } from 'antd'
import { removeCurrentUser } from '../../../../redux/user/user.action'
import Countdown from 'antd/lib/statistic/Countdown'

const qs = require('qs')

const Verify = () => {
  const currentUser = useSelector(selectCurrentUser)
  const [coundown, setCoundown] = useState(true)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const [form] = Form.useForm()

  useEffect(() => {
    if (location.pathname === '/daftar') {
      axios({
        method: 'post',
        url: '/email/verification-notification',
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      })
        .then(() => {})
        .catch((error) => {
          if (error.response.status === 500) {
            message.error('We are Under Maintenance')
            navigate('/daftar')
          }
        })
    }
  }, [currentUser.token, dispatch, location.pathname, navigate])

  const onChange = (val) => {
    if (val < 150) {
      setCoundown(false)
    }
  }

  const onFinish = (values) => {
    setLoading(true)
    const { email } = values
    const data = qs.stringify({
      email: email,
    })
    axios({
      method: 'patch',
      url: '/email/update-verification',
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: data,
    })
      .then(() => {
        message.success('Berhasil ganti email')
        setLoading(false)
        setCoundown(true)
        setTimeout(() => {
          window.location.reload()
        }, 500)
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 403) {
          message.error('Email telah digunakan')
        }
        if (error.response.status === 500) {
          message.error('We are Under Maintenance')
        }
      })
  }

  const onClick = () => {
    setLoading(true)
    axios({
      method: 'post',
      url: '/email/verification-notification',
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    })
      .then(() => {
        message.success('Link verifikasi berhasil dikirim')
        setLoading(false)
        setCoundown(true)
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 500) {
          message.error('We are Under Maintenance')
        }
      })
  }

  const onBack = () => {
    dispatch(removeCurrentUser())
    navigate('/')
  }

  return (
    <div id="verify-page">
      {location.pathname === '/daftar' ? (
        <>
          <div className="backBotton">
            <Button className="btn ant-btn-primary" onClick={onBack}>
              Kembali ke halaman utama
            </Button>
          </div>
          <img className="image" src={verifyEmail} alt="verifyEmail" />
          <p className="title">Verifikasi Email</p>
          <p className="text">
            Silahkan cek email kamu ya untuk link verifikasinya
          </p>
          {coundown ? (
            <Countdown
              title="Kirim ulang email verifikasi ?"
              value={Date.now() + 15 * 1000}
              onChange={onChange}
            />
          ) : (
            <>
              <Button
                className="btn btn-tertiary"
                onClick={onClick}
                loading={loading}>
                Kirim Ulang Link Verifikasi
              </Button>
              <p style={{ margin: 20 }}>atau</p>
              <Form form={form} name="forgotPassForm" onFinish={onFinish}>
                <Form.Item
                  name="email"
                  label="Ganti Email"
                  hasFeedback
                  rules={[
                    {
                      type: 'email',
                      message: 'Email Tidak Valid',
                    },
                    {
                      required: true,
                      message: '',
                    },
                  ]}>
                  <Input placeholder="Alamat e-mail " size="large" />
                </Form.Item>
                <Form.Item>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    block>
                    Ganti Email
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}
        </>
      ) : (
        <>
          <img className="image" src={verifyEmailFail} alt="verifyEmail" />
          <p className="title">Verifikasi Email</p>
          <div style={{ marginBottom: 20 }}>
            <p className="text">
              Oops, Kamu belum memverifikasi email, Klik tombol dibawah
            </p>
            <p className="text">
              lalu cek email {currentUser.user.email} untuk link verifikasinya
            </p>
          </div>
          <Button
            className="btn ant-btn-primary"
            onClick={onClick}
            loading={loading}>
            Kirim Link Verifikasi
          </Button>
        </>
      )}
    </div>
  )
}

export default Verify
