import './Transaksi.scss'
import ProfileLayout from '../components/ProfileLayout'
import { Button, message, Modal, Space, Spin, Table, Tag } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { selectCurrentUser } from '../../../redux/user/user.selectors'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { removeCurrentUser } from '../../../redux/user/user.action'
import transactionNotFound from './assets/EmptyTransaction.png'
import styles from './Transaksi.module.css'
import iconWarning from './assets/icon_warning.svg'

const Transaksi = () => {
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const currentUser = useSelector(selectCurrentUser)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    axios({
      method: 'get',
      url: '/user-transaction',
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    })
      .then(({ data }) => {
        setData(data)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate('/masuk')
          dispatch(removeCurrentUser())
        }
        if (error.response.status === 500) {
          message.error('We are Under Maintenance')
        }
      })
  }, [currentUser.token, dispatch, navigate])

  const cancelTransaction = (transaction_id) => {
    setLoading(true)
    axios({
      method: 'patch',
      url: `/cancel-transaction/${transaction_id}`,
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    })
      .then(() => {
        window.location.reload()
      })
      .catch((error) => {
        if (error.response.status === 500) {
          message.error('We are Under Maintenance')
        }
        setLoading(false)
        setModalVisible(false)
        message.error({
          content: 'Gagal menghapus produk dari keranjang',
          onClose: {},
          style: {
            marginTop: '20vh',
          },
        })
      })
  }

  const column = [
    {
      title: '#Kode',
      dataIndex: 'external_id',
      key: 'external_id',
    },
    {
      title: 'Nama Kelas',
      dataIndex: 'product_name',
      key: 'product_name',
    },
    {
      title: 'Dibuat',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) =>
        created_at
          ? created_at.slice(0, 10).split('-').reverse().join('-')
          : null,
      width: '15%',
    },
    {
      title: 'Berakhir',
      dataIndex: 'expiration_date',
      key: 'expiration_date',
      render: (expiration_date) =>
        expiration_date
          ? expiration_date.slice(0, 10).split('-').reverse().join('-')
          : '-',
      width: '15%',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag
          color={
            status === 'failed'
              ? 'red'
              : status === 'pending'
              ? 'orange'
              : 'green'
          }>
          {status.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: 'Aksi',
      dataIndex: 'aksi',
      key: 'aksi',
      render: (_, record) => (
        <Space size="middle">
          {record.status === 'success' ? (
            <Link to={`/dashboard/transaksi/${btoa(record.transaction_id)}`}>
              Lihat Invoice
            </Link>
          ) : record.status === 'pending' ? (
            record.payment_channel === 'VIRTUAL_ACCOUNT' ? (
              <>
                <Link to={`/bayar-pesanan/${btoa(record.transaction_id)}`}>
                  Lihat Pembayaran
                </Link>
                <Button type="text" onClick={() => setModalVisible(true)}>
                  Batal Transaksi
                </Button>
                <Modal
                  centered
                  visible={modalVisible}
                  okText="Ya"
                  okType="default"
                  closable={false}
                  cancelText="Tidak"
                  onOk={() => cancelTransaction(record.transaction_id)}
                  confirmLoading={loading}
                  onCancel={() => setModalVisible(false)}>
                  <div className={styles.modalContainer}>
                    <img
                      className={styles.modalIcon}
                      src={iconWarning}
                      alt="delete warning"
                    />
                    <div>
                      <p className={styles.modalTitle}>Batal Transaksi</p>
                      <p className={styles.modalText}>
                        Apakah anda yakin ingin membatalkan transaksi?
                      </p>
                    </div>
                  </div>
                </Modal>
              </>
            ) : record.payment_channel === 'RETAIL_OUTLETS' ? (
              <Link to={`/bayar-pesanan/${btoa(record.transaction_id)}`}>
                Lihat Pembayaran
              </Link>
            ) : (
              <Link
                to={`/checkout/konfirmasi-pembayaran/${btoa(
                  record.transaction_id
                )}`}>
                Lihat Pembayaran
              </Link>
            )
          ) : (
            <Link disabled to="#">
              Lihat Invoice
            </Link>
          )}
        </Space>
      ),
    },
  ]

  return (
    <>
      <ProfileLayout id="transaksi" title="Transaksi" text="Daftar Transaksi">
        {data?.length === 0 ? (
          <div className={styles.emptyContent}>
            <img
              className={styles.emptyImage}
              src={transactionNotFound}
              alt="class not found"
            />
            <p className={styles.emptyText}>
              Yah, {currentUser.user.name} belum ada Transaksi apapun nih {':('}
            </p>
            <p className={styles.emptySubText}>Yuk, segera ikuti kelas!</p>
          </div>
        ) : data?.length > 0 ? (
          <div className={styles.container}>
            <Table
              bordered
              rowKey={(obj) => obj.id}
              columns={column}
              dataSource={data}
              pagination={{ pageSize: 10, position: ['', 'bottomCenter'] }}
            />
          </div>
        ) : (
          <div className={styles.spin}>
            <Spin size="large" />
          </div>
        )}
      </ProfileLayout>
    </>
  )
}

export default Transaksi
