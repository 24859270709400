import { Col, Row } from 'antd'
import React from 'react'
import IcCharacter from '../../assets/ic-character.svg'
import IcCompetence from '../../assets/ic-competence.svg'
import IcCompetitiveness from '../../assets/ic-competitiveness.svg'
import styles from './VisiMisi.module.css'
const VisiMisi = () => {
  return (
    <div className="container">
      <Row gutter={[32, 32]}>
        <Col sm={12}>
          <h2 className={styles.title}>Visi</h2>
          <p>Membangun generasi bangsa yang unggul dan mandiri</p>
        </Col>
        <Col sm={12}>
          <h2 className={styles.title}>Misi</h2>
          <div className={styles.misiItems}>
            <div className={styles.misiItem}>
              <img
                src={IcCharacter}
                alt="icon"
                style={{ width: '62px', height: '62px' }}
              />
              <div className={styles.misiDesc}>
                <h3>Character</h3>
                <p>
                  Menyelenggarakan pelatihan berbasis pola pikir dan karakter
                </p>
              </div>
            </div>
            <div className={styles.misiItem}>
              <img
                src={IcCompetence}
                alt="icon"
                style={{ width: '62px', height: '62px' }}
              />
              <div className={styles.misiDesc}>
                <h3>Competence</h3>
                <p>Mengembangkan keahlian untuk menjadi generasi mandiri</p>
              </div>
            </div>
            <div className={styles.misiItem}>
              <img
                src={IcCompetitiveness}
                alt="icon"
                style={{ width: '62px', height: '62px' }}
              />
              <div className={styles.misiDesc}>
                <h3>Competitiveness</h3>
                <p>Meningkatkan daya saing untuk menjadi generasi unggul</p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default VisiMisi
