import React from 'react'
import styles from './ButtonActionGroup.module.css'
import ButtonLink from '../../ButtonLink'
import { ShoppingCartOutlined } from '@ant-design/icons'

// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser } from '../../../redux/user/user.selectors'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import axios from 'axios'
import { removeCurrentUser } from '../../../redux/user/user.action'
import { Button, message } from 'antd'
import { useState } from 'react'

const ButtonActionGroup = () => {
  const currentUser = useSelector(selectCurrentUser)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const onLogout = () => {
    setLoading(true)
    axios({
      method: 'post',
      url: '/logout',
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    })
      .then(() => {
        dispatch(removeCurrentUser())
        setLoading(false)
        message.success('Logout')
        navigate('/masuk')
      })
      .catch((error) => {
        // if (error.response.status === 401) {
        //   navigate('/masuk')
        //   dispatch(removeCurrentUser())
        // }
        // if (
        //   error.response.data.message === 'Your email address is not verified.'
        // ) {
        //   navigate('/masuk')
        //   dispatch(removeCurrentUser())
        // }
        setLoading(false)
        dispatch(removeCurrentUser())
        navigate('/masuk')
      })
  }

  return (
    <div>
      <div className={styles.navbarActionGroup}>
        <div className={styles.navbarActionButton}>
          {currentUser ? (
            currentUser.user.email_verified_at === null ? (
              <>
                {location.pathname === '/verify' ? null : (
                  <ButtonLink
                    title="Verifikasi Email"
                    variant="outlined"
                    urlRoute="/verify"
                  />
                )}
                <Button
                  className={styles.button}
                  onClick={onLogout}
                  loading={loading}>
                  Keluar
                </Button>
              </>
            ) : (
              <>
                <Link to="/keranjang">
                  {/* <Badge count={2}> */}
                  <ShoppingCartOutlined className={styles.shoppingChart} />
                  {/* </Badge> */}
                </Link>
                <ButtonLink
                  title="Dashboard"
                  variant="outlined"
                  urlRoute="/dashboard/kelas-saya"
                />
                <Button
                  className={styles.button}
                  onClick={onLogout}
                  loading={loading}>
                  Keluar
                </Button>
              </>
            )
          ) : (
            <>
              <ButtonLink title="Masuk" variant="outlined" urlRoute="/masuk" />
              <ButtonLink title="Daftar" urlRoute="/daftar" />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ButtonActionGroup
