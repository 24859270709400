import React from 'react'
import './BtnFilterCategory.scss'

import { Select } from 'antd'
const { Option } = Select

const BtnFilterCategory = ({ setCategoryField, categoryData }) => {
  return (
    <div id="program-page__filter-category">
      <Select
        bordered={false}
        allowClear
        showSearch
        placeholder="Cari kategori di sini"
        optionFilterProp="children"
        onChange={(value) => {
          value ? setCategoryField(value) : setCategoryField('')
        }}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            .toLowerCase()
            .localeCompare(optionB.children.toLowerCase())
        }>
        {categoryData &&
          categoryData.map((category) => (
            <Option value={category.code_category} key={category.code_category}>
              {category.name}
            </Option>
          ))}
      </Select>
    </div>
  )
}

export default BtnFilterCategory
