import { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import {
  Banner,
  FAQ,
  KenapaHarusMindo,
  ProgramBootcamp,
  // ProgramBootcamp,
  // ProgramBaru,
  ProgramUnggulan,
  Testimoni,
} from './components'

import axios from 'axios'
import styles from './home.module.css'
import BelajarMandiri from '../../components/BelajarMandiri'
import { message } from 'antd'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../redux/user/user.selectors'
import ProgramTerdekat from './components/ProgramTerdekat'

const Home = () => {
  const [programBootcampData, setProgramBootcampData] = useState(null)
  const [programTerdekatData, setProgramTerdekatData] = useState(null)
  const [programUnggulanData, setProgramUnggulanData] = useState(null)
  const [programDatas, setprogramDatas] = useState([])
  const [testimoniData, setTestimoniData] = useState(null)
  const [faqData, setFaqData] = useState(null)

  const currentUser = useSelector(selectCurrentUser)
  const [dataUser, setDataUser] = useState(null)

  const getProgramData = async () => {
    const response = await axios(`/show-product`)
    const data = await response.data
    const programs = data.program.map((program) => {
      return { value: program.name, text: program.name }
    })
    setprogramDatas(programs)
    setProgramBootcampData(data.program)
  }

  useEffect(() => {
    axios
      .get('/show-index')
      .then((res) => {
        const data = res.data
        // setProgramBootcampData(data.program_terdekat)
        setProgramTerdekatData(data.program_terdekat)
        setProgramUnggulanData(data.program_unggulan)
        setFaqData(data.faq)
        setTestimoniData(data.testimoni)
      })
      .catch((error) => {
        if (error.response.status === 500) {
          message.error('We are Under Maintenance')
        }
      })
    getProgramData()
    if (currentUser) {
      axios({
        method: 'get',
        url: '/profile',
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      }).then(({ data }) => {
        setDataUser(data)
      })
    }
  }, [currentUser])

  // Mengurutkan secara ascending berdasarkan waktu terdekat
  programUnggulanData?.sort(
    (a, b) =>
      Number(new Date(a.time_start.slice(0, 10))) -
      Number(new Date(b.time_start.slice(0, 10)))
  )

  return (
    <Layout idPage="homePage">
      <div id="beranda">
        <Banner programDatas={programDatas} />
      </div>
      <div className={styles.homeInner}>
        <ProgramBootcamp
          dataUser={dataUser}
          programBootcampData={programBootcampData}
        />
        <ProgramTerdekat
          dataUser={dataUser}
          programTerdekatData={programTerdekatData}
        />
        <ProgramUnggulan
          programUnggulanData={programUnggulanData}
          dataUser={dataUser}
        />
        {/* <ProgramBaru
          programTerbaruData={programTerbaruData}
          dataUser={dataUser}
        /> */}
        <Testimoni testimoniData={testimoniData} />
        <KenapaHarusMindo />
        <FAQ faqData={faqData} />
        <BelajarMandiri />
      </div>
    </Layout>
  )
}

export default Home
