import ProfileLayout from './components/ProfileLayout'

import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../redux/user/user.selectors'

const Dashboard = () => {
  const currentUser = useSelector(selectCurrentUser)
  return (
    <ProfileLayout
      id="kelas"
      title={`Selamat Datang ${currentUser.user.name}`}
      text="Semoga aktivitas belajarmu menyenangkan."></ProfileLayout>
  )
}

export default Dashboard
