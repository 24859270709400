import React from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
import FloatBtnGroup from '../FloatBtnGroup'
import ScrollToTop from "../ScrollToTop"
const Layout = ({ children, idPage }) => {
  return (
    <>
      <ScrollToTop/>
      <Navbar />
      <main id={idPage}>{children}</main>
      <Footer />
      <FloatBtnGroup/>
    </>
  )
}

export default Layout
