import { Menu } from 'antd'
import { Link, useLocation } from 'react-router-dom'

import IconClass from '../../../../../assets/icon/ic-profile-class.svg'
import IconTransaksi from '../../../../../assets/icon/ic-profile-chart.svg'
import IconProfile from '../../../../../assets/icon/ic-profile-person.svg'
// import IconSettings from '../../../../../assets/icon/ic-profile-setting.svg'

import './MenuItems.scss'

const MenuItems = () => {
  const location = useLocation()

  return (
    <div id="sidebar-menu">
      <Menu mode="inline" selectedKeys={[location.pathname]}>
        <Menu.Item key="/dashboard/kelas-saya">
          <Link to={'/dashboard/kelas-saya'}>
            <img src={IconClass} alt="class-icon" />
            Kelas Saya
          </Link>
        </Menu.Item>
        <Menu.Item key="/dashboard/transaksi">
          <Link to={'/dashboard/transaksi'}>
            <img src={IconTransaksi} alt="transaksi-icon" />
            Transaksi
          </Link>
        </Menu.Item>
        <Menu.Item key="/dashboard/profile">
          <Link to={'/dashboard/profile'}>
            <img src={IconProfile} alt="profile-icon" />
            Profil
          </Link>
        </Menu.Item>
        {/* <Menu.Item key="/dashboard/settings">
          <Link to={'/dashboard/settings'}>
            <img src={IconSettings} alt="settings-icon" />
            Settings
          </Link>
        </Menu.Item> */}
      </Menu>
    </div>
  )
}

export default MenuItems
