import ProgramActionTypes from './program.types'

const INITIAL_STATE = {
  searchField: '',
}

const productReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // START HANDLING SEARCH PROGRAM
    case ProgramActionTypes.SET_SEARCH_PROGRAM_FIELD:
      return {
        ...state,
        searchField: action.payload,
      }
    case ProgramActionTypes.REMOVE_SEARCH_PROGRAM_FIELD:
      return {
        ...state,
        searchField: '',
      }
    // END HANDLING SEARCH PROGRAM
    default:
      return state
  }
}

export default productReducer
