import ProgramActionTypes from './program.types'

// START HANDLING SEARCH PROGRAM
export const setSearchProductField = (searchValue) => ({
  type: ProgramActionTypes.SET_SEARCH_PROGRAM_FIELD,
  payload: searchValue,
})

export const removeSearchProductField = () => ({
  type: ProgramActionTypes.REMOVE_SEARCH_PROGRAM_FIELD,
})
// END HANDLING SEARCH PROGRAM
