import { MenuOutlined } from '@ant-design/icons'
import { Affix, Drawer } from 'antd'
import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import logo from '../../assets/images/logo/mindoLogo.svg'
import ListMenu from './ListMenu'
import { Link } from 'react-router-dom'
import './main.scss'
import styles from './Navbar.module.css'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../redux/user/user.selectors'
import axios from 'axios'

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false)
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })
  const currentUser = useSelector(selectCurrentUser)
  const [dataUser, setDataUser] = useState(null)

  const handleOpenMenu = () => {
    setShowMenu(true)
  }

  const handleCloseMenu = () => {
    setShowMenu(false)
  }

  useEffect(() => {
    if (currentUser) {
      axios({
        method: 'get',
        url: '/profile',
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
        },
      }).then(({ data }) => {
        setDataUser(data)
      })
    }
  }, [currentUser])

  return (
    <Affix offsetTop={0}>
      {dataUser?.user.status === null ? (
        <div className={styles.message}>
          <p>
            Silahkan Lengkapi{' '}
            <Link to="/dashboard/profile" className={styles.messageLink}>
              Profil
            </Link>{' '}
            Terlebih Dahulu
          </p>
        </div>
      ) : null}
      <nav id="navbar" className={styles.navbar}>
        <div className="container">
          <div className={styles.navbarInner}>
            <div className="logo-container">
              <Link to="/">
                <img src={logo} alt="mindo logo" />
              </Link>
            </div>
            {isTabletOrMobile ? (
              <div className={styles.navbarMenuTogleMobile}>
                <MenuOutlined
                  className={styles.humbergerIcon}
                  onClick={handleOpenMenu}
                />
              </div>
            ) : (
              <ListMenu offset={-204} />
            )}
            {isTabletOrMobile && (
              <Drawer
                placement="left"
                closable={false}
                onClose={handleCloseMenu}
                visible={showMenu}
                key="left"
                className="navbar__drawer">
                <div className={styles.logoContainer}>
                  <img src={logo} alt="mindo logo" />
                </div>
                <ListMenu onSetActive={handleCloseMenu} offset={-104} />
              </Drawer>
            )}
          </div>
        </div>
      </nav>
    </Affix>
  )
}

export default Navbar
