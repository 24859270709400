// import { Form, Input } from 'antd'
// import axios from 'axios'
import React from 'react'
// import { ReactComponent as IcSend } from '../../assets/icon/ic-send.svg'
import LogoFooter from '../../assets/images/logo/mindoFooterLogo.svg'
import icemail from './assets/ic-email.svg'
import icfacebook from './assets/ic-facebook-fill-circle.svg'
import icinstagram from './assets/ic-instagram-fill-circle.svg'
import iclinkedin from './assets/ic-linkedin-fill-circle.svg'
import iclocation from './assets/ic-location.png'
import icphone from './assets/ic-phone.svg'
import ictiktok from './assets/ic-tiktok-fill-circle.svg'
import ictwitter from './assets/ic-twitter-fill-circle.svg'
import icwhatsapp from './assets/ic-whatsapp.png'
import styles from './Footer.module.css'

// const validateMessages = {
//   types: {
//     email: 'Email tidak valid!',
//   },
// }
const Footer = () => {
  const mainUrl = window.location.origin
  // const [form] = Form.useForm()
  // const onFinish = (values) => {
  //   const data = JSON.stringify({ email: values.email })
  //   axios
  //     .post('/subscribe', data, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     })
  //     .then(() => {
  //       form.resetFields()
  //     })
  //     .catch((error) => {
  //       console.log(error.response.message)
  //       form.resetFields()
  //     })
  // }
  const today = new Date()
  const year = today.getFullYear()

  return (
    <footer className="bg-primary">
      <div className={`container ${styles.footerContainer}`}>
        <div className={styles.inner}>
          {/* Mindo Desc */}
          <div className={styles.groupItem}>
            <div className={styles.logoFooter}>
              <img src={LogoFooter} alt="mindo education" />
            </div>
            <p className={styles.mindoDesc}>
              Platform edukasi untuk meningkatkan skill kamu agar siap
              berkarier, berkarya, dan berwirausaha. #SobatMindo #UnggulBersama
            </p>
          </div>
          {/* Social Media*/}
          <div className={styles.groupItem}>
            <p className={styles.title}>Lebih dekat dengan Mindo</p>
            <div className={styles.socialMedia}>
              <a
                href="https://www.instagram.com/mindoeducation/"
                target="_blank"
                rel="noreferrer">
                <img src={icinstagram} alt="icon" />
              </a>
              <a
                href="https://www.linkedin.com/company/mindo-education/"
                target="_blank"
                rel="noreferrer">
                <img src={iclinkedin} alt="icon" />
              </a>
              <a
                href="https://www.facebook.com/mindoeducation"
                target="_blank"
                rel="noreferrer">
                <img src={icfacebook} alt="icon" />
              </a>
              <a
                href="https://vt.tiktok.com/ZSdJKnWBu/"
                target="_blank"
                rel="noreferrer">
                <img src={ictiktok} alt="icon" />
              </a>
              <a
                href="https://twitter.com/mindoeducation"
                target="_blank"
                rel="noreferrer">
                <img src={ictwitter} alt="icon" />
              </a>
            </div>
          </div>
          {/* Tentang Kami */}
          <div className={styles.groupItem}>
            <a
              href={`${mainUrl}/tentangmindo`}
              rel="noreferrer"
              className={styles.titleLink}>
              Tentang Kami
            </a>
            <div className={styles.tentangKamiContentGroup}>
              <div className={styles.tentangKamiContent}>
                <div className={styles.tentangKamiIcon}>
                  <img src={icwhatsapp} alt="icon" />
                </div>
                <a
                  href="https://api.whatsapp.com/send/?phone=6282217777616&text=%23askmika%20Halo%2C%20Mika!%20Saya%20ingin%20bertanya%20mengenai.."
                  target="_blank"
                  rel="noreferrer">
                  0822-1777-7616
                </a>
              </div>
              <div className={styles.tentangKamiContent}>
                <div className={styles.tentangKamiIcon}>
                  <img src={icphone} alt="icon" />
                </div>
                <p>(021) 5010 1118</p>
              </div>
              <div className={styles.tentangKamiContent}>
                <div className={styles.tentangKamiIcon}>
                  <img src={icemail} alt="icon" />
                </div>
                <p>cs@mindoeducation.co.id</p>
              </div>
              <div className={`${styles.tentangKamiContent} ${styles.alamat}`}>
                <div className={styles.tentangKamiIcon}>
                  <img src={iclocation} alt="icon" />
                </div>
                <a
                  href="https://goo.gl/maps/M2oKYMRCYaqfyY7x8"
                  target="_blank"
                  rel="noreferrer">
                  SOHO Pancoran, 1505 Splendor, Tebet, Jakarta Selatan
                </a>
              </div>
            </div>
          </div>
          {/* Newslater Kami */}
          {/* <div className={styles.groupItem}>
            <p className={styles.title}>Newsletter</p>
            <div className={`${styles.footerInput}`}>
              <Form
                form={form}
                onFinish={onFinish}
                validateMessages={validateMessages}
                autoComplete="off">
                <Form.Item name={'email'} rules={[{ type: 'email' }]}>
                  <Input
                    className={styles.inputEmail}
                    placeholder="Masukkan Email"
                    suffix={
                      <button type="submit" className={`${styles.btnSubmit}`}>
                        <IcSend />
                      </button>
                    }
                  />
                </Form.Item>
              </Form>
              <p className={styles.labelInput}>
                Berlanggangan buletin Mindo untuk mendapatkan info pelatihan,
                lowongan kerja, diskon dan artikel terbaru
              </p>
            </div>
          </div> */}
        </div>
        <p className={styles.copyRight}>
          © {year} PT Impiana Generasi Bangsa. All Rights Reserved
        </p>
      </div>
    </footer>
  )
}

export default Footer
